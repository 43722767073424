import React from 'react';
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';

const borderColor = '#fff';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    color: '#000',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    flexGrow: 1,
    textAlign: 'left',
    paddingVertical: 10,
  },
  sl: {
    width: '6%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '50%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  image: {
    width: '20%',
    height: 'auto',
  },
  status: {
    width: '25%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  stock: {
    width: '25%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  amount: {
    width: '25%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
});

const TableRow = ({
  id,
  image,
  name,
  quantity,
  totalQty,
  stock_status,
  price,
}) => (
  <View style={styles.container}>
    <View style={styles.sl}>
      <Text>{id}</Text>
    </View>
    <View style={styles.description}>
      {image && <Image style={styles.image} src={image} />}
      <Text>{name}</Text>
    </View>
    <View style={styles.status}>
      <Text>{stock_status}</Text>
    </View>
    <View style={styles.stock}>
      <Text>
        {quantity}/{totalQty}
      </Text>
    </View>
    <View style={styles.amount}>
      <Text>AED {price.$numberDecimal}</Text>
    </View>
  </View>
);

export default TableRow;
