export const countriesWithStates = [
  {
    label: 'Andorra',
    countryCode: 'AD',
    countryCodeAlpha3: 'AND',
    phone: '376',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Sant Julià de Loria',
      },
      {
        label: 'Ordino',
      },
      {
        label: 'La Massana',
      },
      {
        label: 'Encamp',
      },
      {
        label: 'Canillo',
      },
      {
        label: 'Andorra la Vella',
      },
      {
        label: 'Escaldes-Engordany',
      },
    ],
  },
  {
    label: 'United Arab Emirates',
    countryCode: 'AE',
    countryCodeAlpha3: 'ARE',
    phone: '971',
    currency: 'AED',
    stateProvinces: [
      {
        label: 'Imārat Umm al Qaywayn',
      },
      {
        label: 'Raʼs al Khaymah',
      },
      {
        label: 'Dubai',
      },
      {
        label: 'Sharjah',
      },
      {
        label: 'Fujairah',
      },
      {
        label: 'Ajman',
      },
      {
        label: 'Abu Dhabi',
      },
    ],
  },
  {
    label: 'Afghanistan',
    countryCode: 'AF',
    countryCodeAlpha3: 'AFG',
    phone: '93',
    currency: 'AFN',
    stateProvinces: [
      {
        label: 'Zabul',
      },
      {
        label: 'Vardak',
      },
      {
        label: 'Takhar',
      },
      {
        label: 'Sar-e Pol',
      },
      {
        label: 'Samangan',
      },
      {
        label: 'Parwan',
      },
      {
        label: 'Paktika',
      },
      {
        label: 'Paktia',
      },
      {
        label: 'Oruzgan',
      },
      {
        label: 'Nimroz',
      },
      {
        label: 'Nangarhar',
      },
      {
        label: 'Logar',
      },
      {
        label: 'Laghman',
      },
      {
        label: 'Kunduz',
      },
      {
        label: 'Kunar',
      },
      {
        label: 'Kapisa',
      },
      {
        label: 'Kandahar',
      },
      {
        label: 'Kabul',
      },
      {
        label: 'Jowzjan',
      },
      {
        label: 'Herat',
      },
      {
        label: 'Helmand',
      },
      {
        label: 'Ghowr',
      },
      {
        label: 'Ghazni',
      },
      {
        label: 'Faryab',
      },
      {
        label: 'Farah',
      },
      {
        label: 'Bamyan',
      },
      {
        label: 'Balkh',
      },
      {
        label: 'Baghlan',
      },
      {
        label: 'Badghis',
      },
      {
        label: 'Badakhshan',
      },
      {
        label: 'Khowst',
      },
      {
        label: 'Nuristan',
      },
      {
        label: 'Daykundi',
      },
      {
        label: 'Panjshir',
      },
    ],
  },
  {
    label: 'Antigua and Barbuda',
    countryCode: 'AG',
    countryCodeAlpha3: 'ATG',
    phone: '+1-268',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Saint Philip',
      },
      {
        label: 'Saint Peter',
      },
      {
        label: 'Saint Paul',
      },
      {
        label: 'Saint Mary',
      },
      {
        label: 'Saint John',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Redonda',
      },
      {
        label: 'Barbuda',
      },
    ],
  },
  {
    label: 'Anguilla',
    countryCode: 'AI',
    countryCodeAlpha3: 'AIA',
    phone: '+1-264',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Blowing Point',
      },
      {
        label: 'Sandy Ground',
      },
      {
        label: 'Sandy Hill',
      },
      {
        label: 'The Valley',
      },
      {
        label: 'East End',
      },
      {
        label: 'North Hill',
      },
      {
        label: 'West End',
      },
      {
        label: 'South Hill',
      },
      {
        label: 'The Quarter',
      },
      {
        label: 'North Side',
      },
      {
        label: 'Island Harbour',
      },
      {
        label: 'George Hill',
      },
      {
        label: 'Stoney Ground',
      },
      {
        label: 'The Farrington',
      },
    ],
  },
  {
    label: 'Albania',
    countryCode: 'AL',
    countryCodeAlpha3: 'ALB',
    phone: '355',
    currency: 'ALL',
    stateProvinces: [
      {
        label: 'Berat',
      },
      {
        label: 'Dibër',
      },
      {
        label: 'Elbasan',
      },
      {
        label: 'Gjirokastër',
      },
      {
        label: 'Korçë',
      },
      {
        label: 'Kukës',
      },
      {
        label: 'Durrës',
      },
      {
        label: 'Fier',
      },
      {
        label: 'Lezhë',
      },
      {
        label: 'Shkodër',
      },
      {
        label: 'Tirana',
      },
      {
        label: 'Vlorë',
      },
    ],
  },
  {
    label: 'Armenia',
    countryCode: 'AM',
    countryCodeAlpha3: 'ARM',
    phone: '374',
    currency: 'AMD',
    stateProvinces: [
      {
        label: 'Ararat',
      },
      {
        label: 'Syunik',
      },
      {
        label: 'Vayots Dzor',
      },
      {
        label: 'Yerevan',
      },
      {
        label: 'Aragatsotn',
      },
      {
        label: 'Armavir',
      },
      {
        label: 'Gegharkunik',
      },
      {
        label: 'Kotayk',
      },
      {
        label: 'Lori',
      },
      {
        label: 'Shirak',
      },
      {
        label: 'Tavush',
      },
    ],
  },
  {
    label: 'Angola',
    countryCode: 'AO',
    countryCodeAlpha3: 'AGO',
    phone: '244',
    currency: 'AOA',
    stateProvinces: [
      {
        label: 'Lunda Sul',
      },
      {
        label: 'Luanda Norte',
      },
      {
        label: 'Moxico',
      },
      {
        label: 'Cuando Cobango',
      },
      {
        label: 'Zaire',
      },
      {
        label: 'Uíge',
      },
      {
        label: 'Malanje',
      },
      {
        label: 'Luanda',
      },
      {
        label: 'Cuanza Norte',
      },
      {
        label: 'Cabinda',
      },
      {
        label: 'Bengo',
      },
      {
        label: 'Namibe',
      },
      {
        label: 'Huíla',
      },
      {
        label: 'Huambo',
      },
      {
        label: 'Cunene',
      },
      {
        label: 'Kwanza Sul',
      },
      {
        label: 'Bíe',
      },
      {
        label: 'Benguela',
      },
    ],
  },
  {
    label: 'Antarctica',
    countryCode: 'AQ',
    countryCodeAlpha3: 'ATA',
    phone: '',
    currency: '',
    stateProvinces: null,
  },
  {
    label: 'Argentina',
    countryCode: 'AR',
    countryCodeAlpha3: 'ARG',
    phone: '54',
    currency: 'ARS',
    stateProvinces: [
      {
        label: 'Misiones',
      },
      {
        label: 'Formosa',
      },
      {
        label: 'Buenos Aires F.D.',
      },
      {
        label: 'Entre Rios',
      },
      {
        label: 'Corrientes',
      },
      {
        label: 'Buenos Aires',
      },
      {
        label: 'Tucuman',
      },
      {
        label: 'Tierra del Fuego',
      },
      {
        label: 'Santiago del Estero',
      },
      {
        label: 'Santa Fe',
      },
      {
        label: 'Santa Cruz',
      },
      {
        label: 'San Luis',
      },
      {
        label: 'San Juan',
      },
      {
        label: 'Salta',
      },
      {
        label: 'Rio Negro',
      },
      {
        label: 'Neuquen',
      },
      {
        label: 'Mendoza',
      },
      {
        label: 'La Rioja',
      },
      {
        label: 'La Pampa',
      },
      {
        label: 'Jujuy',
      },
      {
        label: 'Cordoba',
      },
      {
        label: 'Chubut',
      },
      {
        label: 'Chaco',
      },
      {
        label: 'Catamarca',
      },
    ],
  },
  {
    label: 'American Samoa',
    countryCode: 'AS',
    countryCodeAlpha3: 'ASM',
    phone: '+1-684',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Western District',
      },
      {
        label: 'Swains Island',
      },
      {
        label: 'Eastern District',
      },
      {
        label: "Manu'a",
      },
      {
        label: 'Rose Island',
      },
    ],
  },
  {
    label: 'Austria',
    countryCode: 'AT',
    countryCodeAlpha3: 'AUT',
    phone: '43',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Vienna',
      },
      {
        label: 'Vorarlberg',
      },
      {
        label: 'Tyrol',
      },
      {
        label: 'Styria',
      },
      {
        label: 'Salzburg',
      },
      {
        label: 'Upper Austria',
      },
      {
        label: 'Lower Austria',
      },
      {
        label: 'Carinthia',
      },
      {
        label: 'Burgenland',
      },
    ],
  },
  {
    label: 'Australia',
    countryCode: 'AU',
    countryCodeAlpha3: 'AUS',
    phone: '61',
    currency: 'AUD',
    stateProvinces: [
      {
        label: 'Western Australia',
      },
      {
        label: 'South Australia',
      },
      {
        label: 'Northern Territory',
      },
      {
        label: 'Victoria',
      },
      {
        label: 'Tasmania',
      },
      {
        label: 'Queensland',
      },
      {
        label: 'New South Wales',
      },
      {
        label: 'Australian Capital Territory',
      },
    ],
  },
  {
    label: 'Aruba',
    countryCode: 'AW',
    countryCodeAlpha3: 'ABW',
    phone: '297',
    currency: 'AWG',
    stateProvinces: null,
  },
  {
    label: 'Aland Islands',
    countryCode: 'AX',
    countryCodeAlpha3: 'ALA',
    phone: '+358-18',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Mariehamns stad',
      },
      {
        label: 'Ålands landsbygd',
      },
      {
        label: 'Ålands skärgård',
      },
    ],
  },
  {
    label: 'Azerbaijan',
    countryCode: 'AZ',
    countryCodeAlpha3: 'AZE',
    phone: '994',
    currency: 'AZN',
    stateProvinces: [
      {
        label: 'Beyləqan',
      },
      {
        label: 'Zǝngilan',
      },
      {
        label: 'Yardımlı',
      },
      {
        label: 'Shusha',
      },
      {
        label: 'Salyan',
      },
      {
        label: 'Sabirabad',
      },
      {
        label: 'Saatlı',
      },
      {
        label: 'Bilǝsuvar',
      },
      {
        label: 'Neftçala',
      },
      {
        label: 'Nakhichevan',
      },
      {
        label: 'Masally',
      },
      {
        label: 'Lerik',
      },
      {
        label: 'Lənkəran',
      },
      {
        label: 'Laçın',
      },
      {
        label: 'Qubadlı',
      },
      {
        label: 'İmişli',
      },
      {
        label: 'Füzuli',
      },
      {
        label: 'Jabrayil',
      },
      {
        label: 'Jalilabad',
      },
      {
        label: 'Astara',
      },
      {
        label: 'Xocalı',
      },
      {
        label: 'Ağcabǝdi',
      },
      {
        label: 'Ağdam',
      },
      {
        label: 'Shirvan',
      },
      {
        label: 'Lankaran Sahari',
      },
      {
        label: 'Shusha City',
      },
      {
        label: 'Tǝrtǝr',
      },
      {
        label: 'Xankǝndi',
      },
      {
        label: 'Khojavend',
      },
      {
        label: 'Zərdab',
      },
      {
        label: 'Zaqatala',
      },
      {
        label: 'Yevlax',
      },
      {
        label: 'Oğuz',
      },
      {
        label: 'Ucar',
      },
      {
        label: 'Tovuz',
      },
      {
        label: 'Şamaxı',
      },
      {
        label: 'Shaki',
      },
      {
        label: 'Şǝmkir',
      },
      {
        label: 'Kürdǝmir',
      },
      {
        label: 'Qǝbǝlǝ',
      },
      {
        label: 'Qusar',
      },
      {
        label: 'Quba',
      },
      {
        label: 'Goygol Rayon',
      },
      {
        label: 'Xaçmaz',
      },
      {
        label: 'Kalbajar',
      },
      {
        label: 'Qazax',
      },
      {
        label: 'Goranboy',
      },
      {
        label: 'Qax',
      },
      {
        label: 'İsmayıllı',
      },
      {
        label: 'Göyçay',
      },
      {
        label: 'Shabran',
      },
      {
        label: 'Daşkǝsǝn',
      },
      {
        label: 'Balakǝn',
      },
      {
        label: 'Barda',
      },
      {
        label: 'Baki',
      },
      {
        label: 'Abşeron',
      },
      {
        label: 'Ağsu',
      },
      {
        label: 'Ağdaş',
      },
      {
        label: 'Gǝdǝbǝy',
      },
      {
        label: 'Ağstafa',
      },
      {
        label: 'Gǝncǝ',
      },
      {
        label: 'Mingǝcevir',
      },
      {
        label: 'Naftalan',
      },
      {
        label: 'Qobustan',
      },
      {
        label: 'Samux',
      },
      {
        label: 'Shaki City',
      },
      {
        label: 'Siyǝzǝn',
      },
      {
        label: 'Sumqayit',
      },
      {
        label: 'Xızı',
      },
      {
        label: 'Yevlax City',
      },
      {
        label: 'Hacıqabul',
      },
    ],
  },
  {
    label: 'Bosnia and Herzegovina',
    countryCode: 'BA',
    countryCodeAlpha3: 'BIH',
    phone: '387',
    currency: 'BAM',
    stateProvinces: [
      {
        label: 'Federation of B&H',
      },
      {
        label: 'Srpska',
      },
      {
        label: 'Brčko',
      },
    ],
  },
  {
    label: 'Barbados',
    countryCode: 'BB',
    countryCodeAlpha3: 'BRB',
    phone: '+1-246',
    currency: 'BBD',
    stateProvinces: [
      {
        label: 'Saint Thomas',
      },
      {
        label: 'Saint Philip',
      },
      {
        label: 'Saint Peter',
      },
      {
        label: 'Saint Michael',
      },
      {
        label: 'Saint Lucy',
      },
      {
        label: 'Saint Joseph',
      },
      {
        label: 'Saint John',
      },
      {
        label: 'Saint James',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Saint Andrew',
      },
      {
        label: 'Christ Church',
      },
    ],
  },
  {
    label: 'Bangladesh',
    countryCode: 'BD',
    countryCodeAlpha3: 'BGD',
    phone: '880',
    currency: 'BDT',
    stateProvinces: [
      {
        label: 'Rajshahi Division',
      },
      {
        label: 'Dhaka',
      },
      {
        label: 'Chittagong',
      },
      {
        label: 'Khulna',
      },
      {
        label: 'Barisāl',
      },
      {
        label: 'Sylhet',
      },
      {
        label: 'Rangpur Division',
      },
      {
        label: 'Mymensingh Division',
      },
    ],
  },
  {
    label: 'Belgium',
    countryCode: 'BE',
    countryCodeAlpha3: 'BEL',
    phone: '32',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Brussels Capital',
      },
      {
        label: 'Wallonia',
      },
      {
        label: 'Flanders',
      },
    ],
  },
  {
    label: 'Burkina Faso',
    countryCode: 'BF',
    countryCodeAlpha3: 'BFA',
    phone: '226',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Boucle du Mouhoun',
      },
      {
        label: 'Cascades',
      },
      {
        label: 'Centre',
      },
      {
        label: 'Centre-Est',
      },
      {
        label: 'Centre-Nord',
      },
      {
        label: 'Centre-Ouest',
      },
      {
        label: 'Centre-Sud',
      },
      {
        label: 'Est',
      },
      {
        label: 'Hauts-Bassins',
      },
      {
        label: 'Nord',
      },
      {
        label: 'Plateau-Central',
      },
      {
        label: 'Sahel',
      },
      {
        label: 'Sud-Ouest',
      },
    ],
  },
  {
    label: 'Bulgaria',
    countryCode: 'BG',
    countryCodeAlpha3: 'BGR',
    phone: '359',
    currency: 'BGN',
    stateProvinces: [
      {
        label: 'Razgrad',
      },
      {
        label: 'Montana',
      },
      {
        label: 'Vratsa',
      },
      {
        label: 'Varna',
      },
      {
        label: 'Dobrich',
      },
      {
        label: 'Sofia',
      },
      {
        label: 'Ruse',
      },
      {
        label: 'Plovdiv',
      },
      {
        label: 'Pleven',
      },
      {
        label: 'Pernik',
      },
      {
        label: 'Pazardzhik',
      },
      {
        label: 'Lovech',
      },
      {
        label: 'Haskovo',
      },
      {
        label: 'Sofia-Capital',
      },
      {
        label: 'Burgas',
      },
      {
        label: 'Blagoevgrad',
      },
      {
        label: 'Gabrovo',
      },
      {
        label: 'Kardzhali',
      },
      {
        label: 'Kyustendil',
      },
      {
        label: 'Shumen',
      },
      {
        label: 'Silistra',
      },
      {
        label: 'Sliven',
      },
      {
        label: 'Smolyan',
      },
      {
        label: 'Stara Zagora',
      },
      {
        label: 'Targovishte',
      },
      {
        label: 'Veliko Tarnovo',
      },
      {
        label: 'Vidin',
      },
      {
        label: 'Yambol',
      },
    ],
  },
  {
    label: 'Bahrain',
    countryCode: 'BH',
    countryCodeAlpha3: 'BHR',
    phone: '973',
    currency: 'BHD',
    stateProvinces: [
      {
        label: 'Muharraq',
      },
      {
        label: 'Manama',
      },
      {
        label: 'Southern Governorate',
      },
      {
        label: 'Northern',
      },
    ],
  },
  {
    label: 'Burundi',
    countryCode: 'BI',
    countryCodeAlpha3: 'BDI',
    phone: '257',
    currency: 'BIF',
    stateProvinces: [
      {
        label: 'Makamba',
      },
      {
        label: 'Bururi',
      },
      {
        label: 'Muramvya',
      },
      {
        label: 'Gitega',
      },
      {
        label: 'Ruyigi',
      },
      {
        label: 'Cankuzo',
      },
      {
        label: 'Karuzi',
      },
      {
        label: 'Bubanza',
      },
      {
        label: 'Cibitoke',
      },
      {
        label: 'Ngozi',
      },
      {
        label: 'Kayanza',
      },
      {
        label: 'Muyinga',
      },
      {
        label: 'Kirundo',
      },
      {
        label: 'Rutana',
      },
      {
        label: 'Mwaro',
      },
      {
        label: 'Bujumbura Mairie',
      },
      {
        label: 'Bujumbura Rural',
      },
      {
        label: 'Rumonge',
      },
    ],
  },
  {
    label: 'Benin',
    countryCode: 'BJ',
    countryCodeAlpha3: 'BEN',
    phone: '229',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Zou',
      },
      {
        label: 'Ouémé',
      },
      {
        label: 'Mono',
      },
      {
        label: 'Borgou',
      },
      {
        label: 'Atlantique',
      },
      {
        label: 'Atakora',
      },
      {
        label: 'Alibori',
      },
      {
        label: 'Collines',
      },
      {
        label: 'Kouffo',
      },
      {
        label: 'Donga',
      },
      {
        label: 'Littoral',
      },
      {
        label: 'Plateau',
      },
    ],
  },
  {
    label: 'Saint Barthelemy',
    countryCode: 'BL',
    countryCodeAlpha3: 'BLM',
    phone: '590',
    currency: 'EUR',
    stateProvinces: null,
  },
  {
    label: 'Bermuda',
    countryCode: 'BM',
    countryCodeAlpha3: 'BMU',
    phone: '+1-441',
    currency: 'BMD',
    stateProvinces: [
      {
        label: 'Warwick',
      },
      {
        label: 'Southampton',
      },
      {
        label: 'Smithʼs',
      },
      {
        label: 'Sandys',
      },
      {
        label: 'Saint Georgeʼs',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Pembroke',
      },
      {
        label: 'Paget',
      },
      {
        label: 'Hamilton',
      },
      {
        label: 'Hamilton city',
      },
      {
        label: 'Devonshire',
      },
    ],
  },
  {
    label: 'Brunei',
    countryCode: 'BN',
    countryCodeAlpha3: 'BRN',
    phone: '673',
    currency: 'BND',
    stateProvinces: [
      {
        label: 'Tutong',
      },
      {
        label: 'Temburong',
      },
      {
        label: 'Brunei-Muara District',
      },
      {
        label: 'Belait',
      },
    ],
  },
  {
    label: 'Bolivia',
    countryCode: 'BO',
    countryCodeAlpha3: 'BOL',
    phone: '591',
    currency: 'BOB',
    stateProvinces: [
      {
        label: 'Tarija',
      },
      {
        label: 'Santa Cruz',
      },
      {
        label: 'Potosí',
      },
      {
        label: 'Pando',
      },
      {
        label: 'Oruro',
      },
      {
        label: 'La Paz',
      },
      {
        label: 'Cochabamba',
      },
      {
        label: 'Chuquisaca',
      },
      {
        label: 'El Beni',
      },
    ],
  },
  {
    label: 'Bonaire, Saint Eustatius and Saba',
    countryCode: 'BQ',
    countryCodeAlpha3: 'BES',
    phone: '599',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Bonaire',
      },
      {
        label: 'Saba',
      },
      {
        label: 'Sint Eustatius',
      },
    ],
  },
  {
    label: 'Brazil',
    countryCode: 'BR',
    countryCodeAlpha3: 'BRA',
    phone: '55',
    currency: 'BRL',
    stateProvinces: [
      {
        label: 'Rio Grande do Norte',
      },
      {
        label: 'Piauí',
      },
      {
        label: 'Pernambuco',
      },
      {
        label: 'Paraíba',
      },
      {
        label: 'Pará',
      },
      {
        label: 'Maranhão',
      },
      {
        label: 'Ceará',
      },
      {
        label: 'Amapá',
      },
      {
        label: 'Alagoas',
      },
      {
        label: 'Sergipe',
      },
      {
        label: 'São Paulo',
      },
      {
        label: 'Santa Catarina',
      },
      {
        label: 'Rio Grande do Sul',
      },
      {
        label: 'Rio de Janeiro',
      },
      {
        label: 'Paraná',
      },
      {
        label: 'Minas Gerais',
      },
      {
        label: 'Mato Grosso do Sul',
      },
      {
        label: 'Mato Grosso',
      },
      {
        label: 'Goiás',
      },
      {
        label: 'Federal District',
      },
      {
        label: 'Espírito Santo',
      },
      {
        label: 'Bahia',
      },
      {
        label: 'Tocantins',
      },
      {
        label: 'Roraima',
      },
      {
        label: 'Amazonas',
      },
      {
        label: 'Acre',
      },
      {
        label: 'Rondônia',
      },
    ],
  },
  {
    label: 'Bahamas',
    countryCode: 'BS',
    countryCodeAlpha3: 'BHS',
    phone: '+1-242',
    currency: 'BSD',
    stateProvinces: [
      {
        label: 'San Salvador',
      },
      {
        label: 'Ragged Island',
      },
      {
        label: 'Berry Islands',
      },
      {
        label: 'New Providence',
      },
      {
        label: 'Mayaguana',
      },
      {
        label: 'Long Island',
      },
      {
        label: 'Inagua',
      },
      {
        label: 'Harbour Island',
      },
      {
        label: 'Freeport',
      },
      {
        label: 'Exuma',
      },
      {
        label: 'Cat Island',
      },
      {
        label: 'Bimini',
      },
      {
        label: 'Acklins',
      },
      {
        label: 'Black Point',
      },
      {
        label: 'Central Abaco',
      },
      {
        label: 'Central Andros',
      },
      {
        label: 'Central Eleuthera',
      },
      {
        label: 'Crooked Island and Long Cay',
      },
      {
        label: 'East Grand Bahama',
      },
      {
        label: 'Grand Cay',
      },
      {
        label: 'Hope Town',
      },
      {
        label: 'Mangrove Cay',
      },
      {
        label: 'Moore’s Island',
      },
      {
        label: 'North Abaco',
      },
      {
        label: 'North Andros',
      },
      {
        label: 'North Eleuthera',
      },
      {
        label: 'Rum Cay',
      },
      {
        label: 'South Abaco',
      },
      {
        label: 'South Andros',
      },
      {
        label: 'South Eleuthera',
      },
      {
        label: 'Spanish Wells',
      },
      {
        label: 'West Grand Bahama',
      },
    ],
  },
  {
    label: 'Bhutan',
    countryCode: 'BT',
    countryCodeAlpha3: 'BTN',
    phone: '975',
    currency: 'BTN',
    stateProvinces: [
      {
        label: 'Bumthang',
      },
      {
        label: 'Chukha',
      },
      {
        label: 'Dagana',
      },
      {
        label: 'Chirang',
      },
      {
        label: 'Geylegphug',
      },
      {
        label: 'Haa',
      },
      {
        label: 'Lhuntse',
      },
      {
        label: 'Mongar',
      },
      {
        label: 'Paro',
      },
      {
        label: 'Pemagatshel',
      },
      {
        label: 'Punakha',
      },
      {
        label: 'Samchi',
      },
      {
        label: 'Samdrup Jongkhar',
      },
      {
        label: 'Shemgang',
      },
      {
        label: 'Tashigang',
      },
      {
        label: 'Thimphu',
      },
      {
        label: 'Tongsa',
      },
      {
        label: 'Wangdi Phodrang',
      },
      {
        label: 'Gasa',
      },
      {
        label: 'Trashi Yangste',
      },
    ],
  },
  {
    label: 'Bouvet Island',
    countryCode: 'BV',
    countryCodeAlpha3: 'BVT',
    phone: '',
    currency: 'NOK',
    stateProvinces: null,
  },
  {
    label: 'Botswana',
    countryCode: 'BW',
    countryCodeAlpha3: 'BWA',
    phone: '267',
    currency: 'BWP',
    stateProvinces: [
      {
        label: 'Ngwaketsi',
      },
      {
        label: 'South-East',
      },
      {
        label: 'North-East',
      },
      {
        label: 'North-West',
      },
      {
        label: 'Kweneng',
      },
      {
        label: 'Kgatleng',
      },
      {
        label: 'Kgalagadi',
      },
      {
        label: 'Ghanzi',
      },
      {
        label: 'Chobe',
      },
      {
        label: 'Central',
      },
      {
        label: 'City of Francistown',
      },
      {
        label: 'Gaborone',
      },
      {
        label: 'Jwaneng',
      },
      {
        label: 'Lobatse',
      },
      {
        label: 'Selibe Phikwe',
      },
      {
        label: 'Sowa Town',
      },
    ],
  },
  {
    label: 'Belarus',
    countryCode: 'BY',
    countryCodeAlpha3: 'BLR',
    phone: '375',
    currency: 'BYN',
    stateProvinces: [
      {
        label: 'Vitebsk',
      },
      {
        label: 'Mogilev',
      },
      {
        label: 'Minsk',
      },
      {
        label: 'Minsk City',
      },
      {
        label: 'Grodnenskaya',
      },
      {
        label: 'Gomel Oblast',
      },
      {
        label: 'Brest',
      },
    ],
  },
  {
    label: 'Belize',
    countryCode: 'BZ',
    countryCodeAlpha3: 'BLZ',
    phone: '501',
    currency: 'BZD',
    stateProvinces: [
      {
        label: 'Toledo',
      },
      {
        label: 'Southern District',
      },
      {
        label: 'Orange Walk',
      },
      {
        label: 'Corozal',
      },
      {
        label: 'Cayo',
      },
      {
        label: 'Belize',
      },
    ],
  },
  {
    label: 'Canada',
    countryCode: 'CA',
    countryCodeAlpha3: 'CAN',
    phone: '1',
    currency: 'CAD',
    stateProvinces: [
      {
        label: 'Alberta',
      },
      {
        label: 'British Columbia',
      },
      {
        label: 'Manitoba',
      },
      {
        label: 'New Brunswick',
      },
      {
        label: 'Northwest Territories',
      },
      {
        label: 'Nova Scotia',
      },
      {
        label: 'Nunavut',
      },
      {
        label: 'Ontario',
      },
      {
        label: 'Prince Edward Island',
      },
      {
        label: 'Quebec',
      },
      {
        label: 'Saskatchewan',
      },
      {
        label: 'Yukon',
      },
      {
        label: 'Newfoundland and Labrador',
      },
    ],
  },
  {
    label: 'Cocos Islands',
    countryCode: 'CC',
    countryCodeAlpha3: 'CCK',
    phone: '61',
    currency: 'AUD',
    stateProvinces: null,
  },
  {
    label: 'Democratic Republic of the Congo',
    countryCode: 'CD',
    countryCodeAlpha3: 'COD',
    phone: '243',
    currency: 'CDF',
    stateProvinces: [
      {
        label: 'Tshuapa',
      },
      {
        label: 'Tshopo',
      },
      {
        label: 'Tanganyika',
      },
      {
        label: 'South Kivu',
      },
      {
        label: 'Sankuru',
      },
      {
        label: 'Nord Kivu',
      },
      {
        label: 'Mongala',
      },
      {
        label: 'Maniema',
      },
      {
        label: 'Kasai-Central',
      },
      {
        label: 'Kasaï-Oriental',
      },
      {
        label: 'Kasai',
      },
      {
        label: 'Ituri',
      },
      {
        label: 'Haut-Uele',
      },
      {
        label: 'Haut-Lomami',
      },
      {
        label: 'Équateur',
      },
      {
        label: 'Bas-Uele',
      },
      {
        label: 'Lualaba',
      },
      {
        label: 'Mai-Ndombe',
      },
      {
        label: 'Kwilu',
      },
      {
        label: 'Kwango',
      },
      {
        label: 'Kinshasa',
      },
      {
        label: 'Bas-Congo',
      },
      {
        label: 'Haut-Katanga',
      },
      {
        label: 'Lomami',
      },
      {
        label: 'Nord-Ubangi',
      },
      {
        label: 'Sud-Ubangi',
      },
    ],
  },
  {
    label: 'Central African Republic',
    countryCode: 'CF',
    countryCodeAlpha3: 'CAF',
    phone: '236',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'Vakaga',
      },
      {
        label: 'Ouaka',
      },
      {
        label: 'Mbomou',
      },
      {
        label: 'Haut-Mbomou',
      },
      {
        label: 'Haute-Kotto',
      },
      {
        label: 'Basse-Kotto',
      },
      {
        label: 'Bamingui-Bangoran',
      },
      {
        label: 'Sangha-Mbaéré',
      },
      {
        label: 'Ouham-Pendé',
      },
      {
        label: 'Ouham',
      },
      {
        label: "Ombella-M'Poko",
      },
      {
        label: 'Nana-Mambéré',
      },
      {
        label: 'Lobaye',
      },
      {
        label: 'Kémo',
      },
      {
        label: 'Mambéré-Kadéï',
      },
      {
        label: 'Nana-Grébizi',
      },
      {
        label: 'Bangui',
      },
    ],
  },
  {
    label: 'Republic of the Congo',
    countryCode: 'CG',
    countryCodeAlpha3: 'COG',
    phone: '242',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'Sangha',
      },
      {
        label: 'Pool',
      },
      {
        label: 'Plateaux',
      },
      {
        label: 'Niari',
      },
      {
        label: 'Likouala',
      },
      {
        label: 'Lékoumou',
      },
      {
        label: 'Kouilou',
      },
      {
        label: 'Cuvette',
      },
      {
        label: 'Bouenza',
      },
      {
        label: 'Brazzaville',
      },
      {
        label: 'Cuvette-Ouest',
      },
      {
        label: 'Pointe-Noire',
      },
    ],
  },
  {
    label: 'Switzerland',
    countryCode: 'CH',
    countryCodeAlpha3: 'CHE',
    phone: '41',
    currency: 'CHF',
    stateProvinces: [
      {
        label: 'Zurich',
      },
      {
        label: 'Zug',
      },
      {
        label: 'Vaud',
      },
      {
        label: 'Valais',
      },
      {
        label: 'Uri',
      },
      {
        label: 'Ticino',
      },
      {
        label: 'Thurgau',
      },
      {
        label: 'Solothurn',
      },
      {
        label: 'Schwyz',
      },
      {
        label: 'Schaffhausen',
      },
      {
        label: 'Saint Gallen',
      },
      {
        label: 'Obwalden',
      },
      {
        label: 'Nidwalden',
      },
      {
        label: 'Neuchâtel',
      },
      {
        label: 'Lucerne',
      },
      {
        label: 'Jura',
      },
      {
        label: 'Grisons',
      },
      {
        label: 'Glarus',
      },
      {
        label: 'Geneva',
      },
      {
        label: 'Fribourg',
      },
      {
        label: 'Bern',
      },
      {
        label: 'Basel-City',
      },
      {
        label: 'Basel-Landschaft',
      },
      {
        label: 'Appenzell Ausserrhoden',
      },
      {
        label: 'Appenzell Innerrhoden',
      },
      {
        label: 'Aargau',
      },
    ],
  },
  {
    label: 'Ivory Coast',
    countryCode: 'CI',
    countryCodeAlpha3: 'CIV',
    phone: '225',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Yamoussoukro',
      },
      {
        label: 'Bas-Sassandra',
      },
      {
        label: 'Comoé',
      },
      {
        label: 'Denguélé',
      },
      {
        label: 'Gôh-Djiboua',
      },
      {
        label: 'Lacs',
      },
      {
        label: 'Lagunes',
      },
      {
        label: 'Montagnes',
      },
      {
        label: 'Sassandra-Marahoué',
      },
      {
        label: 'Savanes',
      },
      {
        label: 'Vallée du Bandama',
      },
      {
        label: 'Woroba',
      },
      {
        label: 'Zanzan',
      },
      {
        label: 'Abidjan',
      },
    ],
  },
  {
    label: 'Cook Islands',
    countryCode: 'CK',
    countryCodeAlpha3: 'COK',
    phone: '682',
    currency: 'NZD',
    stateProvinces: [
      {
        label: 'Aitutaki',
      },
      {
        label: 'Atiu',
      },
      {
        label: 'Mangaia',
      },
      {
        label: 'Manihiki',
      },
      {
        label: "Ma'uke",
      },
      {
        label: 'Mitiaro',
      },
      {
        label: 'Palmerston',
      },
      {
        label: 'Penrhyn',
      },
      {
        label: 'Pukapuka',
      },
      {
        label: 'Rakahanga',
      },
      {
        label: 'Rarotonga',
      },
    ],
  },
  {
    label: 'Chile',
    countryCode: 'CL',
    countryCodeAlpha3: 'CHL',
    phone: '56',
    currency: 'CLP',
    stateProvinces: [
      {
        label: 'Valparaíso',
      },
      {
        label: 'Tarapacá',
      },
      {
        label: 'Santiago Metropolitan',
      },
      {
        label: 'Maule Region',
      },
      {
        label: 'Los Lagos Region',
      },
      {
        label: "O'Higgins Region",
      },
      {
        label: 'Coquimbo Region',
      },
      {
        label: 'Biobío',
      },
      {
        label: 'Atacama',
      },
      {
        label: 'Araucanía',
      },
      {
        label: 'Antofagasta',
      },
      {
        label: 'Aysén',
      },
      {
        label: 'Region of Magallanes',
      },
      {
        label: 'Arica y Parinacota',
      },
      {
        label: 'Los Ríos Region',
      },
      {
        label: 'Ñuble',
      },
    ],
  },
  {
    label: 'Cameroon',
    countryCode: 'CM',
    countryCodeAlpha3: 'CMR',
    phone: '237',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'South-West',
      },
      {
        label: 'South',
      },
      {
        label: 'West',
      },
      {
        label: 'North-West',
      },
      {
        label: 'North',
      },
      {
        label: 'Littoral',
      },
      {
        label: 'Far North',
      },
      {
        label: 'East',
      },
      {
        label: 'Centre',
      },
      {
        label: 'Adamaoua',
      },
    ],
  },
  {
    label: 'China',
    countryCode: 'CN',
    countryCodeAlpha3: 'CHN',
    phone: '86',
    currency: 'CNY',
    stateProvinces: [
      {
        label: 'Tibet',
      },
      {
        label: 'Qinghai',
      },
      {
        label: 'Xinjiang',
      },
      {
        label: 'Zhejiang',
      },
      {
        label: 'Yunnan',
      },
      {
        label: 'Tianjin',
      },
      {
        label: 'Sichuan',
      },
      {
        label: 'Shanxi',
      },
      {
        label: 'Shanghai',
      },
      {
        label: 'Shandong',
      },
      {
        label: 'Shaanxi',
      },
      {
        label: 'Ningxia Hui Autonomous Region',
      },
      {
        label: 'Jiangxi',
      },
      {
        label: 'Jiangsu',
      },
      {
        label: 'Hunan',
      },
      {
        label: 'Hubei',
      },
      {
        label: 'Henan',
      },
      {
        label: 'Hebei',
      },
      {
        label: 'Hainan',
      },
      {
        label: 'Guizhou',
      },
      {
        label: 'Guangxi',
      },
      {
        label: 'Guangdong',
      },
      {
        label: 'Gansu',
      },
      {
        label: 'Fujian',
      },
      {
        label: 'Chongqing',
      },
      {
        label: 'Anhui',
      },
      {
        label: 'Inner Mongolia',
      },
      {
        label: 'Liaoning',
      },
      {
        label: 'Jilin',
      },
      {
        label: 'Heilongjiang',
      },
      {
        label: 'Beijing',
      },
    ],
  },
  {
    label: 'Colombia',
    countryCode: 'CO',
    countryCodeAlpha3: 'COL',
    phone: '57',
    currency: 'COP',
    stateProvinces: [
      {
        label: 'Vichada',
      },
      {
        label: 'Vaupés',
      },
      {
        label: 'Valle del Cauca',
      },
      {
        label: 'Tolima',
      },
      {
        label: 'Sucre',
      },
      {
        label: 'Santander',
      },
      {
        label: 'San Andres y Providencia',
      },
      {
        label: 'Risaralda',
      },
      {
        label: 'Quindío',
      },
      {
        label: 'Putumayo',
      },
      {
        label: 'Norte de Santander Department',
      },
      {
        label: 'Nariño',
      },
      {
        label: 'Meta',
      },
      {
        label: 'Magdalena',
      },
      {
        label: 'La Guajira Department',
      },
      {
        label: 'Huila',
      },
      {
        label: 'Guaviare',
      },
      {
        label: 'Guainía Department',
      },
      {
        label: 'Cundinamarca',
      },
      {
        label: 'Córdoba',
      },
      {
        label: 'Chocó',
      },
      {
        label: 'Cesar',
      },
      {
        label: 'Cauca',
      },
      {
        label: 'Casanare Department',
      },
      {
        label: 'Caquetá',
      },
      {
        label: 'Caldas Department',
      },
      {
        label: 'Boyacá',
      },
      {
        label: 'Bolívar',
      },
      {
        label: 'Bogota D.C.',
      },
      {
        label: 'Atlántico',
      },
      {
        label: 'Departamento de Arauca',
      },
      {
        label: 'Antioquia',
      },
      {
        label: 'Amazonas',
      },
    ],
  },
  {
    label: 'Costa Rica',
    countryCode: 'CR',
    countryCodeAlpha3: 'CRI',
    phone: '506',
    currency: 'CRC',
    stateProvinces: [
      {
        label: 'San José',
      },
      {
        label: 'Puntarenas Province',
      },
      {
        label: 'Limón Province',
      },
      {
        label: 'Heredia Province',
      },
      {
        label: 'Guanacaste Province',
      },
      {
        label: 'Cartago Province',
      },
      {
        label: 'Alajuela Province',
      },
    ],
  },
  {
    label: 'Cuba',
    countryCode: 'CU',
    countryCodeAlpha3: 'CUB',
    phone: '53',
    currency: 'CUP',
    stateProvinces: [
      {
        label: 'Villa Clara Province',
      },
      {
        label: 'Santiago de Cuba',
      },
      {
        label: 'Sancti Spíritus Province',
      },
      {
        label: 'Pinar del Río',
      },
      {
        label: 'Matanzas Province',
      },
      {
        label: 'Las Tunas',
      },
      {
        label: 'Isla de la Juventud',
      },
      {
        label: 'Holguín Province',
      },
      {
        label: 'Guantánamo Province',
      },
      {
        label: 'Granma Province',
      },
      {
        label: 'Havana',
      },
      {
        label: 'Cienfuegos Province',
      },
      {
        label: 'Ciego de Ávila Province',
      },
      {
        label: 'Camagüey',
      },
      {
        label: 'Artemisa',
      },
      {
        label: 'Mayabeque',
      },
    ],
  },
  {
    label: 'Cabo Verde',
    countryCode: 'CV',
    countryCodeAlpha3: 'CPV',
    phone: '238',
    currency: 'CVE',
    stateProvinces: [
      {
        label: 'Tarrafal',
      },
      {
        label: 'São Vicente',
      },
      {
        label: 'Santa Catarina',
      },
      {
        label: 'Sal',
      },
      {
        label: 'Ribeira Grande',
      },
      {
        label: 'Praia',
      },
      {
        label: 'Paul',
      },
      {
        label: 'Maio',
      },
      {
        label: 'Brava',
      },
      {
        label: 'Boa Vista',
      },
      {
        label: 'Mosteiros',
      },
      {
        label: 'Santa Cruz',
      },
      {
        label: 'São Domingos',
      },
      {
        label: 'São Filipe',
      },
      {
        label: 'São Miguel',
      },
      {
        label: 'Porto Novo',
      },
      {
        label: 'Ribeira Brava',
      },
      {
        label: 'Santa Catarina do Fogo',
      },
      {
        label: 'São Salvador do Mundo',
      },
      {
        label: 'Tarrafal de São Nicolau',
      },
      {
        label: 'São Lourenço dos Órgãos',
      },
      {
        label: 'Ribeira Grande de Santiago',
      },
    ],
  },
  {
    label: 'Curacao',
    countryCode: 'CW',
    countryCodeAlpha3: 'CUW',
    phone: '599',
    currency: 'ANG',
    stateProvinces: null,
  },
  {
    label: 'Christmas Island',
    countryCode: 'CX',
    countryCodeAlpha3: 'CXR',
    phone: '61',
    currency: 'AUD',
    stateProvinces: null,
  },
  {
    label: 'Cyprus',
    countryCode: 'CY',
    countryCodeAlpha3: 'CYP',
    phone: '357',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Pafos',
      },
      {
        label: 'Nicosia',
      },
      {
        label: 'Limassol',
      },
      {
        label: 'Larnaka',
      },
      {
        label: 'Keryneia',
      },
      {
        label: 'Ammochostos',
      },
    ],
  },
  {
    label: 'Czechia',
    countryCode: 'CZ',
    countryCodeAlpha3: 'CZE',
    phone: '420',
    currency: 'CZK',
    stateProvinces: [
      {
        label: 'Hlavní město Praha',
      },
      {
        label: 'South Moravian',
      },
      {
        label: 'Jihočeský kraj',
      },
      {
        label: 'Vysočina',
      },
      {
        label: 'Karlovarský kraj',
      },
      {
        label: 'Královéhradecký kraj',
      },
      {
        label: 'Liberecký kraj',
      },
      {
        label: 'Olomoucký',
      },
      {
        label: 'Moravskoslezský',
      },
      {
        label: 'Pardubický',
      },
      {
        label: 'Plzeň Region',
      },
      {
        label: 'Central Bohemia',
      },
      {
        label: 'Ústecký kraj',
      },
      {
        label: 'Zlín',
      },
    ],
  },
  {
    label: 'Germany',
    countryCode: 'DE',
    countryCodeAlpha3: 'DEU',
    phone: '49',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Thuringia',
      },
      {
        label: 'Schleswig-Holstein',
      },
      {
        label: 'Saxony-Anhalt',
      },
      {
        label: 'Saxony',
      },
      {
        label: 'Saarland',
      },
      {
        label: 'Rheinland-Pfalz',
      },
      {
        label: 'North Rhine-Westphalia',
      },
      {
        label: 'Lower Saxony',
      },
      {
        label: 'Mecklenburg-Vorpommern',
      },
      {
        label: 'Hesse',
      },
      {
        label: 'Hamburg',
      },
      {
        label: 'Bremen',
      },
      {
        label: 'Brandenburg',
      },
      {
        label: 'Berlin',
      },
      {
        label: 'Bavaria',
      },
      {
        label: 'Baden-Wurttemberg',
      },
    ],
  },
  {
    label: 'Djibouti',
    countryCode: 'DJ',
    countryCodeAlpha3: 'DJI',
    phone: '253',
    currency: 'DJF',
    stateProvinces: [
      {
        label: 'Tadjourah',
      },
      {
        label: 'Obock',
      },
      {
        label: 'Djibouti',
      },
      {
        label: 'Dikhil',
      },
      {
        label: 'Ali Sabieh',
      },
      {
        label: 'Arta',
      },
    ],
  },
  {
    label: 'Denmark',
    countryCode: 'DK',
    countryCodeAlpha3: 'DNK',
    phone: '45',
    currency: 'DKK',
    stateProvinces: [
      {
        label: 'Capital Region',
      },
      {
        label: 'Central Jutland',
      },
      {
        label: 'North Denmark',
      },
      {
        label: 'Zealand',
      },
      {
        label: 'South Denmark',
      },
    ],
  },
  {
    label: 'Dominica',
    countryCode: 'DM',
    countryCodeAlpha3: 'DMA',
    phone: '+1-767',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Saint Peter',
      },
      {
        label: 'Saint Paul',
      },
      {
        label: 'Saint Patrick',
      },
      {
        label: 'Saint Mark',
      },
      {
        label: 'Saint Luke',
      },
      {
        label: 'Saint Joseph',
      },
      {
        label: 'Saint John',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Saint David',
      },
      {
        label: 'Saint Andrew',
      },
    ],
  },
  {
    label: 'Dominican Republic',
    countryCode: 'DO',
    countryCodeAlpha3: 'DOM',
    phone: '+1-809 and 1-829',
    currency: 'DOP',
    stateProvinces: [
      {
        label: 'Valverde',
      },
      {
        label: 'Santiago Rodríguez',
      },
      {
        label: 'Santiago',
      },
      {
        label: 'San Pedro de Macorís',
      },
      {
        label: 'San Juan',
      },
      {
        label: 'San Cristóbal',
      },
      {
        label: 'Sánchez Ramírez',
      },
      {
        label: 'Samaná',
      },
      {
        label: 'Hermanas Mirabal',
      },
      {
        label: 'Puerto Plata',
      },
      {
        label: 'Peravia',
      },
      {
        label: 'Pedernales',
      },
      {
        label: 'Nacional',
      },
      {
        label: 'Monte Plata',
      },
      {
        label: 'Monte Cristi',
      },
      {
        label: 'Monseñor Nouel',
      },
      {
        label: 'María Trinidad Sánchez',
      },
      {
        label: 'La Vega',
      },
      {
        label: 'La Romana',
      },
      {
        label: 'La Altagracia',
      },
      {
        label: 'Independencia',
      },
      {
        label: 'Hato Mayor',
      },
      {
        label: 'Espaillat',
      },
      {
        label: 'El Seíbo',
      },
      {
        label: 'Elías Piña',
      },
      {
        label: 'Duarte',
      },
      {
        label: 'Dajabón',
      },
      {
        label: 'Barahona',
      },
      {
        label: 'Baoruco',
      },
      {
        label: 'Azua',
      },
      {
        label: 'San José de Ocoa',
      },
      {
        label: 'Santo Domingo',
      },
    ],
  },
  {
    label: 'Algeria',
    countryCode: 'DZ',
    countryCodeAlpha3: 'DZA',
    phone: '213',
    currency: 'DZD',
    stateProvinces: [
      {
        label: 'Tlemcen',
      },
      {
        label: 'Tizi Ouzou',
      },
      {
        label: 'Tissemsilt',
      },
      {
        label: 'Tipaza',
      },
      {
        label: 'Tindouf',
      },
      {
        label: 'Tiaret',
      },
      {
        label: 'Tébessa',
      },
      {
        label: 'Tamanrasset',
      },
      {
        label: 'Souk Ahras',
      },
      {
        label: 'Skikda',
      },
      {
        label: 'Sidi Bel Abbès',
      },
      {
        label: 'Sétif',
      },
      {
        label: 'Saida',
      },
      {
        label: 'Relizane',
      },
      {
        label: 'Oum el Bouaghi',
      },
      {
        label: 'Ouargla',
      },
      {
        label: 'Oran',
      },
      {
        label: 'Naama',
      },
      {
        label: "M'Sila",
      },
      {
        label: 'Mostaganem',
      },
      {
        label: 'Mila',
      },
      {
        label: 'Medea',
      },
      {
        label: 'Mascara',
      },
      {
        label: 'Laghouat',
      },
      {
        label: 'Khenchela',
      },
      {
        label: 'Jijel',
      },
      {
        label: 'Illizi',
      },
      {
        label: 'Guelma',
      },
      {
        label: 'Ghardaia',
      },
      {
        label: 'El Tarf',
      },
      {
        label: 'El Oued',
      },
      {
        label: 'El Bayadh',
      },
      {
        label: 'Djelfa',
      },
      {
        label: 'Constantine',
      },
      {
        label: 'Chlef',
      },
      {
        label: 'Boumerdes',
      },
      {
        label: 'Bouira',
      },
      {
        label: 'Bordj Bou Arréridj',
      },
      {
        label: 'Blida',
      },
      {
        label: 'Biskra',
      },
      {
        label: 'Béjaïa',
      },
      {
        label: 'Béchar',
      },
      {
        label: 'Batna',
      },
      {
        label: 'Annaba',
      },
      {
        label: 'Algiers',
      },
      {
        label: 'Aïn Témouchent',
      },
      {
        label: 'Aïn Defla',
      },
      {
        label: 'Adrar',
      },
      {
        label: 'Bordj Badji Mokhtar',
      },
      {
        label: 'Timimoun',
      },
      {
        label: 'Beni Abbes',
      },
      {
        label: 'In Guezzam',
      },
      {
        label: 'In Salah',
      },
      {
        label: 'Djanet',
      },
      {
        label: 'El Menia',
      },
      {
        label: 'Touggourt',
      },
      {
        label: 'El Mghair',
      },
      {
        label: 'Ouled Djellal',
      },
    ],
  },
  {
    label: 'Ecuador',
    countryCode: 'EC',
    countryCodeAlpha3: 'ECU',
    phone: '593',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Zamora-Chinchipe',
      },
      {
        label: 'Tungurahua',
      },
      {
        label: 'Pichincha',
      },
      {
        label: 'Pastaza',
      },
      {
        label: 'Napo',
      },
      {
        label: 'Morona-Santiago',
      },
      {
        label: 'Manabí',
      },
      {
        label: 'Los Ríos',
      },
      {
        label: 'Loja',
      },
      {
        label: 'Imbabura',
      },
      {
        label: 'Guayas',
      },
      {
        label: 'Galápagos',
      },
      {
        label: 'Esmeraldas',
      },
      {
        label: 'El Oro',
      },
      {
        label: 'Cotopaxi',
      },
      {
        label: 'Chimborazo',
      },
      {
        label: 'Carchi',
      },
      {
        label: 'Cañar',
      },
      {
        label: 'Bolívar',
      },
      {
        label: 'Azuay',
      },
      {
        label: 'Sucumbios',
      },
      {
        label: 'Orellana',
      },
      {
        label: 'Santo Domingo de los Tsáchilas',
      },
      {
        label: 'Santa Elena',
      },
    ],
  },
  {
    label: 'Estonia',
    countryCode: 'EE',
    countryCodeAlpha3: 'EST',
    phone: '372',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Võrumaa',
      },
      {
        label: 'Viljandimaa',
      },
      {
        label: 'Valgamaa',
      },
      {
        label: 'Tartu',
      },
      {
        label: 'Saare',
      },
      {
        label: 'Raplamaa',
      },
      {
        label: 'Põlvamaa',
      },
      {
        label: 'Pärnumaa',
      },
      {
        label: 'Lääne-Virumaa',
      },
      {
        label: 'Lääne',
      },
      {
        label: 'Jõgevamaa',
      },
      {
        label: 'Järvamaa',
      },
      {
        label: 'Ida-Virumaa',
      },
      {
        label: 'Hiiumaa',
      },
      {
        label: 'Harjumaa',
      },
    ],
  },
  {
    label: 'Egypt',
    countryCode: 'EG',
    countryCodeAlpha3: 'EGY',
    phone: '20',
    currency: 'EGP',
    stateProvinces: [
      {
        label: 'Sohag',
      },
      {
        label: 'North Sinai',
      },
      {
        label: 'Qena',
      },
      {
        label: 'Matruh',
      },
      {
        label: 'Kafr el-Sheikh',
      },
      {
        label: 'South Sinai',
      },
      {
        label: 'Damietta',
      },
      {
        label: 'Port Said',
      },
      {
        label: 'Beni Suweif',
      },
      {
        label: 'Asyut',
      },
      {
        label: 'Aswan',
      },
      {
        label: 'Suez',
      },
      {
        label: 'Sharqia',
      },
      {
        label: 'New Valley',
      },
      {
        label: 'Qalyubia',
      },
      {
        label: 'Cairo',
      },
      {
        label: 'Minya',
      },
      {
        label: 'Monufia',
      },
      {
        label: 'Giza',
      },
      {
        label: 'Ismailia',
      },
      {
        label: 'Alexandria',
      },
      {
        label: 'Gharbia',
      },
      {
        label: 'Faiyum',
      },
      {
        label: 'Beheira',
      },
      {
        label: 'Red Sea',
      },
      {
        label: 'Dakahlia',
      },
      {
        label: 'Luxor',
      },
    ],
  },
  {
    label: 'Western Sahara',
    countryCode: 'EH',
    countryCodeAlpha3: 'ESH',
    phone: '212',
    currency: 'MAD',
    stateProvinces: null,
  },
  {
    label: 'Eritrea',
    countryCode: 'ER',
    countryCodeAlpha3: 'ERI',
    phone: '291',
    currency: 'ERN',
    stateProvinces: [
      {
        label: 'Anseba',
      },
      {
        label: 'Debub',
      },
      {
        label: 'Southern Red Sea',
      },
      {
        label: 'Gash-Barka',
      },
      {
        label: 'Maekel',
      },
      {
        label: 'Northern Red Sea',
      },
    ],
  },
  {
    label: 'Spain',
    countryCode: 'ES',
    countryCodeAlpha3: 'ESP',
    phone: '34',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Murcia',
      },
      {
        label: 'Ceuta',
      },
      {
        label: 'Balearic Islands',
      },
      {
        label: 'Andalusia',
      },
      {
        label: 'Canary Islands',
      },
      {
        label: 'Castille-La Mancha',
      },
      {
        label: 'Extremadura',
      },
      {
        label: 'Valencia',
      },
      {
        label: 'Asturias',
      },
      {
        label: 'Navarre',
      },
      {
        label: 'Madrid',
      },
      {
        label: 'La Rioja',
      },
      {
        label: 'Cantabria',
      },
      {
        label: 'Aragon',
      },
      {
        label: 'Castille and León',
      },
      {
        label: 'Catalonia',
      },
      {
        label: 'Galicia',
      },
      {
        label: 'Basque Country',
      },
      {
        label: 'Melilla',
      },
    ],
  },
  {
    label: 'Ethiopia',
    countryCode: 'ET',
    countryCodeAlpha3: 'ETH',
    phone: '251',
    currency: 'ETB',
    stateProvinces: [
      {
        label: 'Addis Ababa',
      },
      {
        label: 'Āfar',
      },
      {
        label: 'Amhara',
      },
      {
        label: 'Bīnshangul Gumuz',
      },
      {
        label: 'Dire Dawa',
      },
      {
        label: 'Gambela',
      },
      {
        label: 'Harari',
      },
      {
        label: 'Oromiya',
      },
      {
        label: 'Somali',
      },
      {
        label: 'Tigray',
      },
      {
        label: 'SNNPR',
      },
    ],
  },
  {
    label: 'Finland',
    countryCode: 'FI',
    countryCodeAlpha3: 'FIN',
    phone: '358',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Lapland',
      },
      {
        label: 'Kainuu',
      },
      {
        label: 'North Ostrobothnia',
      },
      {
        label: 'Central Ostrobothnia',
      },
      {
        label: 'Ostrobothnia',
      },
      {
        label: 'South Ostrobothnia',
      },
      {
        label: 'Central Finland',
      },
      {
        label: 'North Karelia',
      },
      {
        label: 'North Savo',
      },
      {
        label: 'South Savo',
      },
      {
        label: 'South Karelia',
      },
      {
        label: 'Kymenlaakso',
      },
      {
        label: 'Pirkanmaa',
      },
      {
        label: 'Kanta-Häme',
      },
      {
        label: 'Southwest Finland',
      },
      {
        label: 'Uusimaa',
      },
      {
        label: 'Paijat-Hame',
      },
      {
        label: 'Satakunta',
      },
    ],
  },
  {
    label: 'Fiji',
    countryCode: 'FJ',
    countryCodeAlpha3: 'FJI',
    phone: '679',
    currency: 'FJD',
    stateProvinces: [
      {
        label: 'Western',
      },
      {
        label: 'Northern',
      },
      {
        label: 'Central',
      },
      {
        label: 'Eastern',
      },
      {
        label: 'Rotuma',
      },
    ],
  },
  {
    label: 'Falkland Islands',
    countryCode: 'FK',
    countryCodeAlpha3: 'FLK',
    phone: '500',
    currency: 'FKP',
    stateProvinces: null,
  },
  {
    label: 'Micronesia',
    countryCode: 'FM',
    countryCodeAlpha3: 'FSM',
    phone: '691',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Yap',
      },
      {
        label: 'Pohnpei',
      },
      {
        label: 'Kosrae',
      },
      {
        label: 'Chuuk',
      },
    ],
  },
  {
    label: 'Faroe Islands',
    countryCode: 'FO',
    countryCodeAlpha3: 'FRO',
    phone: '298',
    currency: 'DKK',
    stateProvinces: [
      {
        label: 'Vágar',
      },
      {
        label: 'Suðuroy',
      },
      {
        label: 'Streymoy',
      },
      {
        label: 'Sandoy',
      },
      {
        label: 'Norðoyar',
      },
      {
        label: 'Eysturoy',
      },
    ],
  },
  {
    label: 'France',
    countryCode: 'FR',
    countryCodeAlpha3: 'FRA',
    phone: '33',
    currency: 'EUR',
    stateProvinces: [
      {
        label: "Provence-Alpes-Côte d'Azur",
      },
      {
        label: 'Pays de la Loire',
      },
      {
        label: 'Île-de-France',
      },
      {
        label: 'Corsica',
      },
      {
        label: 'Centre',
      },
      {
        label: 'Brittany',
      },
      {
        label: 'Bourgogne-Franche-Comté',
      },
      {
        label: 'Nouvelle-Aquitaine',
      },
      {
        label: 'Normandy',
      },
      {
        label: 'Grand Est',
      },
      {
        label: 'Occitanie',
      },
      {
        label: 'Hauts-de-France',
      },
      {
        label: 'Auvergne-Rhône-Alpes',
      },
    ],
  },
  {
    label: 'Gabon',
    countryCode: 'GA',
    countryCodeAlpha3: 'GAB',
    phone: '241',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'Woleu-Ntem',
      },
      {
        label: 'Ogooué-Maritime',
      },
      {
        label: 'Ogooué-Lolo',
      },
      {
        label: 'Ogooué-Ivindo',
      },
      {
        label: 'Nyanga',
      },
      {
        label: 'Ngouni',
      },
      {
        label: 'Moyen-Ogooué',
      },
      {
        label: 'Haut-Ogooué',
      },
      {
        label: 'Estuaire',
      },
    ],
  },
  {
    label: 'United Kingdom',
    countryCode: 'GB',
    countryCodeAlpha3: 'GBR',
    phone: '44',
    currency: 'GBP',
    stateProvinces: [
      {
        label: 'Wales',
      },
      {
        label: 'Scotland',
      },
      {
        label: 'Northern Ireland',
      },
      {
        label: 'England',
      },
    ],
  },
  {
    label: 'Grenada',
    countryCode: 'GD',
    countryCodeAlpha3: 'GRD',
    phone: '+1-473',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Saint Patrick',
      },
      {
        label: 'Saint Mark',
      },
      {
        label: 'Saint John',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Saint David',
      },
      {
        label: 'Saint Andrew',
      },
      {
        label: 'Carriacou and Petite Martinique',
      },
    ],
  },
  {
    label: 'Georgia',
    countryCode: 'GE',
    countryCodeAlpha3: 'GEO',
    phone: '995',
    currency: 'GEL',
    stateProvinces: [
      {
        label: "T'bilisi",
      },
      {
        label: 'Achara',
      },
      {
        label: 'Kvemo Kartli',
      },
      {
        label: 'Kakheti',
      },
      {
        label: 'Guria',
      },
      {
        label: 'Imereti',
      },
      {
        label: 'Shida Kartli',
      },
      {
        label: 'Mtskheta-Mtianeti',
      },
      {
        label: 'Racha-Lechkhumi and Kvemo Svaneti',
      },
      {
        label: 'Samegrelo and Zemo Svaneti',
      },
      {
        label: 'Samtskhe-Javakheti',
      },
      {
        label: 'Abkhazia',
      },
    ],
  },
  {
    label: 'French Guiana',
    countryCode: 'GF',
    countryCodeAlpha3: 'GUF',
    phone: '594',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Guyane',
      },
    ],
  },
  {
    label: 'Guernsey',
    countryCode: 'GG',
    countryCodeAlpha3: 'GGY',
    phone: '+44-1481',
    currency: 'GBP',
    stateProvinces: [
      {
        label: 'St Pierre du Bois',
      },
      {
        label: 'Torteval',
      },
      {
        label: 'Saint Saviour',
      },
      {
        label: 'Forest',
      },
      {
        label: 'St Martin',
      },
      {
        label: 'Saint Andrew',
      },
      {
        label: 'St Peter Port',
      },
      {
        label: 'Castel',
      },
      {
        label: 'Vale',
      },
      {
        label: 'St Sampson',
      },
      {
        label: 'Alderney',
      },
    ],
  },
  {
    label: 'Ghana',
    countryCode: 'GH',
    countryCodeAlpha3: 'GHA',
    phone: '233',
    currency: 'GHS',
    stateProvinces: [
      {
        label: 'Western',
      },
      {
        label: 'Volta',
      },
      {
        label: 'Upper West',
      },
      {
        label: 'Upper East',
      },
      {
        label: 'Northern',
      },
      {
        label: 'Greater Accra',
      },
      {
        label: 'Eastern',
      },
      {
        label: 'Central',
      },
      {
        label: 'Ashanti',
      },
      {
        label: 'Bono',
      },
      {
        label: 'Oti',
      },
      {
        label: 'North East',
      },
      {
        label: 'Ahafo',
      },
      {
        label: 'Bono East',
      },
      {
        label: 'Savannah',
      },
      {
        label: 'Western North',
      },
    ],
  },
  {
    label: 'Gibraltar',
    countryCode: 'GI',
    countryCodeAlpha3: 'GIB',
    phone: '350',
    currency: 'GIP',
    stateProvinces: null,
  },
  {
    label: 'Greenland',
    countryCode: 'GL',
    countryCodeAlpha3: 'GRL',
    phone: '299',
    currency: 'DKK',
    stateProvinces: [
      {
        label: 'Kujalleq',
      },
      {
        label: 'Qeqqata',
      },
      {
        label: 'Sermersooq',
      },
      {
        label: 'Qeqertalik',
      },
      {
        label: 'Avannaata',
      },
    ],
  },
  {
    label: 'Gambia',
    countryCode: 'GM',
    countryCodeAlpha3: 'GMB',
    phone: '220',
    currency: 'GMD',
    stateProvinces: [
      {
        label: 'Western',
      },
      {
        label: 'Upper River',
      },
      {
        label: 'North Bank',
      },
      {
        label: 'Central River',
      },
      {
        label: 'Lower River',
      },
      {
        label: 'Banjul',
      },
    ],
  },
  {
    label: 'Guinea',
    countryCode: 'GN',
    countryCodeAlpha3: 'GIN',
    phone: '224',
    currency: 'GNF',
    stateProvinces: [
      {
        label: 'Conakry',
      },
      {
        label: 'Boke',
      },
      {
        label: 'Faranah',
      },
      {
        label: 'Kankan',
      },
      {
        label: 'Kindia',
      },
      {
        label: 'Labe',
      },
      {
        label: 'Mamou',
      },
      {
        label: 'Nzerekore',
      },
    ],
  },
  {
    label: 'Guadeloupe',
    countryCode: 'GP',
    countryCodeAlpha3: 'GLP',
    phone: '590',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Guadeloupe',
      },
    ],
  },
  {
    label: 'Equatorial Guinea',
    countryCode: 'GQ',
    countryCodeAlpha3: 'GNQ',
    phone: '240',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'Annobon',
      },
      {
        label: 'Bioko Norte',
      },
      {
        label: 'Bioko Sur',
      },
      {
        label: 'Centro Sur',
      },
      {
        label: 'Kié-Ntem',
      },
      {
        label: 'Litoral',
      },
      {
        label: 'Wele-Nzas',
      },
      {
        label: 'Djibloho',
      },
    ],
  },
  {
    label: 'Greece',
    countryCode: 'GR',
    countryCodeAlpha3: 'GRC',
    phone: '30',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Mount Athos',
      },
      {
        label: 'Attica',
      },
      {
        label: 'Central Greece',
      },
      {
        label: 'Central Macedonia',
      },
      {
        label: 'Crete',
      },
      {
        label: 'East Macedonia and Thrace',
      },
      {
        label: 'Epirus',
      },
      {
        label: 'Ionian Islands',
      },
      {
        label: 'North Aegean',
      },
      {
        label: 'Peloponnese',
      },
      {
        label: 'South Aegean',
      },
      {
        label: 'Thessaly',
      },
      {
        label: 'West Greece',
      },
      {
        label: 'West Macedonia',
      },
    ],
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    countryCodeAlpha3: 'SGS',
    phone: '',
    currency: 'GBP',
    stateProvinces: null,
  },
  {
    label: 'Guatemala',
    countryCode: 'GT',
    countryCodeAlpha3: 'GTM',
    phone: '502',
    currency: 'GTQ',
    stateProvinces: [
      {
        label: 'Zacapa',
      },
      {
        label: 'Totonicapán',
      },
      {
        label: 'Suchitepeque',
      },
      {
        label: 'Sololá',
      },
      {
        label: 'Santa Rosa Department',
      },
      {
        label: 'San Marcos',
      },
      {
        label: 'Sacatepéquez',
      },
      {
        label: 'Retalhuleu',
      },
      {
        label: 'Quiché',
      },
      {
        label: 'Quetzaltenango',
      },
      {
        label: 'Petén',
      },
      {
        label: 'Jutiapa',
      },
      {
        label: 'Jalapa',
      },
      {
        label: 'Izabal Department',
      },
      {
        label: 'Huehuetenango',
      },
      {
        label: 'Guatemala',
      },
      {
        label: 'Escuintla',
      },
      {
        label: 'El Progreso',
      },
      {
        label: 'Chiquimula',
      },
      {
        label: 'Chimaltenango',
      },
      {
        label: 'Baja Verapaz',
      },
      {
        label: 'Alta Verapaz',
      },
    ],
  },
  {
    label: 'Guam',
    countryCode: 'GU',
    countryCodeAlpha3: 'GUM',
    phone: '+1-671',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Piti',
      },
      {
        label: 'Santa Rita',
      },
      {
        label: 'Sinajana',
      },
      {
        label: 'Talofofo',
      },
      {
        label: 'Tamuning',
      },
      {
        label: 'Umatac',
      },
      {
        label: 'Yigo',
      },
      {
        label: 'Yona',
      },
      {
        label: 'Merizo',
      },
      {
        label: 'Mangilao',
      },
      {
        label: 'Agana Heights',
      },
      {
        label: 'Chalan Pago-Ordot',
      },
      {
        label: 'Asan',
      },
      {
        label: 'Agat',
      },
      {
        label: 'Dededo',
      },
      {
        label: 'Barrigada',
      },
      {
        label: 'Hagatna',
      },
      {
        label: 'Inarajan',
      },
      {
        label: 'Mongmong-Toto-Maite',
      },
    ],
  },
  {
    label: 'Guinea-Bissau',
    countryCode: 'GW',
    countryCodeAlpha3: 'GNB',
    phone: '245',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Tombali',
      },
      {
        label: 'Quinara',
      },
      {
        label: 'Oio',
      },
      {
        label: 'Gabú',
      },
      {
        label: 'Cacheu',
      },
      {
        label: 'Bolama',
      },
      {
        label: 'Bissau',
      },
      {
        label: 'Biombo',
      },
      {
        label: 'Bafatá',
      },
    ],
  },
  {
    label: 'Guyana',
    countryCode: 'GY',
    countryCodeAlpha3: 'GUY',
    phone: '592',
    currency: 'GYD',
    stateProvinces: [
      {
        label: 'Upper Takutu-Upper Essequibo',
      },
      {
        label: 'Upper Demerara-Berbice',
      },
      {
        label: 'Potaro-Siparuni',
      },
      {
        label: 'Pomeroon-Supenaam',
      },
      {
        label: 'Mahaica-Berbice',
      },
      {
        label: 'Essequibo Islands-West Demerara',
      },
      {
        label: 'East Berbice-Corentyne',
      },
      {
        label: 'Demerara-Mahaica',
      },
      {
        label: 'Cuyuni-Mazaruni',
      },
      {
        label: 'Barima-Waini',
      },
    ],
  },
  {
    label: 'Hong Kong',
    countryCode: 'HK',
    countryCodeAlpha3: 'HKG',
    phone: '852',
    currency: 'HKD',
    stateProvinces: [
      {
        label: 'Yuen Long',
      },
      {
        label: 'Tsuen Wan',
      },
      {
        label: 'Tai Po',
      },
      {
        label: 'Sai Kung',
      },
      {
        label: 'Islands',
      },
      {
        label: 'Central and Western',
      },
      {
        label: 'Wan Chai',
      },
      {
        label: 'Eastern',
      },
      {
        label: 'Southern',
      },
      {
        label: 'Yau Tsim Mong',
      },
      {
        label: 'Sham Shui Po',
      },
      {
        label: 'Kowloon City',
      },
      {
        label: 'Wong Tai Sin',
      },
      {
        label: 'Kwun Tong',
      },
      {
        label: 'Kwai Tsing',
      },
      {
        label: 'Tuen Mun',
      },
      {
        label: 'North',
      },
      {
        label: 'Sha Tin',
      },
    ],
  },
  {
    label: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
    countryCodeAlpha3: 'HMD',
    phone: '',
    currency: 'AUD',
    stateProvinces: null,
  },
  {
    label: 'Honduras',
    countryCode: 'HN',
    countryCodeAlpha3: 'HND',
    phone: '504',
    currency: 'HNL',
    stateProvinces: [
      {
        label: 'Yoro',
      },
      {
        label: 'Valle',
      },
      {
        label: 'Santa Bárbara',
      },
      {
        label: 'Olancho',
      },
      {
        label: 'Ocotepeque',
      },
      {
        label: 'Lempira',
      },
      {
        label: 'La Paz',
      },
      {
        label: 'Bay Islands',
      },
      {
        label: 'Intibucá',
      },
      {
        label: 'Gracias a Dios',
      },
      {
        label: 'Francisco Morazán',
      },
      {
        label: 'El Paraíso',
      },
      {
        label: 'Cortés',
      },
      {
        label: 'Copán',
      },
      {
        label: 'Comayagua',
      },
      {
        label: 'Colón',
      },
      {
        label: 'Choluteca',
      },
      {
        label: 'Atlántida',
      },
    ],
  },
  {
    label: 'Croatia',
    countryCode: 'HR',
    countryCodeAlpha3: 'HRV',
    phone: '385',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Bjelovarsko-Bilogorska',
      },
      {
        label: 'Slavonski Brod-Posavina',
      },
      {
        label: 'Dubrovačko-Neretvanska',
      },
      {
        label: 'Istria',
      },
      {
        label: 'Karlovačka',
      },
      {
        label: 'Koprivničko-Križevačka',
      },
      {
        label: 'Krapinsko-Zagorska',
      },
      {
        label: 'Ličko-Senjska',
      },
      {
        label: 'Međimurska',
      },
      {
        label: 'Osječko-Baranjska',
      },
      {
        label: 'Požeško-Slavonska',
      },
      {
        label: 'Primorsko-Goranska',
      },
      {
        label: 'Šibensko-Kniniska',
      },
      {
        label: 'Sisačko-Moslavačka',
      },
      {
        label: 'Split-Dalmatia',
      },
      {
        label: 'Varaždinska',
      },
      {
        label: 'Vukovar-Sirmium',
      },
      {
        label: 'Zadarska',
      },
      {
        label: 'Zagrebačka',
      },
      {
        label: 'City of Zagreb',
      },
      {
        label: 'Virovitičk-Podravska',
      },
    ],
  },
  {
    label: 'Haiti',
    countryCode: 'HT',
    countryCodeAlpha3: 'HTI',
    phone: '509',
    currency: 'HTG',
    stateProvinces: [
      {
        label: 'Sud-Est',
      },
      {
        label: 'Sud',
      },
      {
        label: 'Ouest',
      },
      {
        label: 'Nord-Ouest',
      },
      {
        label: 'Nord-Est',
      },
      {
        label: 'Nord',
      },
      {
        label: 'GrandʼAnse',
      },
      {
        label: 'Centre',
      },
      {
        label: 'Artibonite',
      },
      {
        label: 'Nippes',
      },
    ],
  },
  {
    label: 'Hungary',
    countryCode: 'HU',
    countryCodeAlpha3: 'HUN',
    phone: '36',
    currency: 'HUF',
    stateProvinces: [
      {
        label: 'Szabolcs-Szatmár-Bereg',
      },
      {
        label: 'Jász-Nagykun-Szolnok',
      },
      {
        label: 'Heves',
      },
      {
        label: 'Hajdú-Bihar',
      },
      {
        label: 'Csongrád',
      },
      {
        label: 'Borsod-Abaúj-Zemplén',
      },
      {
        label: 'Bekes County',
      },
      {
        label: 'Zala',
      },
      {
        label: 'Veszprém',
      },
      {
        label: 'Vas',
      },
      {
        label: 'Tolna',
      },
      {
        label: 'Somogy',
      },
      {
        label: 'Pest',
      },
      {
        label: 'Nógrád',
      },
      {
        label: 'Komárom-Esztergom',
      },
      {
        label: 'Győr-Moson-Sopron',
      },
      {
        label: 'Fejér',
      },
      {
        label: 'Budapest',
      },
      {
        label: 'Baranya',
      },
      {
        label: 'Bács-Kiskun',
      },
    ],
  },
  {
    label: 'Indonesia',
    countryCode: 'ID',
    countryCodeAlpha3: 'IDN',
    phone: '62',
    currency: 'IDR',
    stateProvinces: [
      {
        label: 'North Sumatra',
      },
      {
        label: 'Aceh',
      },
      {
        label: 'Yogyakarta',
      },
      {
        label: 'South Sumatra',
      },
      {
        label: 'West Sumatra',
      },
      {
        label: 'North Sulawesi',
      },
      {
        label: 'Southeast Sulawesi',
      },
      {
        label: 'Central Sulawesi',
      },
      {
        label: 'South Sulawesi',
      },
      {
        label: 'Riau',
      },
      {
        label: 'East Nusa Tenggara',
      },
      {
        label: 'West Nusa Tenggara',
      },
      {
        label: 'Maluku',
      },
      {
        label: 'Lampung',
      },
      {
        label: 'East Kalimantan',
      },
      {
        label: 'Central Kalimantan',
      },
      {
        label: 'South Kalimantan',
      },
      {
        label: 'West Kalimantan',
      },
      {
        label: 'East Java',
      },
      {
        label: 'Central Java',
      },
      {
        label: 'West Java',
      },
      {
        label: 'Jambi',
      },
      {
        label: 'Jakarta',
      },
      {
        label: 'Papua',
      },
      {
        label: 'Bengkulu',
      },
      {
        label: 'Bali',
      },
      {
        label: 'Banten',
      },
      {
        label: 'Gorontalo',
      },
      {
        label: 'Bangka–Belitung Islands',
      },
      {
        label: 'North Maluku',
      },
      {
        label: 'West Papua',
      },
      {
        label: 'West Sulawesi',
      },
      {
        label: 'Riau Islands',
      },
      {
        label: 'North Kalimantan',
      },
      {
        label: 'Southwest Papua',
      },
      {
        label: 'Central Papua',
      },
      {
        label: 'Highland Papua',
      },
      {
        label: 'South Papua',
      },
    ],
  },
  {
    label: 'Ireland',
    countryCode: 'IE',
    countryCodeAlpha3: 'IRL',
    phone: '353',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Connacht',
      },
      {
        label: 'Leinster',
      },
      {
        label: 'Munster',
      },
      {
        label: 'Ulster',
      },
    ],
  },
  {
    label: 'Israel',
    countryCode: 'IL',
    countryCodeAlpha3: 'ISR',
    phone: '972',
    currency: 'ILS',
    stateProvinces: [
      {
        label: 'Jerusalem',
      },
      {
        label: 'Tel Aviv',
      },
      {
        label: 'Haifa',
      },
      {
        label: 'Northern District',
      },
      {
        label: 'Central District',
      },
      {
        label: 'Southern District',
      },
      {
        label: 'Judea and Samaria Area',
      },
    ],
  },
  {
    label: 'Isle of Man',
    countryCode: 'IM',
    countryCodeAlpha3: 'IMN',
    phone: '+44-1624',
    currency: 'GBP',
    stateProvinces: [
      {
        label: 'Andreas',
      },
      {
        label: 'Arbory',
      },
      {
        label: 'Ballaugh',
      },
      {
        label: 'Braddan',
      },
      {
        label: 'Bride',
      },
      {
        label: 'Castletown',
      },
      {
        label: 'Douglas',
      },
      {
        label: 'German',
      },
      {
        label: 'Jurby',
      },
      {
        label: 'Laxey',
      },
      {
        label: 'Lezayre',
      },
      {
        label: 'Lonan',
      },
      {
        label: 'Malew',
      },
      {
        label: 'Marown',
      },
      {
        label: 'Maughold',
      },
      {
        label: 'Michael',
      },
      {
        label: 'Onchan',
      },
      {
        label: 'Patrick',
      },
      {
        label: 'Peel',
      },
      {
        label: 'Port Erin',
      },
      {
        label: 'Port St Mary',
      },
      {
        label: 'Ramsey',
      },
      {
        label: 'Rushen',
      },
      {
        label: 'Santon',
      },
    ],
  },
  {
    label: 'India',
    countryCode: 'IN',
    countryCodeAlpha3: 'IND',
    phone: '91',
    currency: 'INR',
    stateProvinces: [
      {
        label: 'West Bengal',
      },
      {
        label: 'Uttar Pradesh',
      },
      {
        label: 'Tripura',
      },
      {
        label: 'Telangana',
      },
      {
        label: 'Tamil Nadu',
      },
      {
        label: 'Sikkim',
      },
      {
        label: 'Rajasthan',
      },
      {
        label: 'Punjab',
      },
      {
        label: 'Puducherry',
      },
      {
        label: 'Odisha',
      },
      {
        label: 'Nagaland',
      },
      {
        label: 'Mizoram',
      },
      {
        label: 'Meghalaya',
      },
      {
        label: 'Manipur',
      },
      {
        label: 'Maharashtra',
      },
      {
        label: 'Madhya Pradesh',
      },
      {
        label: 'Laccadives',
      },
      {
        label: 'Kerala',
      },
      {
        label: 'Karnataka',
      },
      {
        label: 'Jammu and Kashmir',
      },
      {
        label: 'Himachal Pradesh',
      },
      {
        label: 'Haryana',
      },
      {
        label: 'Gujarat',
      },
      {
        label: 'Goa',
      },
      {
        label: 'Delhi',
      },
      {
        label: 'Chandigarh',
      },
      {
        label: 'Bihar',
      },
      {
        label: 'Assam',
      },
      {
        label: 'Arunachal Pradesh',
      },
      {
        label: 'Andhra Pradesh',
      },
      {
        label: 'Andaman and Nicobar',
      },
      {
        label: 'Chhattisgarh',
      },
      {
        label: 'Jharkhand',
      },
      {
        label: 'Uttarakhand',
      },
      {
        label: 'Ladakh',
      },
      {
        label: 'Dadra and Nagar Haveli and Daman and Diu',
      },
    ],
  },
  {
    label: 'British Indian Ocean Territory',
    countryCode: 'IO',
    countryCodeAlpha3: 'IOT',
    phone: '246',
    currency: 'USD',
    stateProvinces: null,
  },
  {
    label: 'Iraq',
    countryCode: 'IQ',
    countryCodeAlpha3: 'IRQ',
    phone: '964',
    currency: 'IQD',
    stateProvinces: [
      {
        label: 'Basra',
      },
      {
        label: 'Wāsiţ',
      },
      {
        label: 'Salah ad Din',
      },
      {
        label: 'Nineveh',
      },
      {
        label: 'Maysan',
      },
      {
        label: 'Karbalāʼ',
      },
      {
        label: 'Arbīl',
      },
      {
        label: 'Diyālá',
      },
      {
        label: 'Dhi Qar',
      },
      {
        label: 'Duhok',
      },
      {
        label: 'Baghdad',
      },
      {
        label: 'Bābil',
      },
      {
        label: 'Kirkuk',
      },
      {
        label: 'Sulaymaniyah',
      },
      {
        label: 'An Najaf',
      },
      {
        label: 'Al Qādisīyah',
      },
      {
        label: 'Al Muthanná',
      },
      {
        label: 'Al Anbar',
      },
      {
        label: 'Halabja Governorate',
      },
    ],
  },
  {
    label: 'Iran',
    countryCode: 'IR',
    countryCodeAlpha3: 'IRN',
    phone: '98',
    currency: 'IRR',
    stateProvinces: [
      {
        label: 'Tehran',
      },
      {
        label: 'Zanjan',
      },
      {
        label: 'Yazd',
      },
      {
        label: 'Semnan',
      },
      {
        label: 'Māzandarān',
      },
      {
        label: 'Markazi',
      },
      {
        label: 'Lorestan Province',
      },
      {
        label: 'Kordestān',
      },
      {
        label: 'Kohgiluyeh and Boyer-Ahmad',
      },
      {
        label: 'Khuzestan',
      },
      {
        label: 'Kermānshāh',
      },
      {
        label: 'Kerman',
      },
      {
        label: 'Ilam Province',
      },
      {
        label: 'Hormozgan',
      },
      {
        label: 'Hamadān',
      },
      {
        label: 'Gīlān',
      },
      {
        label: 'Fars',
      },
      {
        label: 'Chaharmahal and Bakhtiari',
      },
      {
        label: 'Bushehr',
      },
      {
        label: 'East Azerbaijan',
      },
      {
        label: 'West Azerbaijan',
      },
      {
        label: 'Ardabīl',
      },
      {
        label: 'Isfahan',
      },
      {
        label: 'Golestan',
      },
      {
        label: 'Qazvīn',
      },
      {
        label: 'Qom',
      },
      {
        label: 'Sistan and Baluchestan',
      },
      {
        label: 'South Khorasan Province',
      },
      {
        label: 'Razavi Khorasan',
      },
      {
        label: 'North Khorasan',
      },
      {
        label: 'Alborz Province',
      },
    ],
  },
  {
    label: 'Iceland',
    countryCode: 'IS',
    countryCodeAlpha3: 'ISL',
    phone: '354',
    currency: 'ISK',
    stateProvinces: [
      {
        label: 'Northwest',
      },
      {
        label: 'Northeast',
      },
      {
        label: 'East',
      },
      {
        label: 'South',
      },
      {
        label: 'Capital Region',
      },
      {
        label: 'Southern Peninsula',
      },
      {
        label: 'West',
      },
      {
        label: 'Westfjords',
      },
    ],
  },
  {
    label: 'Italy',
    countryCode: 'IT',
    countryCodeAlpha3: 'ITA',
    phone: '39',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Sicily',
      },
      {
        label: 'Sardinia',
      },
      {
        label: 'Calabria',
      },
      {
        label: 'Veneto',
      },
      {
        label: 'Aosta Valley',
      },
      {
        label: 'Umbria',
      },
      {
        label: 'Trentino-Alto Adige',
      },
      {
        label: 'Tuscany',
      },
      {
        label: 'Apulia',
      },
      {
        label: 'Piedmont',
      },
      {
        label: 'Molise',
      },
      {
        label: 'The Marches',
      },
      {
        label: 'Lombardy',
      },
      {
        label: 'Liguria',
      },
      {
        label: 'Lazio',
      },
      {
        label: 'Friuli Venezia Giulia',
      },
      {
        label: 'Emilia-Romagna',
      },
      {
        label: 'Campania',
      },
      {
        label: 'Basilicate',
      },
      {
        label: 'Abruzzo',
      },
    ],
  },
  {
    label: 'Jersey',
    countryCode: 'JE',
    countryCodeAlpha3: 'JEY',
    phone: '+44-1534',
    currency: 'GBP',
    stateProvinces: [
      {
        label: 'St Clement',
      },
      {
        label: 'St Saviour',
      },
      {
        label: 'St. Brelade',
      },
      {
        label: 'Grouville',
      },
      {
        label: 'St Mary',
      },
      {
        label: 'St Lawrence',
      },
      {
        label: 'St Peter',
      },
      {
        label: 'St Ouen',
      },
      {
        label: 'St John',
      },
      {
        label: 'Trinity',
      },
      {
        label: 'St Martîn',
      },
      {
        label: 'St Helier',
      },
    ],
  },
  {
    label: 'Jamaica',
    countryCode: 'JM',
    countryCodeAlpha3: 'JAM',
    phone: '+1-876',
    currency: 'JMD',
    stateProvinces: [
      {
        label: 'Westmoreland',
      },
      {
        label: 'Trelawny',
      },
      {
        label: 'St. Thomas',
      },
      {
        label: 'St. Mary',
      },
      {
        label: 'St. James',
      },
      {
        label: 'St. Elizabeth',
      },
      {
        label: 'Saint Catherine',
      },
      {
        label: 'St Ann',
      },
      {
        label: 'St. Andrew',
      },
      {
        label: 'Portland',
      },
      {
        label: 'Manchester',
      },
      {
        label: 'Kingston',
      },
      {
        label: 'Hanover',
      },
      {
        label: 'Clarendon',
      },
    ],
  },
  {
    label: 'Jordan',
    countryCode: 'JO',
    countryCodeAlpha3: 'JOR',
    phone: '962',
    currency: 'JOD',
    stateProvinces: [
      {
        label: 'Ma’an',
      },
      {
        label: 'Irbid',
      },
      {
        label: 'Zarqa',
      },
      {
        label: 'Tafielah',
      },
      {
        label: 'Amman',
      },
      {
        label: 'Mafraq',
      },
      {
        label: 'Karak',
      },
      {
        label: 'Balqa',
      },
      {
        label: 'Ajlun',
      },
      {
        label: 'Jerash',
      },
      {
        label: 'Aqaba',
      },
      {
        label: 'Madaba',
      },
    ],
  },
  {
    label: 'Japan',
    countryCode: 'JP',
    countryCodeAlpha3: 'JPN',
    phone: '81',
    currency: 'JPY',
    stateProvinces: [
      {
        label: 'Yamanashi',
      },
      {
        label: 'Yamaguchi',
      },
      {
        label: 'Wakayama',
      },
      {
        label: 'Toyama',
      },
      {
        label: 'Tottori',
      },
      {
        label: 'Tokyo',
      },
      {
        label: 'Tokushima',
      },
      {
        label: 'Tochigi',
      },
      {
        label: 'Shizuoka',
      },
      {
        label: 'Shimane',
      },
      {
        label: 'Shiga',
      },
      {
        label: 'Saitama',
      },
      {
        label: 'Saga',
      },
      {
        label: 'Ōsaka',
      },
      {
        label: 'Okinawa',
      },
      {
        label: 'Okayama',
      },
      {
        label: 'Oita',
      },
      {
        label: 'Niigata',
      },
      {
        label: 'Nara',
      },
      {
        label: 'Nagasaki',
      },
      {
        label: 'Nagano',
      },
      {
        label: 'Miyazaki',
      },
      {
        label: 'Mie',
      },
      {
        label: 'Kyoto',
      },
      {
        label: 'Kumamoto',
      },
      {
        label: 'Kochi',
      },
      {
        label: 'Kanagawa',
      },
      {
        label: 'Kagoshima',
      },
      {
        label: 'Kagawa',
      },
      {
        label: 'Ishikawa',
      },
      {
        label: 'Hyōgo',
      },
      {
        label: 'Hiroshima',
      },
      {
        label: 'Gunma',
      },
      {
        label: 'Gifu',
      },
      {
        label: 'Fukuoka',
      },
      {
        label: 'Fukui',
      },
      {
        label: 'Ehime',
      },
      {
        label: 'Aichi',
      },
      {
        label: 'Yamagata',
      },
      {
        label: 'Miyagi',
      },
      {
        label: 'Iwate',
      },
      {
        label: 'Ibaraki',
      },
      {
        label: 'Fukushima',
      },
      {
        label: 'Chiba',
      },
      {
        label: 'Akita',
      },
      {
        label: 'Hokkaido',
      },
      {
        label: 'Aomori',
      },
    ],
  },
  {
    label: 'Kenya',
    countryCode: 'KE',
    countryCodeAlpha3: 'KEN',
    phone: '254',
    currency: 'KES',
    stateProvinces: [
      {
        label: 'West Pokot',
      },
      {
        label: 'Wajir',
      },
      {
        label: 'Uasin Gishu',
      },
      {
        label: 'Turkana',
      },
      {
        label: 'Trans Nzoia',
      },
      {
        label: 'Tharaka - Nithi',
      },
      {
        label: 'Tana River',
      },
      {
        label: 'Siaya',
      },
      {
        label: 'Samburu',
      },
      {
        label: 'Nairobi Area',
      },
      {
        label: "Murang'A",
      },
      {
        label: 'Mombasa',
      },
      {
        label: 'Meru',
      },
      {
        label: 'Marsabit',
      },
      {
        label: 'Mandera',
      },
      {
        label: 'Laikipia',
      },
      {
        label: 'Kwale',
      },
      {
        label: 'Kitui',
      },
      {
        label: 'Kisumu',
      },
      {
        label: 'Kisii',
      },
      {
        label: 'Kirinyaga',
      },
      {
        label: 'Kilifi',
      },
      {
        label: 'Kiambu',
      },
      {
        label: 'Kericho',
      },
      {
        label: 'Kakamega',
      },
      {
        label: 'Isiolo',
      },
      {
        label: 'Garissa',
      },
      {
        label: 'Embu',
      },
      {
        label: 'Busia',
      },
      {
        label: 'Bungoma',
      },
      {
        label: 'Baringo',
      },
      {
        label: 'Nyandarua',
      },
      {
        label: 'Vihiga',
      },
      {
        label: 'Lamu',
      },
      {
        label: 'Machakos',
      },
      {
        label: 'Makueni',
      },
      {
        label: 'Elegeyo-Marakwet',
      },
      {
        label: 'Taita Taveta',
      },
      {
        label: 'Kajiado',
      },
      {
        label: 'Nyeri',
      },
      {
        label: 'Homa Bay',
      },
      {
        label: 'Bomet',
      },
      {
        label: 'Migori',
      },
      {
        label: 'Nakuru',
      },
      {
        label: 'Narok',
      },
      {
        label: 'Nyamira',
      },
      {
        label: 'Nandi',
      },
    ],
  },
  {
    label: 'Kyrgyzstan',
    countryCode: 'KG',
    countryCodeAlpha3: 'KGZ',
    phone: '996',
    currency: 'KGS',
    stateProvinces: [
      {
        label: 'Osh',
      },
      {
        label: 'Batken',
      },
      {
        label: 'Talas',
      },
      {
        label: 'Naryn',
      },
      {
        label: 'Issyk-Kul',
      },
      {
        label: 'Bishkek',
      },
      {
        label: 'Jalal-Abad',
      },
      {
        label: 'Chüy',
      },
      {
        label: 'Osh City',
      },
    ],
  },
  {
    label: 'Cambodia',
    countryCode: 'KH',
    countryCodeAlpha3: 'KHM',
    phone: '855',
    currency: 'KHR',
    stateProvinces: [
      {
        label: 'Pursat',
      },
      {
        label: 'Battambang',
      },
      {
        label: 'Takeo',
      },
      {
        label: 'Svay Rieng',
      },
      {
        label: 'Stung Treng',
      },
      {
        label: 'Ŏtâr Méanchey',
      },
      {
        label: 'Siem Reap',
      },
      {
        label: 'Ratanakiri',
      },
      {
        label: 'Prey Veng',
      },
      {
        label: 'Preah Vihear',
      },
      {
        label: 'Phnom Penh',
      },
      {
        label: 'Pailin',
      },
      {
        label: 'Mondolkiri',
      },
      {
        label: 'Kratie',
      },
      {
        label: 'Kep',
      },
      {
        label: 'Koh Kong',
      },
      {
        label: 'Kandal',
      },
      {
        label: 'Kampot',
      },
      {
        label: 'Kampong Thom',
      },
      {
        label: 'Kampong Speu',
      },
      {
        label: 'Kampong Chhnang',
      },
      {
        label: 'Kampong Cham',
      },
      {
        label: 'Preah Sihanouk',
      },
      {
        label: 'Banteay Meanchey',
      },
      {
        label: 'Tboung Khmum',
      },
    ],
  },
  {
    label: 'Kiribati',
    countryCode: 'KI',
    countryCodeAlpha3: 'KIR',
    phone: '686',
    currency: 'AUD',
    stateProvinces: [
      {
        label: 'Gilbert Islands',
      },
      {
        label: 'Line Islands',
      },
      {
        label: 'Phoenix Islands',
      },
    ],
  },
  {
    label: 'Comoros',
    countryCode: 'KM',
    countryCodeAlpha3: 'COM',
    phone: '269',
    currency: 'KMF',
    stateProvinces: [
      {
        label: 'Mohéli',
      },
      {
        label: 'Grande Comore',
      },
      {
        label: 'Anjouan',
      },
    ],
  },
  {
    label: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    countryCodeAlpha3: 'KNA',
    phone: '+1-869',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Trinity Palmetto Point',
      },
      {
        label: 'Middle Island',
      },
      {
        label: 'Saint Thomas Lowland',
      },
      {
        label: 'Saint Peter Basseterre',
      },
      {
        label: 'Saint Paul Charlestown',
      },
      {
        label: 'Saint Paul Capesterre',
      },
      {
        label: 'Saint Mary Cayon',
      },
      {
        label: 'Saint John Figtree',
      },
      {
        label: 'Saint John Capesterre',
      },
      {
        label: 'Saint James Windwa',
      },
      {
        label: 'Saint George Gingerland',
      },
      {
        label: 'Saint George Basseterre',
      },
      {
        label: 'Saint Anne Sandy Point',
      },
      {
        label: 'Christ Church Nichola Town',
      },
    ],
  },
  {
    label: 'North Korea',
    countryCode: 'KP',
    countryCodeAlpha3: 'PRK',
    phone: '850',
    currency: 'KPW',
    stateProvinces: [
      {
        label: 'Pyongyang',
      },
      {
        label: 'South Pyongan',
      },
      {
        label: "P'yŏngan-bukto",
      },
      {
        label: 'Kangwŏn-do',
      },
      {
        label: 'Hwanghae-namdo',
      },
      {
        label: 'Hwanghae-bukto',
      },
      {
        label: 'Hamgyŏng-namdo',
      },
      {
        label: 'Yanggang-do',
      },
      {
        label: 'Hambuk',
      },
      {
        label: 'Chagang-do',
      },
      {
        label: 'Rason',
      },
    ],
  },
  {
    label: 'South Korea',
    countryCode: 'KR',
    countryCodeAlpha3: 'KOR',
    phone: '82',
    currency: 'KRW',
    stateProvinces: [
      {
        label: 'Ulsan',
      },
      {
        label: 'Daejeon',
      },
      {
        label: 'Daegu',
      },
      {
        label: 'Seoul',
      },
      {
        label: 'Busan',
      },
      {
        label: 'Gyeongsangbuk-do',
      },
      {
        label: 'Gyeonggi-do',
      },
      {
        label: 'Gwangju',
      },
      {
        label: 'Gangwon-do',
      },
      {
        label: 'Incheon',
      },
      {
        label: 'Chungcheongnam-do',
      },
      {
        label: 'North Chungcheong',
      },
      {
        label: 'Jeollanam-do',
      },
      {
        label: 'Jeollabuk-do',
      },
      {
        label: 'Jeju-do',
      },
      {
        label: 'Gyeongsangnam-do',
      },
      {
        label: 'Sejong-si',
      },
    ],
  },
  {
    label: 'Kosovo',
    countryCode: 'XK',
    countryCodeAlpha3: 'XKX',
    phone: '',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Ferizaj',
      },
      {
        label: 'Gjakova',
      },
      {
        label: 'Gjilan',
      },
      {
        label: 'Mitrovica',
      },
      {
        label: 'Pec',
      },
      {
        label: 'Pristina',
      },
      {
        label: 'Prizren',
      },
    ],
  },
  {
    label: 'Kuwait',
    countryCode: 'KW',
    countryCodeAlpha3: 'KWT',
    phone: '965',
    currency: 'KWD',
    stateProvinces: [
      {
        label: 'Hawalli',
      },
      {
        label: 'Al Asimah',
      },
      {
        label: 'Al Jahrāʼ',
      },
      {
        label: 'Al Farwaniyah',
      },
      {
        label: 'Al Aḩmadī',
      },
      {
        label: 'Mubārak al Kabīr',
      },
    ],
  },
  {
    label: 'Cayman Islands',
    countryCode: 'KY',
    countryCodeAlpha3: 'CYM',
    phone: '+1-345',
    currency: 'KYD',
    stateProvinces: [
      {
        label: 'George Town',
      },
      {
        label: 'West Bay',
      },
      {
        label: 'Bodden Town',
      },
      {
        label: 'North Side',
      },
      {
        label: 'East End',
      },
      {
        label: 'Sister Island',
      },
    ],
  },
  {
    label: 'Kazakhstan',
    countryCode: 'KZ',
    countryCodeAlpha3: 'KAZ',
    phone: '7',
    currency: 'KZT',
    stateProvinces: [
      {
        label: 'Batys Qazaqstan',
      },
      {
        label: 'Mangghystaū',
      },
      {
        label: 'Atyraū',
      },
      {
        label: 'Aqtöbe',
      },
      {
        label: 'East Kazakhstan',
      },
      {
        label: 'Aqmola',
      },
      {
        label: 'North Kazakhstan',
      },
      {
        label: 'Pavlodar Region',
      },
      {
        label: 'Qyzylorda',
      },
      {
        label: 'Qostanay',
      },
      {
        label: 'Karaganda',
      },
      {
        label: 'Zhambyl',
      },
      {
        label: 'South Kazakhstan',
      },
      {
        label: 'Almaty',
      },
      {
        label: 'Almaty Oblysy',
      },
      {
        label: 'Shymkent',
      },
      {
        label: 'Baikonur',
      },
      {
        label: 'Astana',
      },
      {
        label: 'Abai Region',
      },
      {
        label: 'KZ.12510144',
      },
      {
        label: 'KZ.12510145',
      },
    ],
  },
  {
    label: 'Laos',
    countryCode: 'LA',
    countryCodeAlpha3: 'LAO',
    phone: '856',
    currency: 'LAK',
    stateProvinces: [
      {
        label: 'Xiangkhoang',
      },
      {
        label: 'Xiagnabouli',
      },
      {
        label: 'Vientiane',
      },
      {
        label: 'Savannahkhét',
      },
      {
        label: 'Salavan',
      },
      {
        label: 'Phôngsali',
      },
      {
        label: 'Oudômxai',
      },
      {
        label: 'Louangphabang',
      },
      {
        label: 'Loungnamtha',
      },
      {
        label: 'Khammouan',
      },
      {
        label: 'Houaphan',
      },
      {
        label: 'Champasak',
      },
      {
        label: 'Attapu',
      },
      {
        label: 'Xékong',
      },
      {
        label: 'Bokeo',
      },
      {
        label: 'Bolikhamsai',
      },
      {
        label: 'Vientiane Prefecture',
      },
      {
        label: 'Xaisomboun',
      },
    ],
  },
  {
    label: 'Lebanon',
    countryCode: 'LB',
    countryCodeAlpha3: 'LBN',
    phone: '961',
    currency: 'LBP',
    stateProvinces: [
      {
        label: 'Mont-Liban',
      },
      {
        label: 'Beyrouth',
      },
      {
        label: 'Liban-Nord',
      },
      {
        label: 'South Governorate',
      },
      {
        label: 'Béqaa',
      },
      {
        label: 'Nabatîyé',
      },
      {
        label: 'Aakkâr',
      },
      {
        label: 'Baalbek-Hermel',
      },
    ],
  },
  {
    label: 'Saint Lucia',
    countryCode: 'LC',
    countryCodeAlpha3: 'LCA',
    phone: '+1-758',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Vieux-Fort',
      },
      {
        label: 'Soufrière',
      },
      {
        label: 'Micoud',
      },
      {
        label: 'Laborie',
      },
      {
        label: 'Gros-Islet',
      },
      {
        label: 'Dennery',
      },
      {
        label: 'Choiseul',
      },
      {
        label: 'Castries',
      },
      {
        label: 'Anse-la-Raye',
      },
      {
        label: 'Canaries',
      },
    ],
  },
  {
    label: 'Liechtenstein',
    countryCode: 'LI',
    countryCodeAlpha3: 'LIE',
    phone: '423',
    currency: 'CHF',
    stateProvinces: [
      {
        label: 'Vaduz',
      },
      {
        label: 'Triesenberg',
      },
      {
        label: 'Triesen',
      },
      {
        label: 'Schellenberg',
      },
      {
        label: 'Schaan',
      },
      {
        label: 'Ruggell',
      },
      {
        label: 'Planken',
      },
      {
        label: 'Mauren',
      },
      {
        label: 'Gamprin',
      },
      {
        label: 'Eschen',
      },
      {
        label: 'Balzers',
      },
    ],
  },
  {
    label: 'Sri Lanka',
    countryCode: 'LK',
    countryCodeAlpha3: 'LKA',
    phone: '94',
    currency: 'LKR',
    stateProvinces: [
      {
        label: 'Western',
      },
      {
        label: 'Uva',
      },
      {
        label: 'Southern',
      },
      {
        label: 'Sabaragamuwa',
      },
      {
        label: 'North Western',
      },
      {
        label: 'North Central',
      },
      {
        label: 'Central',
      },
      {
        label: 'Northern Province',
      },
      {
        label: 'Eastern Province',
      },
    ],
  },
  {
    label: 'Liberia',
    countryCode: 'LR',
    countryCodeAlpha3: 'LBR',
    phone: '231',
    currency: 'LRD',
    stateProvinces: [
      {
        label: 'Sinoe',
      },
      {
        label: 'Nimba',
      },
      {
        label: 'Montserrado',
      },
      {
        label: 'Maryland',
      },
      {
        label: 'Lofa',
      },
      {
        label: 'Grand Gedeh',
      },
      {
        label: 'Grand Cape Mount',
      },
      {
        label: 'Grand Bassa',
      },
      {
        label: 'Bong',
      },
      {
        label: 'Bomi',
      },
      {
        label: 'Grand Kru',
      },
      {
        label: 'Margibi',
      },
      {
        label: 'River Cess',
      },
      {
        label: 'Gbarpolu',
      },
      {
        label: 'River Gee',
      },
    ],
  },
  {
    label: 'Lesotho',
    countryCode: 'LS',
    countryCodeAlpha3: 'LSO',
    phone: '266',
    currency: 'LSL',
    stateProvinces: [
      {
        label: 'Thaba-Tseka',
      },
      {
        label: 'Quthing',
      },
      {
        label: 'Qachaʼs Nek',
      },
      {
        label: 'Mokhotlong',
      },
      {
        label: 'Mohaleʼs Hoek',
      },
      {
        label: 'Maseru',
      },
      {
        label: 'Mafeteng',
      },
      {
        label: 'Leribe',
      },
      {
        label: 'Butha-Buthe',
      },
      {
        label: 'Berea',
      },
    ],
  },
  {
    label: 'Lithuania',
    countryCode: 'LT',
    countryCodeAlpha3: 'LTU',
    phone: '370',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Alytus',
      },
      {
        label: 'Kaunas',
      },
      {
        label: 'Klaipėda County',
      },
      {
        label: 'Marijampolė County',
      },
      {
        label: 'Panevėžys',
      },
      {
        label: 'Siauliai',
      },
      {
        label: 'Tauragė County',
      },
      {
        label: 'Telsiai',
      },
      {
        label: 'Utena',
      },
      {
        label: 'Vilnius',
      },
    ],
  },
  {
    label: 'Luxembourg',
    countryCode: 'LU',
    countryCodeAlpha3: 'LUX',
    phone: '352',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Wiltz',
      },
      {
        label: 'Vianden',
      },
      {
        label: 'Remich',
      },
      {
        label: 'Redange',
      },
      {
        label: 'Mersch',
      },
      {
        label: 'Luxembourg',
      },
      {
        label: 'Grevenmacher',
      },
      {
        label: 'Esch-sur-Alzette',
      },
      {
        label: 'Echternach',
      },
      {
        label: 'Diekirch',
      },
      {
        label: 'Clervaux',
      },
      {
        label: 'Capellen',
      },
    ],
  },
  {
    label: 'Latvia',
    countryCode: 'LV',
    countryCodeAlpha3: 'LVA',
    phone: '371',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Ventspils Rajons',
      },
      {
        label: 'Ventspils',
      },
      {
        label: 'Valmiera',
      },
      {
        label: 'Valka',
      },
      {
        label: 'Tukuma novads',
      },
      {
        label: 'Talsi Municipality',
      },
      {
        label: 'Saldus Rajons',
      },
      {
        label: 'Riga',
      },
      {
        label: 'Rēzeknes Novads',
      },
      {
        label: 'Rēzekne',
      },
      {
        label: 'Preiļu novads',
      },
      {
        label: 'Ogre',
      },
      {
        label: 'Madona Municipality',
      },
      {
        label: 'Ludzas novads',
      },
      {
        label: 'Limbažu novads',
      },
      {
        label: 'Liepāja',
      },
      {
        label: 'Kuldīgas novads',
      },
      {
        label: 'Krāslavas novads',
      },
      {
        label: 'Jūrmala',
      },
      {
        label: 'Jelgavas novads',
      },
      {
        label: 'Jelgava',
      },
      {
        label: 'Jēkabpils Municipality',
      },
      {
        label: 'Gulbenes novads',
      },
      {
        label: 'Dobeles novads',
      },
      {
        label: 'Daugavpils',
      },
      {
        label: 'Cēsu Rajons',
      },
      {
        label: 'Bauskas Rajons',
      },
      {
        label: 'Balvu Novads',
      },
      {
        label: 'Alūksnes Novads',
      },
      {
        label: 'Aizkraukles novads',
      },
      {
        label: 'Mārupe',
      },
      {
        label: 'Olaine',
      },
      {
        label: 'Ķekava',
      },
      {
        label: 'Salaspils',
      },
      {
        label: 'Ādaži',
      },
      {
        label: 'Līvāni',
      },
      {
        label: 'Varakļāni',
      },
      {
        label: 'Ropažu',
      },
      {
        label: 'Sigulda',
      },
      {
        label: 'Saulkrastu',
      },
      {
        label: 'Smiltene',
      },
      {
        label: 'South Kurzeme Municipality',
      },
      {
        label: 'Augšdaugava Municipality',
      },
    ],
  },
  {
    label: 'Libya',
    countryCode: 'LY',
    countryCodeAlpha3: 'LBY',
    phone: '218',
    currency: 'LYD',
    stateProvinces: [
      {
        label: 'Darnah',
      },
      {
        label: 'Banghāzī',
      },
      {
        label: 'Al Marj',
      },
      {
        label: 'Al Kufrah',
      },
      {
        label: 'Al Jabal al Akhḑar',
      },
      {
        label: 'Tripoli',
      },
      {
        label: 'Surt',
      },
      {
        label: 'Sabhā',
      },
      {
        label: 'Nālūt',
      },
      {
        label: 'Murzuq',
      },
      {
        label: 'Mişrātah',
      },
      {
        label: 'Ghāt',
      },
      {
        label: 'Az Zāwiyah',
      },
      {
        label: 'Ash Shāţiʼ',
      },
      {
        label: 'Al Jufrah',
      },
      {
        label: 'An Nuqāţ al Khams',
      },
      {
        label: 'Al Buţnān',
      },
      {
        label: 'Jabal al Gharbi',
      },
      {
        label: 'Al Jafārah',
      },
      {
        label: 'Al Marqab',
      },
      {
        label: 'Al Wāḩāt',
      },
      {
        label: 'Wādī al Ḩayāt',
      },
    ],
  },
  {
    label: 'Morocco',
    countryCode: 'MA',
    countryCodeAlpha3: 'MAR',
    phone: '212',
    currency: 'MAD',
    stateProvinces: [
      {
        label: 'Tanger-Tetouan-Al Hoceima',
      },
      {
        label: 'Oriental',
      },
      {
        label: 'Fès-Meknès',
      },
      {
        label: 'Rabat-Salé-Kénitra',
      },
      {
        label: 'Béni Mellal-Khénifra',
      },
      {
        label: 'Casablanca-Settat',
      },
      {
        label: 'Marrakesh-Safi',
      },
      {
        label: 'Drâa-Tafilalet',
      },
      {
        label: 'Souss-Massa',
      },
      {
        label: 'Guelmim-Oued Noun',
      },
      {
        label: 'Laâyoune-Sakia El Hamra',
      },
      {
        label: 'Dakhla-Oued Ed-Dahab',
      },
    ],
  },
  {
    label: 'Monaco',
    countryCode: 'MC',
    countryCodeAlpha3: 'MCO',
    phone: '377',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Municipality of Monaco',
      },
    ],
  },
  {
    label: 'Moldova',
    countryCode: 'MD',
    countryCodeAlpha3: 'MDA',
    phone: '373',
    currency: 'MDL',
    stateProvinces: [
      {
        label: 'Raionul Edineţ',
      },
      {
        label: 'Ungheni',
      },
      {
        label: 'Teleneşti',
      },
      {
        label: 'Taraclia',
      },
      {
        label: 'Ştefan-Vodă',
      },
      {
        label: 'Strășeni',
      },
      {
        label: 'Raionul Soroca',
      },
      {
        label: 'Rîşcani',
      },
      {
        label: 'Rezina',
      },
      {
        label: 'Orhei',
      },
      {
        label: 'Raionul Ocniţa',
      },
      {
        label: 'Anenii Noi',
      },
      {
        label: 'Nisporeni',
      },
      {
        label: 'Leova',
      },
      {
        label: 'Sîngerei',
      },
      {
        label: 'Criuleni',
      },
      {
        label: 'Ialoveni',
      },
      {
        label: 'Chișinău Municipality',
      },
      {
        label: 'Căuşeni',
      },
      {
        label: 'Cantemir',
      },
      {
        label: 'Călăraşi',
      },
      {
        label: 'Cahul',
      },
      {
        label: 'Glodeni',
      },
      {
        label: 'Floreşti',
      },
      {
        label: 'Fălești',
      },
      {
        label: 'Dubăsari',
      },
      {
        label: 'Drochia',
      },
      {
        label: 'Donduşeni',
      },
      {
        label: 'Cimişlia',
      },
      {
        label: 'Briceni',
      },
      {
        label: 'Basarabeasca',
      },
      {
        label: 'Hînceşti',
      },
      {
        label: 'Şoldăneşti',
      },
      {
        label: 'Transnistria',
      },
      {
        label: 'Găgăuzia',
      },
      {
        label: 'Bender Municipality',
      },
      {
        label: 'Bălţi',
      },
    ],
  },
  {
    label: 'Montenegro',
    countryCode: 'ME',
    countryCodeAlpha3: 'MNE',
    phone: '382',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Rožaje Municipality',
      },
      {
        label: 'Opština Žabljak',
      },
      {
        label: 'Ulcinj',
      },
      {
        label: 'Tivat',
      },
      {
        label: 'Podgorica',
      },
      {
        label: 'Opština Šavnik',
      },
      {
        label: 'Opština Plužine',
      },
      {
        label: 'Pljevlja',
      },
      {
        label: 'Opština Plav',
      },
      {
        label: 'Opština Nikšić',
      },
      {
        label: 'Mojkovac',
      },
      {
        label: 'Kotor',
      },
      {
        label: 'Opština Kolašin',
      },
      {
        label: 'Berane',
      },
      {
        label: 'Herceg Novi',
      },
      {
        label: 'Danilovgrad',
      },
      {
        label: 'Cetinje',
      },
      {
        label: 'Budva',
      },
      {
        label: 'Bijelo Polje',
      },
      {
        label: 'Bar',
      },
      {
        label: 'Andrijevica',
      },
      {
        label: 'Gusinje',
      },
      {
        label: 'Petnjica',
      },
      {
        label: 'Tuzi',
      },
    ],
  },
  {
    label: 'Saint Martin',
    countryCode: 'MF',
    countryCodeAlpha3: 'MAF',
    phone: '590',
    currency: 'EUR',
    stateProvinces: null,
  },
  {
    label: 'Madagascar',
    countryCode: 'MG',
    countryCodeAlpha3: 'MDG',
    phone: '261',
    currency: 'MGA',
    stateProvinces: [
      {
        label: 'Diana',
      },
      {
        label: 'Sava',
      },
      {
        label: 'Sofia',
      },
      {
        label: 'Analanjirofo',
      },
      {
        label: 'Boeny',
      },
      {
        label: 'Betsiboka',
      },
      {
        label: 'Alaotra Mangoro',
      },
      {
        label: 'Melaky',
      },
      {
        label: 'Bongolava',
      },
      {
        label: 'Vakinankaratra',
      },
      {
        label: 'Itasy',
      },
      {
        label: 'Analamanga',
      },
      {
        label: 'Atsinanana',
      },
      {
        label: 'Menabe',
      },
      {
        label: "Amoron'i Mania",
      },
      {
        label: 'Upper Matsiatra',
      },
      {
        label: 'Ihorombe',
      },
      {
        label: 'Atsimo-Atsinanana',
      },
      {
        label: 'Anosy',
      },
      {
        label: 'Androy',
      },
      {
        label: 'Atsimo-Andrefana',
      },
      {
        label: 'Fitovinany Region',
      },
      {
        label: 'Vatovavy Region',
      },
    ],
  },
  {
    label: 'Marshall Islands',
    countryCode: 'MH',
    countryCodeAlpha3: 'MHL',
    phone: '692',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Ailinginae Atoll',
      },
      {
        label: 'Ailinglaplap Atoll',
      },
      {
        label: 'Ailuk Atoll',
      },
      {
        label: 'Arno Atoll',
      },
      {
        label: 'Aur Atoll',
      },
      {
        label: 'Bikar Atoll',
      },
      {
        label: 'Bikini Atoll',
      },
      {
        label: 'Ebon Atoll',
      },
      {
        label: 'Enewetak Atoll',
      },
      {
        label: 'Erikub Atoll',
      },
      {
        label: 'Jaluit Atoll',
      },
      {
        label: 'Kwajalein Atoll',
      },
      {
        label: 'Lae Atoll',
      },
      {
        label: 'Likiep Atoll',
      },
      {
        label: 'Majuro Atoll',
      },
      {
        label: 'Maloelap Atoll',
      },
      {
        label: 'Mili Atoll',
      },
      {
        label: 'Namdrik Atoll',
      },
      {
        label: 'Namu Atoll',
      },
      {
        label: 'Rongelap Atoll',
      },
      {
        label: 'Rongrik Atoll',
      },
      {
        label: 'Taka Atoll',
      },
      {
        label: 'Bokak Atoll',
      },
      {
        label: 'Ujae Atoll',
      },
      {
        label: 'Ujelang',
      },
      {
        label: 'Utrik Atoll',
      },
      {
        label: 'Wotho Atoll',
      },
      {
        label: 'Wotje Atoll',
      },
      {
        label: 'Jabat Island',
      },
      {
        label: 'Jemo Island',
      },
      {
        label: 'Kili Island',
      },
      {
        label: 'Lib Island',
      },
      {
        label: 'Mejit Island',
      },
    ],
  },
  {
    label: 'North Macedonia',
    countryCode: 'MK',
    countryCodeAlpha3: 'MKD',
    phone: '389',
    currency: 'MKD',
    stateProvinces: [
      {
        label: 'Valandovo',
      },
      {
        label: 'Resen',
      },
      {
        label: 'Kratovo',
      },
      {
        label: 'Pehchevo',
      },
      {
        label: 'Novo Selo',
      },
      {
        label: 'Bosilovo',
      },
      {
        label: 'Vasilevo',
      },
      {
        label: 'Dojran',
      },
      {
        label: 'Bogdanci',
      },
      {
        label: 'Konche',
      },
      {
        label: 'Makedonska Kamenica',
      },
      {
        label: 'Zrnovci',
      },
      {
        label: 'Karbinci',
      },
      {
        label: 'Demir Kapija',
      },
      {
        label: 'Rosoman',
      },
      {
        label: 'Gradsko',
      },
      {
        label: 'Lozovo',
      },
      {
        label: 'Češinovo-Obleševo',
      },
      {
        label: 'Novaci',
      },
      {
        label: 'Berovo',
      },
      {
        label: 'Bitola',
      },
      {
        label: 'Mogila',
      },
      {
        label: 'Arachinovo',
      },
      {
        label: 'Bogovinje',
      },
      {
        label: 'Brvenica',
      },
      {
        label: 'Chashka',
      },
      {
        label: 'Centar Zhupa',
      },
      {
        label: 'Chucher Sandevo',
      },
      {
        label: 'Debar',
      },
      {
        label: 'Delchevo',
      },
      {
        label: 'Demir Hisar',
      },
      {
        label: 'Dolneni',
      },
      {
        label: 'Gevgelija',
      },
      {
        label: 'Gostivar',
      },
      {
        label: 'Ilinden',
      },
      {
        label: 'Jegunovce',
      },
      {
        label: 'Kavadarci',
      },
      {
        label: 'Kichevo',
      },
      {
        label: 'Kochani',
      },
      {
        label: 'Kriva Palanka',
      },
      {
        label: 'Krivogashtani',
      },
      {
        label: 'Krushevo',
      },
      {
        label: 'Kumanovo',
      },
      {
        label: 'Lipkovo',
      },
      {
        label: 'Makedonski Brod',
      },
      {
        label: 'Negotino',
      },
      {
        label: 'Ohrid',
      },
      {
        label: 'Petrovec',
      },
      {
        label: 'Plasnica',
      },
      {
        label: 'Prilep',
      },
      {
        label: 'Probishtip',
      },
      {
        label: 'Radovish',
      },
      {
        label: 'Rankovce',
      },
      {
        label: 'Mavrovo and Rostuša',
      },
      {
        label: 'Sopište',
      },
      {
        label: 'Staro Nagorichane',
      },
      {
        label: 'Shtip',
      },
      {
        label: 'Struga',
      },
      {
        label: 'Strumica',
      },
      {
        label: 'Studenichani',
      },
      {
        label: 'Sveti Nikole',
      },
      {
        label: 'Tearce',
      },
      {
        label: 'Tetovo',
      },
      {
        label: 'Veles',
      },
      {
        label: 'Vevchani',
      },
      {
        label: 'Vinica',
      },
      {
        label: 'Vrapchishte',
      },
      {
        label: 'Zelenikovo',
      },
      {
        label: 'Zhelino',
      },
      {
        label: 'Debarca',
      },
      {
        label: 'Grad Skopje',
      },
    ],
  },
  {
    label: 'Mali',
    countryCode: 'ML',
    countryCodeAlpha3: 'MLI',
    phone: '223',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Tombouctou',
      },
      {
        label: 'Sikasso',
      },
      {
        label: 'Ségou',
      },
      {
        label: 'Mopti',
      },
      {
        label: 'Koulikoro',
      },
      {
        label: 'Kayes',
      },
      {
        label: 'Gao',
      },
      {
        label: 'Bamako',
      },
      {
        label: 'Kidal',
      },
      {
        label: 'Taoudénit',
      },
      {
        label: 'Ménaka',
      },
    ],
  },
  {
    label: 'Myanmar',
    countryCode: 'MM',
    countryCodeAlpha3: 'MMR',
    phone: '95',
    currency: 'MMK',
    stateProvinces: [
      {
        label: 'Tanintharyi',
      },
      {
        label: 'Shan',
      },
      {
        label: 'Sagain',
      },
      {
        label: 'Yangon',
      },
      {
        label: 'Rakhine',
      },
      {
        label: 'Bago',
      },
      {
        label: 'Mon',
      },
      {
        label: 'Mandalay',
      },
      {
        label: 'Magway',
      },
      {
        label: 'Kayah',
      },
      {
        label: 'Kayin',
      },
      {
        label: 'Kachin',
      },
      {
        label: 'Ayeyarwady',
      },
      {
        label: 'Chin',
      },
      {
        label: 'Nay Pyi Taw',
      },
    ],
  },
  {
    label: 'Mongolia',
    countryCode: 'MN',
    countryCodeAlpha3: 'MNG',
    phone: '976',
    currency: 'MNT',
    stateProvinces: [
      {
        label: 'Uvs',
      },
      {
        label: 'Hovd',
      },
      {
        label: 'Govĭ-Altay',
      },
      {
        label: 'Dzabkhan',
      },
      {
        label: 'Bayan-Ölgiy',
      },
      {
        label: 'Bayanhongor',
      },
      {
        label: 'Ulaanbaatar',
      },
      {
        label: 'Central Aimak',
      },
      {
        label: 'Sühbaatar',
      },
      {
        label: 'Selenge',
      },
      {
        label: 'Övörhangay',
      },
      {
        label: 'Ömnögovĭ',
      },
      {
        label: 'Hövsgöl',
      },
      {
        label: 'Hentiy',
      },
      {
        label: 'Middle Govĭ',
      },
      {
        label: 'East Gobi Aymag',
      },
      {
        label: 'East Aimak',
      },
      {
        label: 'Bulgan',
      },
      {
        label: 'Arhangay',
      },
      {
        label: 'Darhan Uul',
      },
      {
        label: 'Govĭ-Sumber',
      },
      {
        label: 'Orhon',
      },
    ],
  },
  {
    label: 'Macao',
    countryCode: 'MO',
    countryCodeAlpha3: 'MAC',
    phone: '853',
    currency: 'MOP',
    stateProvinces: [
      {
        label: 'Nossa Senhora de Fátima',
      },
      {
        label: 'Santo António',
      },
      {
        label: 'São Lázaro',
      },
      {
        label: 'Sé',
      },
      {
        label: 'São Lourenço',
      },
      {
        label: 'Nossa Senhora do Carmo',
      },
      {
        label: 'Cotai',
      },
      {
        label: 'São Francisco Xavier',
      },
    ],
  },
  {
    label: 'Northern Mariana Islands',
    countryCode: 'MP',
    countryCodeAlpha3: 'MNP',
    phone: '+1-670',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Rota',
      },
      {
        label: 'Saipan',
      },
      {
        label: 'Tinian',
      },
      {
        label: 'Northern Islands',
      },
    ],
  },
  {
    label: 'Martinique',
    countryCode: 'MQ',
    countryCodeAlpha3: 'MTQ',
    phone: '596',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Martinique',
      },
    ],
  },
  {
    label: 'Mauritania',
    countryCode: 'MR',
    countryCodeAlpha3: 'MRT',
    phone: '222',
    currency: 'MRU',
    stateProvinces: [
      {
        label: 'Trarza',
      },
      {
        label: 'Tiris Zemmour',
      },
      {
        label: 'Tagant',
      },
      {
        label: 'Inchiri',
      },
      {
        label: 'Hodh El Gharbi',
      },
      {
        label: 'Hodh Ech Chargi',
      },
      {
        label: 'Guidimaka',
      },
      {
        label: 'Gorgol',
      },
      {
        label: 'Dakhlet Nouadhibou',
      },
      {
        label: 'Brakna',
      },
      {
        label: 'Assaba',
      },
      {
        label: 'Adrar',
      },
      {
        label: 'Nouakchott Ouest',
      },
      {
        label: 'Nouakchott Nord',
      },
      {
        label: 'Nouakchott Sud',
      },
    ],
  },
  {
    label: 'Montserrat',
    countryCode: 'MS',
    countryCodeAlpha3: 'MSR',
    phone: '+1-664',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Saint Peter',
      },
      {
        label: 'Saint Georges',
      },
      {
        label: 'Saint Anthony',
      },
    ],
  },
  {
    label: 'Malta',
    countryCode: 'MT',
    countryCodeAlpha3: 'MLT',
    phone: '356',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Attard',
      },
      {
        label: 'Balzan',
      },
      {
        label: 'Il-Birgu',
      },
      {
        label: 'Birkirkara',
      },
      {
        label: 'Birżebbuġa',
      },
      {
        label: 'Bormla',
      },
      {
        label: 'Dingli',
      },
      {
        label: 'Il-Fgura',
      },
      {
        label: 'Floriana',
      },
      {
        label: 'Il-Fontana',
      },
      {
        label: 'Għajnsielem',
      },
      {
        label: 'L-Għarb',
      },
      {
        label: 'Ħal Għargħur',
      },
      {
        label: 'L-Għasri',
      },
      {
        label: 'Ħal Għaxaq',
      },
      {
        label: 'Il-Gudja',
      },
      {
        label: 'Il-Gżira',
      },
      {
        label: 'Il-Ħamrun',
      },
      {
        label: 'L-Iklin',
      },
      {
        label: 'L-Imdina',
      },
      {
        label: 'L-Imġarr',
      },
      {
        label: 'L-Imqabba',
      },
      {
        label: 'L-Imsida',
      },
      {
        label: 'Mtarfa',
      },
      {
        label: 'Senglea',
      },
      {
        label: 'Il-Kalkara',
      },
      {
        label: 'Ta’ Kerċem',
      },
      {
        label: 'Kirkop',
      },
      {
        label: 'Lija',
      },
      {
        label: 'Luqa',
      },
      {
        label: 'Il-Marsa',
      },
      {
        label: 'Marsaskala',
      },
      {
        label: 'Marsaxlokk',
      },
      {
        label: 'Il-Mellieħa',
      },
      {
        label: 'Il-Mosta',
      },
      {
        label: 'Il-Munxar',
      },
      {
        label: 'In-Nadur',
      },
      {
        label: 'In-Naxxar',
      },
      {
        label: 'Paola',
      },
      {
        label: 'Pembroke',
      },
      {
        label: 'Tal-Pietà',
      },
      {
        label: 'Il-Qala',
      },
      {
        label: 'Qormi',
      },
      {
        label: 'Il-Qrendi',
      },
      {
        label: 'Ir-Rabat',
      },
      {
        label: 'Victoria',
      },
      {
        label: 'Safi',
      },
      {
        label: 'Saint John',
      },
      {
        label: 'Saint Julian',
      },
      {
        label: 'Saint Lawrence',
      },
      {
        label: 'Saint Lucia',
      },
      {
        label: 'Saint Paul’s Bay',
      },
      {
        label: 'Saint Venera',
      },
      {
        label: 'Sannat',
      },
      {
        label: 'Is-Siġġiewi',
      },
      {
        label: 'Tas-Sliema',
      },
      {
        label: 'Is-Swieqi',
      },
      {
        label: 'Tarxien',
      },
      {
        label: 'Ta’ Xbiex',
      },
      {
        label: 'Ix-Xagħra',
      },
      {
        label: 'Ix-Xewkija',
      },
      {
        label: 'Ix-Xgħajra',
      },
      {
        label: 'Ħaż-Żabbar',
      },
      {
        label: 'Ħaż-Żebbuġ',
      },
      {
        label: 'Iż-Żebbuġ',
      },
      {
        label: 'Iż-Żejtun',
      },
      {
        label: 'Iż-Żurrieq',
      },
      {
        label: 'Valletta',
      },
    ],
  },
  {
    label: 'Mauritius',
    countryCode: 'MU',
    countryCodeAlpha3: 'MUS',
    phone: '230',
    currency: 'MUR',
    stateProvinces: [
      {
        label: 'Agalega Islands',
      },
      {
        label: 'Savanne',
      },
      {
        label: 'Rivière du Rempart',
      },
      {
        label: 'Port Louis',
      },
      {
        label: 'Plaines Wilhems',
      },
      {
        label: 'Pamplemousses',
      },
      {
        label: 'Moka',
      },
      {
        label: 'Grand Port',
      },
      {
        label: 'Flacq',
      },
      {
        label: 'Black River',
      },
      {
        label: 'Cargados Carajos',
      },
      {
        label: 'Rodrigues',
      },
    ],
  },
  {
    label: 'Maldives',
    countryCode: 'MV',
    countryCodeAlpha3: 'MDV',
    phone: '960',
    currency: 'MVR',
    stateProvinces: [
      {
        label: 'Vaavu Atholhu',
      },
      {
        label: 'Thaa Atholhu',
      },
      {
        label: 'Shaviyani Atholhu',
      },
      {
        label: 'Seenu',
      },
      {
        label: 'Raa Atoll',
      },
      {
        label: 'Noonu Atoll',
      },
      {
        label: 'Gnyaviyani Atoll',
      },
      {
        label: 'Meemu Atholhu',
      },
      {
        label: 'Faadhippolhu Atoll',
      },
      {
        label: 'Laamu',
      },
      {
        label: 'Kaafu Atoll',
      },
      {
        label: 'Haa Dhaalu Atholhu',
      },
      {
        label: 'Haa Alifu Atholhu',
      },
      {
        label: 'Gaafu Dhaalu Atoll',
      },
      {
        label: 'Gaafu Alif Atoll',
      },
      {
        label: 'Faafu Atholhu',
      },
      {
        label: 'Dhaalu Atholhu',
      },
      {
        label: 'Baa Atholhu',
      },
      {
        label: 'Northern Ari Atoll',
      },
      {
        label: 'Male',
      },
      {
        label: 'Southern Ari Atoll',
      },
    ],
  },
  {
    label: 'Malawi',
    countryCode: 'MW',
    countryCodeAlpha3: 'MWI',
    phone: '265',
    currency: 'MWK',
    stateProvinces: [
      {
        label: 'Southern Region',
      },
      {
        label: 'Northern Region',
      },
      {
        label: 'Central Region',
      },
    ],
  },
  {
    label: 'Mexico',
    countryCode: 'MX',
    countryCodeAlpha3: 'MEX',
    phone: '52',
    currency: 'MXN',
    stateProvinces: [
      {
        label: 'Yucatán',
      },
      {
        label: 'Veracruz',
      },
      {
        label: 'Tlaxcala',
      },
      {
        label: 'Tamaulipas',
      },
      {
        label: 'Tabasco',
      },
      {
        label: 'Quintana Roo',
      },
      {
        label: 'Querétaro',
      },
      {
        label: 'Puebla',
      },
      {
        label: 'Oaxaca',
      },
      {
        label: 'Nuevo León',
      },
      {
        label: 'Morelos',
      },
      {
        label: 'México',
      },
      {
        label: 'Hidalgo',
      },
      {
        label: 'Guerrero',
      },
      {
        label: 'Mexico City',
      },
      {
        label: 'Chiapas',
      },
      {
        label: 'Campeche',
      },
      {
        label: 'Zacatecas',
      },
      {
        label: 'Sonora',
      },
      {
        label: 'Sinaloa',
      },
      {
        label: 'San Luis Potosí',
      },
      {
        label: 'Nayarit',
      },
      {
        label: 'Michoacán',
      },
      {
        label: 'Jalisco',
      },
      {
        label: 'Guanajuato',
      },
      {
        label: 'Durango',
      },
      {
        label: 'Colima',
      },
      {
        label: 'Coahuila',
      },
      {
        label: 'Chihuahua',
      },
      {
        label: 'Baja California Sur',
      },
      {
        label: 'Baja California',
      },
      {
        label: 'Aguascalientes',
      },
    ],
  },
  {
    label: 'Malaysia',
    countryCode: 'MY',
    countryCodeAlpha3: 'MYS',
    phone: '60',
    currency: 'MYR',
    stateProvinces: [
      {
        label: 'Melaka',
      },
      {
        label: 'Terengganu',
      },
      {
        label: 'Selangor',
      },
      {
        label: 'Sarawak',
      },
      {
        label: 'Sabah',
      },
      {
        label: 'Perlis',
      },
      {
        label: 'Perak',
      },
      {
        label: 'Pahang',
      },
      {
        label: 'Negeri Sembilan',
      },
      {
        label: 'Kelantan',
      },
      {
        label: 'Kuala Lumpur',
      },
      {
        label: 'Penang',
      },
      {
        label: 'Kedah',
      },
      {
        label: 'Johor',
      },
      {
        label: 'Labuan',
      },
      {
        label: 'Putrajaya',
      },
    ],
  },
  {
    label: 'Mozambique',
    countryCode: 'MZ',
    countryCodeAlpha3: 'MOZ',
    phone: '258',
    currency: 'MZN',
    stateProvinces: [
      {
        label: 'Zambézia',
      },
      {
        label: 'Tete',
      },
      {
        label: 'Sofala',
      },
      {
        label: 'Niassa',
      },
      {
        label: 'Nampula',
      },
      {
        label: 'Maputo Province',
      },
      {
        label: 'Manica',
      },
      {
        label: 'Inhambane',
      },
      {
        label: 'Gaza',
      },
      {
        label: 'Cabo Delgado',
      },
      {
        label: 'Maputo City',
      },
    ],
  },
  {
    label: 'Namibia',
    countryCode: 'NA',
    countryCodeAlpha3: 'NAM',
    phone: '264',
    currency: 'NAD',
    stateProvinces: [
      {
        label: 'Zambezi',
      },
      {
        label: 'Khomas',
      },
      {
        label: 'Erongo',
      },
      {
        label: 'Hardap',
      },
      {
        label: 'Karas',
      },
      {
        label: 'Kunene',
      },
      {
        label: 'Ohangwena',
      },
      {
        label: 'Omaheke',
      },
      {
        label: 'Omusati',
      },
      {
        label: 'Oshana',
      },
      {
        label: 'Oshikoto',
      },
      {
        label: 'Otjozondjupa',
      },
      {
        label: 'Kavango East',
      },
      {
        label: 'Kavango West',
      },
    ],
  },
  {
    label: 'New Caledonia',
    countryCode: 'NC',
    countryCodeAlpha3: 'NCL',
    phone: '687',
    currency: 'XPF',
    stateProvinces: [
      {
        label: 'South Province',
      },
      {
        label: 'North Province',
      },
      {
        label: 'Loyalty Islands',
      },
    ],
  },
  {
    label: 'Niger',
    countryCode: 'NE',
    countryCodeAlpha3: 'NER',
    phone: '227',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Zinder',
      },
      {
        label: 'Tahoua',
      },
      {
        label: 'Maradi',
      },
      {
        label: 'Dosso',
      },
      {
        label: 'Diffa',
      },
      {
        label: 'Agadez',
      },
      {
        label: 'Tillabéri',
      },
      {
        label: 'Niamey',
      },
    ],
  },
  {
    label: 'Norfolk Island',
    countryCode: 'NF',
    countryCodeAlpha3: 'NFK',
    phone: '672',
    currency: 'AUD',
    stateProvinces: null,
  },
  {
    label: 'Nigeria',
    countryCode: 'NG',
    countryCodeAlpha3: 'NGA',
    phone: '234',
    currency: 'NGN',
    stateProvinces: [
      {
        label: 'Sokoto',
      },
      {
        label: 'Rivers',
      },
      {
        label: 'Plateau',
      },
      {
        label: 'Oyo',
      },
      {
        label: 'Ondo',
      },
      {
        label: 'Ogun',
      },
      {
        label: 'Niger',
      },
      {
        label: 'Lagos',
      },
      {
        label: 'Kwara',
      },
      {
        label: 'Katsina',
      },
      {
        label: 'Kano',
      },
      {
        label: 'Kaduna',
      },
      {
        label: 'Imo',
      },
      {
        label: 'Cross River',
      },
      {
        label: 'Borno',
      },
      {
        label: 'Benue',
      },
      {
        label: 'Bauchi',
      },
      {
        label: 'Anambra',
      },
      {
        label: 'Akwa Ibom',
      },
      {
        label: 'FCT',
      },
      {
        label: 'Abia',
      },
      {
        label: 'Delta',
      },
      {
        label: 'Adamawa',
      },
      {
        label: 'Edo',
      },
      {
        label: 'Enugu',
      },
      {
        label: 'Jigawa',
      },
      {
        label: 'Bayelsa',
      },
      {
        label: 'Ebonyi',
      },
      {
        label: 'Ekiti',
      },
      {
        label: 'Gombe',
      },
      {
        label: 'Nassarawa',
      },
      {
        label: 'Zamfara',
      },
      {
        label: 'Kebbi',
      },
      {
        label: 'Kogi',
      },
      {
        label: 'Osun',
      },
      {
        label: 'Taraba',
      },
      {
        label: 'Yobe',
      },
    ],
  },
  {
    label: 'Nicaragua',
    countryCode: 'NI',
    countryCodeAlpha3: 'NIC',
    phone: '505',
    currency: 'NIO',
    stateProvinces: [
      {
        label: 'Rivas',
      },
      {
        label: 'Río San Juan Department',
      },
      {
        label: 'Nueva Segovia Department',
      },
      {
        label: 'Matagalpa Department',
      },
      {
        label: 'Masaya Department',
      },
      {
        label: 'Managua Department',
      },
      {
        label: 'Madriz Department',
      },
      {
        label: 'León Department',
      },
      {
        label: 'Jinotega Department',
      },
      {
        label: 'Granada Department',
      },
      {
        label: 'Estelí Department',
      },
      {
        label: 'Chontales Department',
      },
      {
        label: 'Chinandega',
      },
      {
        label: 'Carazo Department',
      },
      {
        label: 'Boaco Department',
      },
      {
        label: 'North Caribbean Coast',
      },
      {
        label: 'South Caribbean Coast',
      },
    ],
  },
  {
    label: 'Netherlands',
    countryCode: 'NL',
    countryCodeAlpha3: 'NLD',
    phone: '31',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'South Holland',
      },
      {
        label: 'Zeeland',
      },
      {
        label: 'Utrecht',
      },
      {
        label: 'Overijssel',
      },
      {
        label: 'North Holland',
      },
      {
        label: 'North Brabant',
      },
      {
        label: 'Limburg',
      },
      {
        label: 'Groningen',
      },
      {
        label: 'Gelderland',
      },
      {
        label: 'Friesland',
      },
      {
        label: 'Drenthe',
      },
      {
        label: 'Flevoland',
      },
    ],
  },
  {
    label: 'Norway',
    countryCode: 'NO',
    countryCodeAlpha3: 'NOR',
    phone: '47',
    currency: 'NOK',
    stateProvinces: [
      {
        label: 'Rogaland',
      },
      {
        label: 'Oslo',
      },
      {
        label: 'Nordland',
      },
      {
        label: 'Møre og Romsdal',
      },
      {
        label: 'Trøndelag',
      },
      {
        label: 'Viken',
      },
      {
        label: 'Agder',
      },
      {
        label: 'Troms og Finnmark',
      },
      {
        label: 'Innlandet',
      },
      {
        label: 'Vestfold og Telemark',
      },
      {
        label: 'Vestland',
      },
    ],
  },
  {
    label: 'Nepal',
    countryCode: 'NP',
    countryCodeAlpha3: 'NPL',
    phone: '977',
    currency: 'NPR',
    stateProvinces: [
      {
        label: 'Province 1',
      },
      {
        label: 'Province 2',
      },
      {
        label: 'Bagmati Province',
      },
      {
        label: 'Province 4',
      },
      {
        label: 'Lumbini Province',
      },
      {
        label: 'Karnali Pradesh',
      },
      {
        label: 'Sudurpashchim Pradesh',
      },
    ],
  },
  {
    label: 'Nauru',
    countryCode: 'NR',
    countryCodeAlpha3: 'NRU',
    phone: '674',
    currency: 'AUD',
    stateProvinces: [
      {
        label: 'Yaren',
      },
      {
        label: 'Uaboe',
      },
      {
        label: 'Nibok',
      },
      {
        label: 'Meneng',
      },
      {
        label: 'Ijuw',
      },
      {
        label: 'Ewa',
      },
      {
        label: 'Denigomodu',
      },
      {
        label: 'Buada',
      },
      {
        label: 'Boe',
      },
      {
        label: 'Baiti',
      },
      {
        label: 'Anibare',
      },
      {
        label: 'Anetan',
      },
      {
        label: 'Anabar',
      },
      {
        label: 'Aiwo',
      },
    ],
  },
  {
    label: 'Niue',
    countryCode: 'NU',
    countryCodeAlpha3: 'NIU',
    phone: '683',
    currency: 'NZD',
    stateProvinces: null,
  },
  {
    label: 'New Zealand',
    countryCode: 'NZ',
    countryCodeAlpha3: 'NZL',
    phone: '64',
    currency: 'NZD',
    stateProvinces: [
      {
        label: 'Wellington',
      },
      {
        label: 'Manawatu-Wanganui',
      },
      {
        label: 'Waikato',
      },
      {
        label: 'Tasman',
      },
      {
        label: 'Taranaki',
      },
      {
        label: 'Southland',
      },
      {
        label: 'Bay of Plenty',
      },
      {
        label: 'Northland',
      },
      {
        label: 'Marlborough',
      },
      {
        label: "Hawke's Bay",
      },
      {
        label: 'Gisborne',
      },
      {
        label: 'Canterbury',
      },
      {
        label: 'Auckland',
      },
      {
        label: 'Chatham Islands',
      },
      {
        label: 'Nelson',
      },
      {
        label: 'Otago',
      },
      {
        label: 'West Coast',
      },
    ],
  },
  {
    label: 'Oman',
    countryCode: 'OM',
    countryCodeAlpha3: 'OMN',
    phone: '968',
    currency: 'OMR',
    stateProvinces: [
      {
        label: 'Ad Dakhiliyah',
      },
      {
        label: 'Al Batinah South',
      },
      {
        label: 'Al Wusta Governorate',
      },
      {
        label: 'Southeastern Governorate',
      },
      {
        label: 'Ad Dhahirah',
      },
      {
        label: 'Muscat',
      },
      {
        label: 'Musandam Governorate',
      },
      {
        label: 'Dhofar',
      },
      {
        label: 'Al Buraimi',
      },
      {
        label: 'Northeastern Governorate',
      },
      {
        label: 'Al Batinah North',
      },
    ],
  },
  {
    label: 'Panama',
    countryCode: 'PA',
    countryCodeAlpha3: 'PAN',
    phone: '507',
    currency: 'PAB',
    stateProvinces: [
      {
        label: 'Veraguas',
      },
      {
        label: 'Guna Yala',
      },
      {
        label: 'Panamá',
      },
      {
        label: 'Los Santos',
      },
      {
        label: 'Herrera',
      },
      {
        label: 'Darién',
      },
      {
        label: 'Colón',
      },
      {
        label: 'Coclé',
      },
      {
        label: 'Chiriquí Province',
      },
      {
        label: 'Bocas del Toro Province',
      },
      {
        label: 'Emberá',
      },
      {
        label: 'Ngöbe-Buglé',
      },
      {
        label: 'Panamá Oeste Province',
      },
      {
        label: 'PA.NT',
      },
    ],
  },
  {
    label: 'Peru',
    countryCode: 'PE',
    countryCodeAlpha3: 'PER',
    phone: '51',
    currency: 'PEN',
    stateProvinces: [
      {
        label: 'Ucayali',
      },
      {
        label: 'Tumbes',
      },
      {
        label: 'San Martín',
      },
      {
        label: 'Piura',
      },
      {
        label: 'Loreto',
      },
      {
        label: 'Lambayeque',
      },
      {
        label: 'La Libertad',
      },
      {
        label: 'Huanuco',
      },
      {
        label: 'Cajamarca',
      },
      {
        label: 'Ancash',
      },
      {
        label: 'Amazonas',
      },
      {
        label: 'Tacna',
      },
      {
        label: 'Puno',
      },
      {
        label: 'Pasco',
      },
      {
        label: 'Moquegua',
      },
      {
        label: 'Madre de Dios',
      },
      {
        label: 'Lima',
      },
      {
        label: 'Lima region',
      },
      {
        label: 'Junin',
      },
      {
        label: 'Ica',
      },
      {
        label: 'Huancavelica',
      },
      {
        label: 'Cusco',
      },
      {
        label: 'Callao',
      },
      {
        label: 'Ayacucho',
      },
      {
        label: 'Arequipa',
      },
      {
        label: 'Apurímac',
      },
    ],
  },
  {
    label: 'French Polynesia',
    countryCode: 'PF',
    countryCodeAlpha3: 'PYF',
    phone: '689',
    currency: 'XPF',
    stateProvinces: [
      {
        label: 'Îles Marquises',
      },
      {
        label: 'Îles Tuamotu-Gambier',
      },
      {
        label: 'Leeward Islands',
      },
      {
        label: 'Îles du Vent',
      },
      {
        label: 'Îles Australes',
      },
    ],
  },
  {
    label: 'Papua New Guinea',
    countryCode: 'PG',
    countryCodeAlpha3: 'PNG',
    phone: '675',
    currency: 'PGK',
    stateProvinces: [
      {
        label: 'West New Britain',
      },
      {
        label: 'Western Province',
      },
      {
        label: 'Western Highlands',
      },
      {
        label: 'Southern Highlands',
      },
      {
        label: 'Sandaun',
      },
      {
        label: 'Bougainville',
      },
      {
        label: 'Northern Province',
      },
      {
        label: 'New Ireland',
      },
      {
        label: 'National Capital',
      },
      {
        label: 'Morobe',
      },
      {
        label: 'Manus',
      },
      {
        label: 'Madang',
      },
      {
        label: 'Gulf',
      },
      {
        label: 'Enga',
      },
      {
        label: 'East Sepik',
      },
      {
        label: 'East New Britain',
      },
      {
        label: 'Eastern Highlands',
      },
      {
        label: 'Chimbu',
      },
      {
        label: 'Milne Bay',
      },
      {
        label: 'Central Province',
      },
      {
        label: 'Hela',
      },
      {
        label: 'Jiwaka',
      },
    ],
  },
  {
    label: 'Philippines',
    countryCode: 'PH',
    countryCodeAlpha3: 'PHL',
    phone: '63',
    currency: 'PHP',
    stateProvinces: [
      {
        label: 'Autonomous Region in Muslim Mindanao',
      },
      {
        label: 'Northern Mindanao',
      },
      {
        label: 'Mimaropa',
      },
      {
        label: 'Cagayan Valley',
      },
      {
        label: 'Soccsksargen',
      },
      {
        label: 'Caraga',
      },
      {
        label: 'Cordillera',
      },
      {
        label: 'Ilocos',
      },
      {
        label: 'Calabarzon',
      },
      {
        label: 'Western Visayas',
      },
      {
        label: 'Central Luzon',
      },
      {
        label: 'Central Visayas',
      },
      {
        label: 'Eastern Visayas',
      },
      {
        label: 'Zamboanga Peninsula',
      },
      {
        label: 'Davao',
      },
      {
        label: 'Bicol',
      },
      {
        label: 'Metro Manila',
      },
    ],
  },
  {
    label: 'Pakistan',
    countryCode: 'PK',
    countryCodeAlpha3: 'PAK',
    phone: '92',
    currency: 'PKR',
    stateProvinces: [
      {
        label: 'Islamabad',
      },
      {
        label: 'Sindh',
      },
      {
        label: 'Punjab',
      },
      {
        label: 'Khyber Pakhtunkhwa',
      },
      {
        label: 'Gilgit-Baltistan',
      },
      {
        label: 'Balochistan',
      },
      {
        label: 'Azad Kashmir',
      },
    ],
  },
  {
    label: 'Poland',
    countryCode: 'PL',
    countryCodeAlpha3: 'POL',
    phone: '48',
    currency: 'PLN',
    stateProvinces: [
      {
        label: 'Lublin',
      },
      {
        label: 'Lesser Poland',
      },
      {
        label: 'Mazovia',
      },
      {
        label: 'Subcarpathia',
      },
      {
        label: 'Podlasie',
      },
      {
        label: 'Świętokrzyskie',
      },
      {
        label: 'Warmia-Masuria',
      },
      {
        label: 'Lower Silesia',
      },
      {
        label: 'Łódź Voivodeship',
      },
      {
        label: 'Lubusz',
      },
      {
        label: 'Opole Voivodeship',
      },
      {
        label: 'Pomerania',
      },
      {
        label: 'Silesia',
      },
      {
        label: 'Greater Poland',
      },
      {
        label: 'West Pomerania',
      },
      {
        label: 'Kujawsko-Pomorskie',
      },
    ],
  },
  {
    label: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
    countryCodeAlpha3: 'SPM',
    phone: '508',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Saint-Pierre',
      },
      {
        label: 'Miquelon-Langlade',
      },
    ],
  },
  {
    label: 'Pitcairn',
    countryCode: 'PN',
    countryCodeAlpha3: 'PCN',
    phone: '870',
    currency: 'NZD',
    stateProvinces: null,
  },
  {
    label: 'Puerto Rico',
    countryCode: 'PR',
    countryCodeAlpha3: 'PRI',
    phone: '+1-787 and 1-939',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Adjuntas',
      },
      {
        label: 'Aguada',
      },
      {
        label: 'Aguadilla',
      },
      {
        label: 'Aguas Buenas',
      },
      {
        label: 'Aibonito',
      },
      {
        label: 'Añasco',
      },
      {
        label: 'Arecibo',
      },
      {
        label: 'Arroyo',
      },
      {
        label: 'Barceloneta',
      },
      {
        label: 'Barranquitas',
      },
      {
        label: 'Bayamón',
      },
      {
        label: 'Cabo Rojo',
      },
      {
        label: 'Caguas',
      },
      {
        label: 'Camuy',
      },
      {
        label: 'Canóvanas',
      },
      {
        label: 'Carolina',
      },
      {
        label: 'Cataño',
      },
      {
        label: 'Cayey',
      },
      {
        label: 'Ceiba',
      },
      {
        label: 'Ciales',
      },
      {
        label: 'Cidra',
      },
      {
        label: 'Coamo',
      },
      {
        label: 'Comerío',
      },
      {
        label: 'Corozal',
      },
      {
        label: 'Culebra',
      },
      {
        label: 'Dorado',
      },
      {
        label: 'Fajardo',
      },
      {
        label: 'Florida',
      },
      {
        label: 'Guánica',
      },
      {
        label: 'Guayama',
      },
      {
        label: 'Guayanilla',
      },
      {
        label: 'Guaynabo',
      },
      {
        label: 'Gurabo',
      },
      {
        label: 'Hatillo',
      },
      {
        label: 'Hormigueros',
      },
      {
        label: 'Humacao',
      },
      {
        label: 'Isabela',
      },
      {
        label: 'Jayuya',
      },
      {
        label: 'Juana Díaz',
      },
      {
        label: 'Juncos',
      },
      {
        label: 'Lajas',
      },
      {
        label: 'Lares',
      },
      {
        label: 'Las Marías',
      },
      {
        label: 'Las Piedras',
      },
      {
        label: 'Loíza',
      },
      {
        label: 'Luquillo',
      },
      {
        label: 'Manatí',
      },
      {
        label: 'Maricao',
      },
      {
        label: 'Maunabo',
      },
      {
        label: 'Mayagüez',
      },
      {
        label: 'Moca',
      },
      {
        label: 'Morovis',
      },
      {
        label: 'Naguabo',
      },
      {
        label: 'Naranjito',
      },
      {
        label: 'Orocovis',
      },
      {
        label: 'Patillas',
      },
      {
        label: 'Peñuelas',
      },
      {
        label: 'Ponce',
      },
      {
        label: 'Rincón',
      },
      {
        label: 'Quebradillas',
      },
      {
        label: 'Río Grande',
      },
      {
        label: 'Sabana Grande',
      },
      {
        label: 'Salinas',
      },
      {
        label: 'San Germán',
      },
      {
        label: 'San Juan',
      },
      {
        label: 'San Lorenzo',
      },
      {
        label: 'San Sebastián',
      },
      {
        label: 'Santa Isabel',
      },
      {
        label: 'Toa Alta',
      },
      {
        label: 'Toa Baja',
      },
      {
        label: 'Trujillo Alto',
      },
      {
        label: 'Utuado',
      },
      {
        label: 'Vega Alta',
      },
      {
        label: 'Vega Baja',
      },
      {
        label: 'Villalba',
      },
      {
        label: 'Yabucoa',
      },
      {
        label: 'Yauco',
      },
      {
        label: 'Vieques',
      },
    ],
  },
  {
    label: 'Palestinian Territory',
    countryCode: 'PS',
    countryCodeAlpha3: 'PSE',
    phone: '970',
    currency: 'ILS',
    stateProvinces: [
      {
        label: 'Gaza Strip',
      },
      {
        label: 'West Bank',
      },
    ],
  },
  {
    label: 'Portugal',
    countryCode: 'PT',
    countryCodeAlpha3: 'PRT',
    phone: '351',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'District of Setúbal',
      },
      {
        label: 'Santarém',
      },
      {
        label: 'Portalegre',
      },
      {
        label: 'Lisbon',
      },
      {
        label: 'Leiria',
      },
      {
        label: 'Faro',
      },
      {
        label: 'Évora',
      },
      {
        label: 'Castelo Branco',
      },
      {
        label: 'Beja',
      },
      {
        label: 'Madeira',
      },
      {
        label: 'Viseu',
      },
      {
        label: 'Vila Real',
      },
      {
        label: 'Viana do Castelo',
      },
      {
        label: 'Porto',
      },
      {
        label: 'Guarda',
      },
      {
        label: 'Coimbra',
      },
      {
        label: 'Bragança',
      },
      {
        label: 'Braga',
      },
      {
        label: 'Aveiro',
      },
      {
        label: 'Azores',
      },
    ],
  },
  {
    label: 'Palau',
    countryCode: 'PW',
    countryCodeAlpha3: 'PLW',
    phone: '680',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Ngatpang',
      },
      {
        label: 'Sonsorol',
      },
      {
        label: 'Kayangel',
      },
      {
        label: 'Hatohobei',
      },
      {
        label: 'Aimeliik',
      },
      {
        label: 'Airai',
      },
      {
        label: 'Angaur',
      },
      {
        label: 'Koror',
      },
      {
        label: 'Melekeok',
      },
      {
        label: 'Ngaraard',
      },
      {
        label: 'Ngchesar',
      },
      {
        label: 'Ngarchelong',
      },
      {
        label: 'Ngardmau',
      },
      {
        label: 'Ngaremlengui',
      },
      {
        label: 'Ngiwal',
      },
      {
        label: 'Peleliu',
      },
    ],
  },
  {
    label: 'Paraguay',
    countryCode: 'PY',
    countryCodeAlpha3: 'PRY',
    phone: '595',
    currency: 'PYG',
    stateProvinces: [
      {
        label: 'San Pedro',
      },
      {
        label: 'Presidente Hayes',
      },
      {
        label: 'Paraguarí',
      },
      {
        label: 'Ñeembucú',
      },
      {
        label: 'Misiones',
      },
      {
        label: 'Itapúa',
      },
      {
        label: 'Guairá',
      },
      {
        label: 'Cordillera',
      },
      {
        label: 'Concepción',
      },
      {
        label: 'Central',
      },
      {
        label: 'Canindeyú',
      },
      {
        label: 'Caazapá',
      },
      {
        label: 'Caaguazú',
      },
      {
        label: 'Amambay',
      },
      {
        label: 'Alto Paraná',
      },
      {
        label: 'Alto Paraguay',
      },
      {
        label: 'Asunción',
      },
      {
        label: 'Boquerón',
      },
    ],
  },
  {
    label: 'Qatar',
    countryCode: 'QA',
    countryCodeAlpha3: 'QAT',
    phone: '974',
    currency: 'QAR',
    stateProvinces: [
      {
        label: 'Madīnat ash Shamāl',
      },
      {
        label: 'Al Khor',
      },
      {
        label: 'Baladīyat Umm Şalāl',
      },
      {
        label: 'Baladīyat ar Rayyān',
      },
      {
        label: 'Baladīyat ad Dawḩah',
      },
      {
        label: 'Al Wakrah',
      },
      {
        label: 'Baladīyat az̧ Z̧a‘āyin',
      },
      {
        label: 'Al-Shahaniya',
      },
    ],
  },
  {
    label: 'Reunion',
    countryCode: 'RE',
    countryCodeAlpha3: 'REU',
    phone: '262',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Réunion',
      },
    ],
  },
  {
    label: 'Romania',
    countryCode: 'RO',
    countryCodeAlpha3: 'ROU',
    phone: '40',
    currency: 'RON',
    stateProvinces: [
      {
        label: 'Vrancea',
      },
      {
        label: 'Vâlcea',
      },
      {
        label: 'Vaslui',
      },
      {
        label: 'Tulcea',
      },
      {
        label: 'Timiș',
      },
      {
        label: 'Teleorman',
      },
      {
        label: 'Suceava',
      },
      {
        label: 'Sibiu',
      },
      {
        label: 'Satu Mare',
      },
      {
        label: 'Sălaj',
      },
      {
        label: 'Prahova',
      },
      {
        label: 'Olt',
      },
      {
        label: 'Neamț',
      },
      {
        label: 'Mureș',
      },
      {
        label: 'Mehedinți',
      },
      {
        label: 'Maramureş',
      },
      {
        label: 'Iași',
      },
      {
        label: 'Ialomița',
      },
      {
        label: 'Hunedoara',
      },
      {
        label: 'Harghita',
      },
      {
        label: 'Gorj',
      },
      {
        label: 'Giurgiu',
      },
      {
        label: 'Galați',
      },
      {
        label: 'Dolj',
      },
      {
        label: 'Dâmbovița',
      },
      {
        label: 'Covasna',
      },
      {
        label: 'Constanța',
      },
      {
        label: 'Cluj',
      },
      {
        label: 'Caraș-Severin',
      },
      {
        label: 'Călărași',
      },
      {
        label: 'Buzău',
      },
      {
        label: 'București',
      },
      {
        label: 'Brașov',
      },
      {
        label: 'Brăila',
      },
      {
        label: 'Botoșani',
      },
      {
        label: 'Bistrița-Năsăud',
      },
      {
        label: 'Bihor',
      },
      {
        label: 'Bacău',
      },
      {
        label: 'Arges',
      },
      {
        label: 'Arad',
      },
      {
        label: 'Alba',
      },
      {
        label: 'Ilfov',
      },
    ],
  },
  {
    label: 'Serbia',
    countryCode: 'RS',
    countryCodeAlpha3: 'SRB',
    phone: '381',
    currency: 'RSD',
    stateProvinces: [
      {
        label: 'Vojvodina',
      },
      {
        label: 'Central Serbia',
      },
    ],
  },
  {
    label: 'Russia',
    countryCode: 'RU',
    countryCodeAlpha3: 'RUS',
    phone: '7',
    currency: 'RUB',
    stateProvinces: [
      {
        label: 'Yaroslavl Oblast',
      },
      {
        label: 'Voronezh Oblast',
      },
      {
        label: 'Vologda Oblast',
      },
      {
        label: 'Volgograd Oblast',
      },
      {
        label: 'Ulyanovsk',
      },
      {
        label: 'Udmurtiya Republic',
      },
      {
        label: 'Tver Oblast',
      },
      {
        label: 'Tula Oblast',
      },
      {
        label: 'Tatarstan Republic',
      },
      {
        label: 'Tambov Oblast',
      },
      {
        label: 'Stavropol Kray',
      },
      {
        label: 'Smolensk Oblast',
      },
      {
        label: 'Saratov Oblast',
      },
      {
        label: 'Samara Oblast',
      },
      {
        label: 'Ryazan Oblast',
      },
      {
        label: 'Rostov',
      },
      {
        label: 'Pskov Oblast',
      },
      {
        label: 'Perm Krai',
      },
      {
        label: 'Penza Oblast',
      },
      {
        label: 'Oryol oblast',
      },
      {
        label: 'Orenburg Oblast',
      },
      {
        label: 'Novgorod Oblast',
      },
      {
        label: 'North Ossetia–Alania',
      },
      {
        label: 'Nenets',
      },
      {
        label: 'Murmansk',
      },
      {
        label: 'Moscow',
      },
      {
        label: 'Moscow Oblast',
      },
      {
        label: 'Mordoviya Republic',
      },
      {
        label: 'Mariy-El Republic',
      },
      {
        label: 'Lipetsk Oblast',
      },
      {
        label: "Leningradskaya Oblast'",
      },
      {
        label: 'St.-Petersburg',
      },
      {
        label: 'Kursk Oblast',
      },
      {
        label: 'Krasnodar Krai',
      },
      {
        label: 'Kostroma Oblast',
      },
      {
        label: 'Komi',
      },
      {
        label: 'Kirov Oblast',
      },
      {
        label: 'Karelia',
      },
      {
        label: 'Karachayevo-Cherkesiya Republic',
      },
      {
        label: 'Kaluga Oblast',
      },
      {
        label: 'Kalmykiya Republic',
      },
      {
        label: 'Kaliningrad Oblast',
      },
      {
        label: 'Kabardino-Balkariya Republic',
      },
      {
        label: 'Ivanovo Oblast',
      },
      {
        label: 'Ingushetiya Republic',
      },
      {
        label: 'Nizhny Novgorod Oblast',
      },
      {
        label: 'Dagestan',
      },
      {
        label: 'Chuvashia',
      },
      {
        label: 'Chechnya',
      },
      {
        label: 'Bryansk Oblast',
      },
      {
        label: 'Belgorod Oblast',
      },
      {
        label: 'Bashkortostan Republic',
      },
      {
        label: 'Astrakhan Oblast',
      },
      {
        label: 'Arkhangelskaya',
      },
      {
        label: 'Adygeya Republic',
      },
      {
        label: 'Vladimir Oblast',
      },
      {
        label: 'Yamalo-Nenets',
      },
      {
        label: 'Tyumen Oblast',
      },
      {
        label: 'Republic of Tyva',
      },
      {
        label: 'Tomsk Oblast',
      },
      {
        label: 'Sverdlovsk Oblast',
      },
      {
        label: 'Omsk Oblast',
      },
      {
        label: 'Novosibirsk Oblast',
      },
      {
        label: 'Kurgan Oblast',
      },
      {
        label: 'Krasnoyarsk Krai',
      },
      {
        label: 'Khanty-Mansia',
      },
      {
        label: 'Khakasiya Republic',
      },
      {
        label: 'Kuzbass',
      },
      {
        label: 'Altai',
      },
      {
        label: 'Chelyabinsk',
      },
      {
        label: 'Altai Krai',
      },
      {
        label: 'Sakha',
      },
      {
        label: 'Primorye',
      },
      {
        label: 'Khabarovsk',
      },
      {
        label: 'Irkutsk Oblast',
      },
      {
        label: 'Jewish Autonomous Oblast',
      },
      {
        label: 'Amur Oblast',
      },
      {
        label: 'Buryatiya Republic',
      },
      {
        label: 'Sakhalin Oblast',
      },
      {
        label: 'Magadan Oblast',
      },
      {
        label: 'Kamchatka',
      },
      {
        label: 'Chukotka',
      },
      {
        label: 'Zabaykalskiy (Transbaikal) Kray',
      },
    ],
  },
  {
    label: 'Rwanda',
    countryCode: 'RW',
    countryCodeAlpha3: 'RWA',
    phone: '250',
    currency: 'RWF',
    stateProvinces: [
      {
        label: 'Eastern Province',
      },
      {
        label: 'Kigali',
      },
      {
        label: 'Northern Province',
      },
      {
        label: 'Western Province',
      },
      {
        label: 'Southern Province',
      },
    ],
  },
  {
    label: 'Saudi Arabia',
    countryCode: 'SA',
    countryCodeAlpha3: 'SAU',
    phone: '966',
    currency: 'SAR',
    stateProvinces: [
      {
        label: 'Tabuk Region',
      },
      {
        label: 'Najran Region',
      },
      {
        label: 'Mecca Region',
      },
      {
        label: 'Jazan Region',
      },
      {
        label: "Ha'il Region",
      },
      {
        label: "'Asir Region",
      },
      {
        label: 'Eastern Province',
      },
      {
        label: 'Riyadh Region',
      },
      {
        label: 'Al-Qassim Region',
      },
      {
        label: 'Medina Region',
      },
      {
        label: 'Al Jawf Region',
      },
      {
        label: 'Northern Borders Region',
      },
      {
        label: 'Al Bahah Region',
      },
    ],
  },
  {
    label: 'Solomon Islands',
    countryCode: 'SB',
    countryCodeAlpha3: 'SLB',
    phone: '677',
    currency: 'SBD',
    stateProvinces: [
      {
        label: 'Western Province',
      },
      {
        label: 'Malaita',
      },
      {
        label: 'Isabel',
      },
      {
        label: 'Guadalcanal',
      },
      {
        label: 'Central Province',
      },
      {
        label: 'Temotu',
      },
      {
        label: 'Makira',
      },
      {
        label: 'Choiseul',
      },
      {
        label: 'Rennell and Bellona',
      },
      {
        label: 'Honiara',
      },
    ],
  },
  {
    label: 'Seychelles',
    countryCode: 'SC',
    countryCodeAlpha3: 'SYC',
    phone: '248',
    currency: 'SCR',
    stateProvinces: [
      {
        label: 'Takamaka',
      },
      {
        label: 'Saint Louis',
      },
      {
        label: 'Port Glaud',
      },
      {
        label: 'Pointe Larue',
      },
      {
        label: 'Plaisance',
      },
      {
        label: 'Mont Fleuri',
      },
      {
        label: 'Mont Buxton',
      },
      {
        label: 'English River',
      },
      {
        label: 'La Digue',
      },
      {
        label: 'Grand Anse Mahe',
      },
      {
        label: 'Grand Anse Praslin',
      },
      {
        label: 'Glacis',
      },
      {
        label: 'Cascade',
      },
      {
        label: 'Bel Ombre',
      },
      {
        label: 'Bel Air',
      },
      {
        label: 'Beau Vallon',
      },
      {
        label: 'Baie Sainte Anne',
      },
      {
        label: 'Baie Lazare',
      },
      {
        label: 'Anse Royale',
      },
      {
        label: 'Anse Etoile',
      },
      {
        label: 'Anse Boileau',
      },
      {
        label: 'Anse-aux-Pins',
      },
      {
        label: 'Les Mamelles',
      },
      {
        label: 'Roche Caiman',
      },
      {
        label: 'Au Cap',
      },
      {
        label: 'Outer Islands',
      },
      {
        label: 'Ile Perseverance I',
      },
      {
        label: 'Ile Perseverance II',
      },
    ],
  },
  {
    label: 'Sudan',
    countryCode: 'SD',
    countryCodeAlpha3: 'SDN',
    phone: '249',
    currency: 'SDG',
    stateProvinces: [
      {
        label: 'Northern State',
      },
      {
        label: 'Khartoum',
      },
      {
        label: 'Red Sea',
      },
      {
        label: 'Al Jazīrah',
      },
      {
        label: 'Al Qaḑārif',
      },
      {
        label: 'White Nile',
      },
      {
        label: 'Blue Nile',
      },
      {
        label: 'Western Darfur',
      },
      {
        label: 'West Kordofan State',
      },
      {
        label: 'Southern Darfur',
      },
      {
        label: 'Southern Kordofan',
      },
      {
        label: 'Kassala',
      },
      {
        label: 'River Nile',
      },
      {
        label: 'Northern Darfur',
      },
      {
        label: 'North Kordofan',
      },
      {
        label: 'Sinnār',
      },
      {
        label: 'Eastern Darfur',
      },
      {
        label: 'Central Darfur',
      },
    ],
  },
  {
    label: 'South Sudan',
    countryCode: 'SS',
    countryCodeAlpha3: 'SSD',
    phone: '211',
    currency: 'SSP',
    stateProvinces: [
      {
        label: 'Upper Nile',
      },
      {
        label: 'Lakes',
      },
      {
        label: 'Unity',
      },
      {
        label: 'Central Equatoria',
      },
      {
        label: 'Western Equatoria',
      },
      {
        label: 'Western Bahr al Ghazal',
      },
      {
        label: 'Jonglei',
      },
      {
        label: 'Northern Bahr al Ghazal',
      },
      {
        label: 'Eastern Equatoria',
      },
      {
        label: 'Warrap',
      },
    ],
  },
  {
    label: 'Sweden',
    countryCode: 'SE',
    countryCodeAlpha3: 'SWE',
    phone: '46',
    currency: 'SEK',
    stateProvinces: [
      {
        label: 'Norrbotten',
      },
      {
        label: 'Västmanland',
      },
      {
        label: 'Västernorrland',
      },
      {
        label: 'Västerbotten',
      },
      {
        label: 'Värmland',
      },
      {
        label: 'Uppsala',
      },
      {
        label: 'Stockholm',
      },
      {
        label: 'Södermanland',
      },
      {
        label: 'Östergötland',
      },
      {
        label: 'Örebro',
      },
      {
        label: 'Kronoberg',
      },
      {
        label: 'Dalarna',
      },
      {
        label: 'Kalmar',
      },
      {
        label: 'Jönköping',
      },
      {
        label: 'Jämtland',
      },
      {
        label: 'Halland',
      },
      {
        label: 'Gotland',
      },
      {
        label: 'Gävleborg',
      },
      {
        label: 'Blekinge',
      },
      {
        label: 'Skåne',
      },
      {
        label: 'Västra Götaland',
      },
    ],
  },
  {
    label: 'Singapore',
    countryCode: 'SG',
    countryCodeAlpha3: 'SGP',
    phone: '65',
    currency: 'SGD',
    stateProvinces: null,
  },
  {
    label: 'Saint Helena',
    countryCode: 'SH',
    countryCodeAlpha3: 'SHN',
    phone: '290',
    currency: 'SHP',
    stateProvinces: [
      {
        label: 'Ascension',
      },
      {
        label: 'Tristan da Cunha',
      },
      {
        label: 'Saint Helena',
      },
    ],
  },
  {
    label: 'Slovenia',
    countryCode: 'SI',
    countryCodeAlpha3: 'SVN',
    phone: '386',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Žalec',
      },
      {
        label: 'Zagorje ob Savi',
      },
      {
        label: 'Vrhnika',
      },
      {
        label: 'Tržič',
      },
      {
        label: 'Trebnje',
      },
      {
        label: 'Trbovlje',
      },
      {
        label: 'Tolmin',
      },
      {
        label: 'Velenje',
      },
      {
        label: 'Šmarje pri Jelšah',
      },
      {
        label: 'Slovenska Konjice',
      },
      {
        label: 'Slovenska Bistrica',
      },
      {
        label: 'Slovenj Gradec',
      },
      {
        label: 'Škofja Loka',
      },
      {
        label: 'Sežana',
      },
      {
        label: 'Sevnica',
      },
      {
        label: 'Sentjur',
      },
      {
        label: 'Ribnica',
      },
      {
        label: 'Radovljica',
      },
      {
        label: 'Radlje ob Dravi',
      },
      {
        label: 'Ptuj',
      },
      {
        label: 'Postojna',
      },
      {
        label: 'Piran-Pirano',
      },
      {
        label: 'Ormož',
      },
      {
        label: 'Novo Mesto',
      },
      {
        label: 'Nova Gorica',
      },
      {
        label: 'Murska Sobota',
      },
      {
        label: 'Mozirje',
      },
      {
        label: 'Metlika',
      },
      {
        label: 'Maribor',
      },
      {
        label: 'Logatec',
      },
      {
        label: 'Ljutomer',
      },
      {
        label: 'Litija',
      },
      {
        label: 'Lenart',
      },
      {
        label: 'Laško',
      },
      {
        label: 'Krško',
      },
      {
        label: 'Kranj',
      },
      {
        label: 'Koper-Capodistria',
      },
      {
        label: 'Kočevje',
      },
      {
        label: 'Kamnik',
      },
      {
        label: 'Jesenice',
      },
      {
        label: 'Izola-Isola',
      },
      {
        label: 'Ilirska Bistrica',
      },
      {
        label: 'Idrija',
      },
      {
        label: 'Hrastnik',
      },
      {
        label: 'Grosuplje',
      },
      {
        label: 'Gornja Radgona',
      },
      {
        label: 'Dravograd',
      },
      {
        label: 'Domžale',
      },
      {
        label: 'Črnomelj',
      },
      {
        label: 'Cerknica',
      },
      {
        label: 'Celje',
      },
      {
        label: 'Brežice',
      },
      {
        label: 'Ajdovščina',
      },
      {
        label: 'Hrpelje-Kozina',
      },
      {
        label: 'Divača',
      },
      {
        label: 'Pivka',
      },
      {
        label: 'Loška Dolina',
      },
      {
        label: 'Loški Potok',
      },
      {
        label: 'Osilnica',
      },
      {
        label: 'Velike Lašče',
      },
      {
        label: 'Škofljica',
      },
      {
        label: 'Ig',
      },
      {
        label: 'Brezovica',
      },
      {
        label: 'Borovnica',
      },
      {
        label: 'Vipava',
      },
      {
        label: 'Komen',
      },
      {
        label: 'Miren-Kostanjevica',
      },
      {
        label: 'Brda',
      },
      {
        label: 'Kanal',
      },
      {
        label: 'Žiri',
      },
      {
        label: 'Cerkno',
      },
      {
        label: 'Železniki',
      },
      {
        label: 'Gorenja Vas-Poljane',
      },
      {
        label: 'Dobrova-Horjul-Polhov Gradec',
      },
      {
        label: 'Kobarid',
      },
      {
        label: 'Bovec',
      },
      {
        label: 'Bohinj',
      },
      {
        label: 'Bled',
      },
      {
        label: 'Naklo',
      },
      {
        label: 'Kranjska Gora',
      },
      {
        label: 'Preddvor',
      },
      {
        label: 'Cerklje na Gorenjskem',
      },
      {
        label: 'Šenčur',
      },
      {
        label: 'Vodice',
      },
      {
        label: 'Medvode',
      },
      {
        label: 'Mengeš',
      },
      {
        label: 'Dol pri Ljubljani',
      },
      {
        label: 'Moravče',
      },
      {
        label: 'Gornji Grad',
      },
      {
        label: 'Luče',
      },
      {
        label: 'Ravne na Koroškem',
      },
      {
        label: 'Mežica',
      },
      {
        label: 'Muta',
      },
      {
        label: 'Vuzenica',
      },
      {
        label: 'Črna na Koroškem',
      },
      {
        label: 'Ljubno',
      },
      {
        label: 'Šoštanj',
      },
      {
        label: 'Šmartno ob Paki',
      },
      {
        label: 'Lukovica',
      },
      {
        label: 'Radeče',
      },
      {
        label: 'Ivančna Gorica',
      },
      {
        label: 'Dobrepolje',
      },
      {
        label: 'Semič',
      },
      {
        label: 'Šentjernej',
      },
      {
        label: 'Škocjan',
      },
      {
        label: 'Štore',
      },
      {
        label: 'Vojnik',
      },
      {
        label: 'Vitanje',
      },
      {
        label: 'Zreče',
      },
      {
        label: 'Mislinja',
      },
      {
        label: 'Ruše',
      },
      {
        label: 'Kungota',
      },
      {
        label: 'Šentilj',
      },
      {
        label: 'Pesnica',
      },
      {
        label: 'Duplek',
      },
      {
        label: 'Rače-Fram',
      },
      {
        label: 'Starše',
      },
      {
        label: 'Kidričevo',
      },
      {
        label: 'Majšperk',
      },
      {
        label: 'Videm',
      },
      {
        label: 'Rogaška Slatina',
      },
      {
        label: 'Rogatec',
      },
      {
        label: 'Podčetrtek',
      },
      {
        label: 'Kozje',
      },
      {
        label: 'Gorišnica',
      },
      {
        label: 'Zavrč',
      },
      {
        label: 'Dornava',
      },
      {
        label: 'Juršinci',
      },
      {
        label: 'Sveti Jurij',
      },
      {
        label: 'Radenci',
      },
      {
        label: 'Puconci',
      },
      {
        label: 'Rogašovci',
      },
      {
        label: 'Kuzma',
      },
      {
        label: 'Gornji Petrovci',
      },
      {
        label: 'Moravske Toplice',
      },
      {
        label: 'Kobilje',
      },
      {
        label: 'Beltinci',
      },
      {
        label: 'Turnišče',
      },
      {
        label: 'Odranci',
      },
      {
        label: 'Črenšovci',
      },
      {
        label: 'Nazarje',
      },
      {
        label: 'Ljubljana',
      },
      {
        label: 'Žirovnica',
      },
      {
        label: 'Jezersko',
      },
      {
        label: 'Solčava',
      },
      {
        label: 'Komenda',
      },
      {
        label: 'Horjul',
      },
      {
        label: 'Šempeter-Vrtojba',
      },
      {
        label: 'Bloke',
      },
      {
        label: 'Sodražica',
      },
      {
        label: 'Trzin',
      },
      {
        label: 'Prevalje',
      },
      {
        label: 'Vransko',
      },
      {
        label: 'Tabor',
      },
      {
        label: 'Braslovče',
      },
      {
        label: 'Polzela',
      },
      {
        label: 'Prebold',
      },
      {
        label: 'Kostel',
      },
      {
        label: 'Žužemberk',
      },
      {
        label: 'Dolenjske Toplice',
      },
      {
        label: 'Mirna Peč',
      },
      {
        label: 'Bistrica ob Sotli',
      },
      {
        label: 'Dobje',
      },
      {
        label: 'Dobrna',
      },
      {
        label: 'Oplotnica',
      },
      {
        label: 'Podvelka',
      },
      {
        label: 'Ribnica na Pohorju',
      },
      {
        label: 'Lovrenc na Pohorju',
      },
      {
        label: 'Selnica ob Dravi',
      },
      {
        label: 'Hoče-Slivnica',
      },
      {
        label: 'Miklavž na Dravskem Polju',
      },
      {
        label: 'Hajdina',
      },
      {
        label: 'Žetale',
      },
      {
        label: 'Podlehnik',
      },
      {
        label: 'Markovci',
      },
      {
        label: 'Destrnik',
      },
      {
        label: 'Trnovska Vas',
      },
      {
        label: 'Sveti Andraž v Slovenskih Goricah',
      },
      {
        label: 'Cerkvenjak',
      },
      {
        label: 'Benedikt',
      },
      {
        label: 'Sveta Ana',
      },
      {
        label: 'Križevci',
      },
      {
        label: 'Veržej',
      },
      {
        label: 'Velika Polana',
      },
      {
        label: 'Lendava-Lendva',
      },
      {
        label: 'Dobrovnik-Dobronak',
      },
      {
        label: 'Tišina',
      },
      {
        label: 'Cankova',
      },
      {
        label: 'Grad',
      },
      {
        label: 'Hodoš-Hodos',
      },
      {
        label: 'Razkrižje',
      },
      {
        label: 'Šmartno pri Litiji',
      },
      {
        label: 'Šalovci',
      },
      {
        label: 'Apače',
      },
      {
        label: 'Cirkulane',
      },
      {
        label: 'Kostanjevica na Krki',
      },
      {
        label: 'Log–Dragomer',
      },
      {
        label: 'Makole',
      },
      {
        label: 'Mokronog-Trebelno',
      },
      {
        label: 'Poljčane',
      },
      {
        label: 'Rečica ob Savinji',
      },
      {
        label: 'Renče-Vogrsko',
      },
      {
        label: 'Središče ob Dravi',
      },
      {
        label: 'Straža',
      },
      {
        label: 'Sv. Trojica v Slov. Goricah',
      },
      {
        label: 'Sveti Tomaž',
      },
      {
        label: 'Šentrupert',
      },
      {
        label: 'Šmarješke Toplice',
      },
      {
        label: 'Sveti Jurij v Slovenskih Goricah',
      },
      {
        label: 'Gorje',
      },
      {
        label: 'Ankaran',
      },
      {
        label: 'Mirna',
      },
    ],
  },
  {
    label: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    countryCodeAlpha3: 'SJM',
    phone: '47',
    currency: 'NOK',
    stateProvinces: [
      {
        label: 'Jan Mayen',
      },
      {
        label: 'Svalbard',
      },
    ],
  },
  {
    label: 'Slovakia',
    countryCode: 'SK',
    countryCodeAlpha3: 'SVK',
    phone: '421',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Košický kraj',
      },
      {
        label: 'Prešovský kraj',
      },
      {
        label: 'Žilinský kraj',
      },
      {
        label: 'Banskobystrický kraj',
      },
      {
        label: 'Bratislavský Kraj',
      },
      {
        label: 'Nitriansky kraj',
      },
      {
        label: 'Trenčiansky kraj',
      },
      {
        label: 'Trnava',
      },
    ],
  },
  {
    label: 'Sierra Leone',
    countryCode: 'SL',
    countryCodeAlpha3: 'SLE',
    phone: '232',
    currency: 'SLL',
    stateProvinces: [
      {
        label: 'Western Area',
      },
      {
        label: 'Southern Province',
      },
      {
        label: 'Northern Province',
      },
      {
        label: 'Eastern Province',
      },
      {
        label: 'North West',
      },
    ],
  },
  {
    label: 'San Marino',
    countryCode: 'SM',
    countryCodeAlpha3: 'SMR',
    phone: '378',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Serravalle',
      },
      {
        label: 'Chiesanuova',
      },
      {
        label: 'San Marino',
      },
      {
        label: 'Acquaviva',
      },
      {
        label: 'Borgo Maggiore',
      },
      {
        label: 'Domagnano',
      },
      {
        label: 'Faetano',
      },
      {
        label: 'Fiorentino',
      },
      {
        label: 'Montegiardino',
      },
    ],
  },
  {
    label: 'Senegal',
    countryCode: 'SN',
    countryCodeAlpha3: 'SEN',
    phone: '221',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Ziguinchor',
      },
      {
        label: 'Thiès',
      },
      {
        label: 'Tambacounda',
      },
      {
        label: 'Saint-Louis',
      },
      {
        label: 'Matam',
      },
      {
        label: 'Louga',
      },
      {
        label: 'Kolda',
      },
      {
        label: 'Kaolack',
      },
      {
        label: 'Fatick',
      },
      {
        label: 'Diourbel',
      },
      {
        label: 'Dakar',
      },
      {
        label: 'Kaffrine',
      },
      {
        label: 'Kédougou',
      },
      {
        label: 'Sédhiou',
      },
    ],
  },
  {
    label: 'Somalia',
    countryCode: 'SO',
    countryCodeAlpha3: 'SOM',
    phone: '252',
    currency: 'SOS',
    stateProvinces: [
      {
        label: 'Woqooyi Galbeed',
      },
      {
        label: 'Togdheer',
      },
      {
        label: 'Lower Shabeelle',
      },
      {
        label: 'Middle Shabele',
      },
      {
        label: 'Sanaag',
      },
      {
        label: 'Nugaal',
      },
      {
        label: 'Mudug',
      },
      {
        label: 'Lower Juba',
      },
      {
        label: 'Middle Juba',
      },
      {
        label: 'Hiiraan',
      },
      {
        label: 'Gedo',
      },
      {
        label: 'Galguduud',
      },
      {
        label: 'Bay',
      },
      {
        label: 'Bari',
      },
      {
        label: 'Banaadir',
      },
      {
        label: 'Bakool',
      },
      {
        label: 'Awdal',
      },
      {
        label: 'Sool',
      },
    ],
  },
  {
    label: 'Surilabel',
    countryCode: 'SR',
    countryCodeAlpha3: 'SUR',
    phone: '597',
    currency: 'SRD',
    stateProvinces: [
      {
        label: 'Wanica',
      },
      {
        label: 'Sipaliwini',
      },
      {
        label: 'Saramacca',
      },
      {
        label: 'Paramaribo',
      },
      {
        label: 'Para',
      },
      {
        label: 'Nickerie',
      },
      {
        label: 'Marowijne',
      },
      {
        label: 'Coronie',
      },
      {
        label: 'Commewijne',
      },
      {
        label: 'Brokopondo',
      },
    ],
  },
  {
    label: 'Sao Tome and Principe',
    countryCode: 'ST',
    countryCodeAlpha3: 'STP',
    phone: '239',
    currency: 'STN',
    stateProvinces: [
      {
        label: 'São Tomé Island',
      },
      {
        label: 'Príncipe',
      },
    ],
  },
  {
    label: 'El Salvador',
    countryCode: 'SV',
    countryCodeAlpha3: 'SLV',
    phone: '503',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Usulután',
      },
      {
        label: 'Sonsonate',
      },
      {
        label: 'San Vicente',
      },
      {
        label: 'Santa Ana',
      },
      {
        label: 'San Salvador',
      },
      {
        label: 'San Miguel',
      },
      {
        label: 'Morazán',
      },
      {
        label: 'La Unión',
      },
      {
        label: 'La Paz',
      },
      {
        label: 'La Libertad',
      },
      {
        label: 'Cuscatlán',
      },
      {
        label: 'Chalatenango',
      },
      {
        label: 'Cabañas',
      },
      {
        label: 'Ahuachapán',
      },
    ],
  },
  {
    label: 'Sint Maarten',
    countryCode: 'SX',
    countryCodeAlpha3: 'SXM',
    phone: '599',
    currency: 'ANG',
    stateProvinces: null,
  },
  {
    label: 'Syria',
    countryCode: 'SY',
    countryCodeAlpha3: 'SYR',
    phone: '963',
    currency: 'SYP',
    stateProvinces: [
      {
        label: 'Tartus',
      },
      {
        label: 'Dimashq',
      },
      {
        label: 'Idlib',
      },
      {
        label: 'Homs',
      },
      {
        label: 'Hama',
      },
      {
        label: 'Aleppo',
      },
      {
        label: 'Rif-dimashq',
      },
      {
        label: 'Deir ez-Zor',
      },
      {
        label: 'Daraa',
      },
      {
        label: 'As-Suwayda',
      },
      {
        label: 'Ar-Raqqah',
      },
      {
        label: 'Quneitra',
      },
      {
        label: 'Latakia',
      },
      {
        label: 'Al-Hasakah',
      },
    ],
  },
  {
    label: 'Eswatini',
    countryCode: 'SZ',
    countryCodeAlpha3: 'SWZ',
    phone: '268',
    currency: 'SZL',
    stateProvinces: [
      {
        label: 'Shiselweni',
      },
      {
        label: 'Manzini',
      },
      {
        label: 'Lubombo',
      },
      {
        label: 'Hhohho',
      },
    ],
  },
  {
    label: 'Turks and Caicos Islands',
    countryCode: 'TC',
    countryCodeAlpha3: 'TCA',
    phone: '+1-649',
    currency: 'USD',
    stateProvinces: null,
  },
  {
    label: 'Chad',
    countryCode: 'TD',
    countryCodeAlpha3: 'TCD',
    phone: '235',
    currency: 'XAF',
    stateProvinces: [
      {
        label: 'Salamat',
      },
      {
        label: 'Ouadaï',
      },
      {
        label: 'Wadi Fira',
      },
      {
        label: 'Tandjilé',
      },
      {
        label: 'Moyen-Chari',
      },
      {
        label: 'Mayo-Kebbi Est',
      },
      {
        label: 'Logone Oriental',
      },
      {
        label: 'Logone Occidental',
      },
      {
        label: 'Lac',
      },
      {
        label: 'Kanem',
      },
      {
        label: 'Guéra',
      },
      {
        label: 'Chari-Baguirmi',
      },
      {
        label: 'Batha',
      },
      {
        label: 'Borkou',
      },
      {
        label: 'Hadjer-Lamis',
      },
      {
        label: 'Mandoul',
      },
      {
        label: 'Mayo-Kebbi Ouest',
      },
      {
        label: 'N’Djaména',
      },
      {
        label: 'Barh el Gazel',
      },
      {
        label: 'Sila',
      },
      {
        label: 'Tibesti',
      },
      {
        label: 'Ennedi-Ouest',
      },
      {
        label: 'Ennedi-Est',
      },
    ],
  },
  {
    label: 'French Southern Territories',
    countryCode: 'TF',
    countryCodeAlpha3: 'ATF',
    phone: '',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Crozet',
      },
      {
        label: 'Kerguelen',
      },
      {
        label: 'Saint-Paul-et-Amsterdam',
      },
      {
        label: 'Îles Éparses',
      },
      {
        label: 'Terre-Adélie',
      },
    ],
  },
  {
    label: 'Togo',
    countryCode: 'TG',
    countryCodeAlpha3: 'TGO',
    phone: '228',
    currency: 'XOF',
    stateProvinces: [
      {
        label: 'Savanes',
      },
      {
        label: 'Plateaux',
      },
      {
        label: 'Maritime',
      },
      {
        label: 'Centrale',
      },
      {
        label: 'Kara',
      },
    ],
  },
  {
    label: 'Thailand',
    countryCode: 'TH',
    countryCodeAlpha3: 'THA',
    phone: '66',
    currency: 'THB',
    stateProvinces: [
      {
        label: 'Uthai Thani',
      },
      {
        label: 'Trang',
      },
      {
        label: 'Tak',
      },
      {
        label: 'Surat Thani',
      },
      {
        label: 'Sukhothai',
      },
      {
        label: 'Ratchaburi',
      },
      {
        label: 'Ranong',
      },
      {
        label: 'Prachuap Khiri Khan',
      },
      {
        label: 'Phuket',
      },
      {
        label: 'Phetchaburi',
      },
      {
        label: 'Phang Nga',
      },
      {
        label: 'Mae Hong Son',
      },
      {
        label: 'Lamphun',
      },
      {
        label: 'Lampang',
      },
      {
        label: 'Krabi',
      },
      {
        label: 'Kanchanaburi',
      },
      {
        label: 'Kamphaeng Phet',
      },
      {
        label: 'Chumphon',
      },
      {
        label: 'Chiang Rai',
      },
      {
        label: 'Chiang Mai',
      },
      {
        label: 'Yasothon',
      },
      {
        label: 'Yala',
      },
      {
        label: 'Uttaradit',
      },
      {
        label: 'Trat',
      },
      {
        label: 'Surin',
      },
      {
        label: 'Suphanburi',
      },
      {
        label: 'Songkhla',
      },
      {
        label: 'Si Sa Ket',
      },
      {
        label: 'Sing Buri',
      },
      {
        label: 'Satun',
      },
      {
        label: 'Saraburi',
      },
      {
        label: 'Samut Songkhram',
      },
      {
        label: 'Samut Sakhon',
      },
      {
        label: 'Samut Prakan',
      },
      {
        label: 'Sakon Nakhon',
      },
      {
        label: 'Roi Et',
      },
      {
        label: 'Rayong',
      },
      {
        label: 'Phra Nakhon Si Ayutthaya',
      },
      {
        label: 'Phrae',
      },
      {
        label: 'Phitsanulok',
      },
      {
        label: 'Phichit',
      },
      {
        label: 'Phetchabun',
      },
      {
        label: 'Phayao',
      },
      {
        label: 'Phatthalung',
      },
      {
        label: 'Pattani',
      },
      {
        label: 'Pathum Thani',
      },
      {
        label: 'Nonthaburi',
      },
      {
        label: 'Nong Khai',
      },
      {
        label: 'Narathiwat',
      },
      {
        label: 'Nan',
      },
      {
        label: 'Nakhon Si Thammarat',
      },
      {
        label: 'Nakhon Sawan',
      },
      {
        label: 'Nakhon Ratchasima',
      },
      {
        label: 'Nakhon Phanom',
      },
      {
        label: 'Nakhon Pathom',
      },
      {
        label: 'Nakhon Nayok',
      },
      {
        label: 'Mukdahan',
      },
      {
        label: 'Maha Sarakham',
      },
      {
        label: 'Lopburi',
      },
      {
        label: 'Loei',
      },
      {
        label: 'Bangkok',
      },
      {
        label: 'Khon Kaen',
      },
      {
        label: 'Kalasin',
      },
      {
        label: 'Chon Buri',
      },
      {
        label: 'Chanthaburi',
      },
      {
        label: 'Chaiyaphum',
      },
      {
        label: 'Chai Nat',
      },
      {
        label: 'Chachoengsao',
      },
      {
        label: 'Buriram',
      },
      {
        label: 'Ang Thong',
      },
      {
        label: 'Udon Thani',
      },
      {
        label: 'Prachin Buri',
      },
      {
        label: 'Ubon Ratchathani',
      },
      {
        label: 'Amnat Charoen',
      },
      {
        label: 'Nong Bua Lam Phu',
      },
      {
        label: 'Sa Kaeo',
      },
      {
        label: 'Bueng Kan',
      },
    ],
  },
  {
    label: 'Tajikistan',
    countryCode: 'TJ',
    countryCodeAlpha3: 'TJK',
    phone: '992',
    currency: 'TJS',
    stateProvinces: [
      {
        label: 'Sughd',
      },
      {
        label: 'Gorno-Badakhshan',
      },
      {
        label: 'Khatlon',
      },
      {
        label: 'Republican Subordination',
      },
      {
        label: 'Dushanbe',
      },
    ],
  },
  {
    label: 'Tokelau',
    countryCode: 'TK',
    countryCodeAlpha3: 'TKL',
    phone: '690',
    currency: 'NZD',
    stateProvinces: [
      {
        label: 'Nukunonu',
      },
      {
        label: 'Fakaofo',
      },
      {
        label: 'Atafu',
      },
    ],
  },
  {
    label: 'Timor Leste',
    countryCode: 'TL',
    countryCodeAlpha3: 'TLS',
    phone: '670',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Viqueque',
      },
      {
        label: 'Manufahi',
      },
      {
        label: 'Manatuto',
      },
      {
        label: 'Liquiçá',
      },
      {
        label: 'Lautém',
      },
      {
        label: 'Cova Lima',
      },
      {
        label: 'Ermera',
      },
      {
        label: 'Díli',
      },
      {
        label: 'Bobonaro',
      },
      {
        label: 'Baucau',
      },
      {
        label: 'Oecusse',
      },
      {
        label: 'Ainaro',
      },
      {
        label: 'Aileu',
      },
    ],
  },
  {
    label: 'Turkmenistan',
    countryCode: 'TM',
    countryCodeAlpha3: 'TKM',
    phone: '993',
    currency: 'TMT',
    stateProvinces: [
      {
        label: 'Balkan',
      },
      {
        label: 'Ahal',
      },
      {
        label: 'Ashgabat',
      },
      {
        label: 'Daşoguz',
      },
      {
        label: 'Mary',
      },
      {
        label: 'Lebap',
      },
    ],
  },
  {
    label: 'Tunisia',
    countryCode: 'TN',
    countryCodeAlpha3: 'TUN',
    phone: '216',
    currency: 'TND',
    stateProvinces: [
      {
        label: 'Zaghwān',
      },
      {
        label: 'Tūnis',
      },
      {
        label: 'Tawzar',
      },
      {
        label: 'Tataouine',
      },
      {
        label: 'Sūsah',
      },
      {
        label: 'Silyānah',
      },
      {
        label: 'Sīdī Bū Zayd',
      },
      {
        label: 'Şafāqis',
      },
      {
        label: 'Qibilī',
      },
      {
        label: 'Gafsa',
      },
      {
        label: 'Qābis',
      },
      {
        label: 'Nābul',
      },
      {
        label: 'Madanīn',
      },
      {
        label: 'Jundūbah',
      },
      {
        label: 'Bin ‘Arūs',
      },
      {
        label: 'Banzart',
      },
      {
        label: 'Bājah',
      },
      {
        label: 'Ariana',
      },
      {
        label: 'Kairouan',
      },
      {
        label: 'Al Qaşrayn',
      },
      {
        label: 'Al Munastīr',
      },
      {
        label: 'Al Mahdīyah',
      },
      {
        label: 'Kef',
      },
      {
        label: 'Manouba',
      },
    ],
  },
  {
    label: 'Tonga',
    countryCode: 'TO',
    countryCodeAlpha3: 'TON',
    phone: '676',
    currency: 'TOP',
    stateProvinces: [
      {
        label: 'Vava‘u',
      },
      {
        label: 'Tongatapu',
      },
      {
        label: 'Ha‘apai',
      },
      {
        label: 'ʻEua',
      },
      {
        label: 'Niuas',
      },
    ],
  },
  {
    label: 'Turkey',
    countryCode: 'TR',
    countryCodeAlpha3: 'TUR',
    phone: '90',
    currency: 'TRY',
    stateProvinces: [
      {
        label: 'Yozgat',
      },
      {
        label: 'Van',
      },
      {
        label: 'Uşak',
      },
      {
        label: 'Şanlıurfa',
      },
      {
        label: 'Tunceli',
      },
      {
        label: 'Sivas',
      },
      {
        label: 'Siirt',
      },
      {
        label: 'Niğde',
      },
      {
        label: 'Nevşehir',
      },
      {
        label: 'Muş',
      },
      {
        label: 'Muğla',
      },
      {
        label: 'Mardin',
      },
      {
        label: 'Manisa',
      },
      {
        label: 'Malatya',
      },
      {
        label: 'Kütahya',
      },
      {
        label: 'Konya',
      },
      {
        label: 'Kırşehir',
      },
      {
        label: 'Kayseri',
      },
      {
        label: 'Kahramanmaraş',
      },
      {
        label: 'İzmir',
      },
      {
        label: 'Isparta',
      },
      {
        label: 'Mersin',
      },
      {
        label: 'Hatay',
      },
      {
        label: 'Hakkâri',
      },
      {
        label: 'Gaziantep',
      },
      {
        label: 'Eskişehir',
      },
      {
        label: 'Erzurum',
      },
      {
        label: 'Erzincan',
      },
      {
        label: 'Elazığ',
      },
      {
        label: 'Diyarbakır',
      },
      {
        label: 'Denizli',
      },
      {
        label: 'Burdur',
      },
      {
        label: 'Bitlis',
      },
      {
        label: 'Bingöl',
      },
      {
        label: 'Bilecik',
      },
      {
        label: 'Balıkesir',
      },
      {
        label: 'Aydın',
      },
      {
        label: 'Antalya',
      },
      {
        label: 'Ankara',
      },
      {
        label: 'Ağrı',
      },
      {
        label: 'Afyonkarahisar',
      },
      {
        label: 'Adıyaman',
      },
      {
        label: 'Adana',
      },
      {
        label: 'Osmaniye',
      },
      {
        label: 'Iğdır',
      },
      {
        label: 'Aksaray',
      },
      {
        label: 'Batman',
      },
      {
        label: 'Karaman',
      },
      {
        label: 'Kırıkkale',
      },
      {
        label: 'Şırnak',
      },
      {
        label: 'Kilis',
      },
      {
        label: 'Zonguldak',
      },
      {
        label: 'Trabzon',
      },
      {
        label: 'Tokat',
      },
      {
        label: 'Tekirdağ',
      },
      {
        label: 'Sinop',
      },
      {
        label: 'Samsun',
      },
      {
        label: 'Sakarya',
      },
      {
        label: 'Rize',
      },
      {
        label: 'Ordu',
      },
      {
        label: 'Kocaeli',
      },
      {
        label: 'Kırklareli',
      },
      {
        label: 'Kastamonu',
      },
      {
        label: 'Kars',
      },
      {
        label: 'Istanbul',
      },
      {
        label: 'Gümüşhane',
      },
      {
        label: 'Giresun',
      },
      {
        label: 'Edirne',
      },
      {
        label: 'Çorum',
      },
      {
        label: 'Çankırı',
      },
      {
        label: 'Canakkale',
      },
      {
        label: 'Bursa',
      },
      {
        label: 'Bolu',
      },
      {
        label: 'Artvin',
      },
      {
        label: 'Amasya',
      },
      {
        label: 'Bartın',
      },
      {
        label: 'Karabük',
      },
      {
        label: 'Yalova',
      },
      {
        label: 'Ardahan',
      },
      {
        label: 'Bayburt',
      },
      {
        label: 'Düzce',
      },
    ],
  },
  {
    label: 'Trinidad and Tobago',
    countryCode: 'TT',
    countryCodeAlpha3: 'TTO',
    phone: '+1-868',
    currency: 'TTD',
    stateProvinces: [
      {
        label: 'Tobago',
      },
      {
        label: 'San Fernando',
      },
      {
        label: 'Port of Spain',
      },
      {
        label: 'Mayaro',
      },
      {
        label: 'Borough of Arima',
      },
      {
        label: 'Chaguanas',
      },
      {
        label: 'Couva-Tabaquite-Talparo',
      },
      {
        label: 'Diego Martin',
      },
      {
        label: 'Penal/Debe',
      },
      {
        label: 'Princes Town',
      },
      {
        label: 'Point Fortin',
      },
      {
        label: 'Sangre Grande',
      },
      {
        label: 'Siparia',
      },
      {
        label: 'San Juan/Laventille',
      },
      {
        label: 'Tunapuna/Piarco',
      },
    ],
  },
  {
    label: 'Tuvalu',
    countryCode: 'TV',
    countryCodeAlpha3: 'TUV',
    phone: '688',
    currency: 'AUD',
    stateProvinces: [
      {
        label: 'Nui',
      },
      {
        label: 'Nanumea',
      },
      {
        label: 'Funafuti',
      },
      {
        label: 'Niutao',
      },
      {
        label: 'Nanumanga',
      },
      {
        label: 'Vaitupu',
      },
      {
        label: 'Nukufetau',
      },
      {
        label: 'Nukulaelae',
      },
    ],
  },
  {
    label: 'Taiwan',
    countryCode: 'TW',
    countryCodeAlpha3: 'TWN',
    phone: '886',
    currency: 'TWD',
    stateProvinces: [
      {
        label: 'Fukien',
      },
      {
        label: 'Takao',
      },
      {
        label: 'Taipei',
      },
      {
        label: 'Taiwan',
      },
    ],
  },
  {
    label: 'Tanzania',
    countryCode: 'TZ',
    countryCodeAlpha3: 'TZA',
    phone: '255',
    currency: 'TZS',
    stateProvinces: [
      {
        label: 'Kagera',
      },
      {
        label: 'Zanzibar Urban/West',
      },
      {
        label: 'Zanzibar North',
      },
      {
        label: 'Zanzibar Central/South',
      },
      {
        label: 'Tanga',
      },
      {
        label: 'Tabora',
      },
      {
        label: 'Singida',
      },
      {
        label: 'Shinyanga',
      },
      {
        label: 'Rukwa',
      },
      {
        label: 'Pwani',
      },
      {
        label: 'Pemba South',
      },
      {
        label: 'Pemba North',
      },
      {
        label: 'Mwanza',
      },
      {
        label: 'Morogoro',
      },
      {
        label: 'Mbeya',
      },
      {
        label: 'Mara',
      },
      {
        label: 'Lindi',
      },
      {
        label: 'Kilimanjaro',
      },
      {
        label: 'Kigoma',
      },
      {
        label: 'Iringa',
      },
      {
        label: 'Dodoma',
      },
      {
        label: 'Dar es Salaam',
      },
      {
        label: 'Arusha',
      },
      {
        label: 'Manyara',
      },
      {
        label: 'Ruvuma',
      },
      {
        label: 'Mtwara',
      },
      {
        label: 'Simiyu',
      },
      {
        label: 'Geita',
      },
      {
        label: 'Katavi',
      },
      {
        label: 'Njombe',
      },
      {
        label: 'Songwe',
      },
    ],
  },
  {
    label: 'Ukraine',
    countryCode: 'UA',
    countryCodeAlpha3: 'UKR',
    phone: '380',
    currency: 'UAH',
    stateProvinces: [
      {
        label: 'Zhytomyr',
      },
      {
        label: 'Zaporizhzhia',
      },
      {
        label: 'Transcarpathia',
      },
      {
        label: 'Volyn',
      },
      {
        label: 'Vinnytsia',
      },
      {
        label: 'Ternopil',
      },
      {
        label: 'Sumy',
      },
      {
        label: 'Sevastopol City',
      },
      {
        label: 'Rivne',
      },
      {
        label: 'Poltava',
      },
      {
        label: 'Odessa',
      },
      {
        label: 'Mykolaiv',
      },
      {
        label: 'Lviv',
      },
      {
        label: 'Luhansk',
      },
      {
        label: 'Kiev',
      },
      {
        label: 'Kyiv City',
      },
      {
        label: 'Crimea',
      },
      {
        label: 'Kirovohrad',
      },
      {
        label: 'Khmelnytskyi',
      },
      {
        label: 'Kherson',
      },
      {
        label: 'Kharkiv',
      },
      {
        label: 'Ivano-Frankivsk',
      },
      {
        label: 'Donetsk',
      },
      {
        label: 'Dnipropetrovsk',
      },
      {
        label: 'Chernivtsi',
      },
      {
        label: 'Chernihiv',
      },
      {
        label: 'Cherkasy',
      },
    ],
  },
  {
    label: 'Uganda',
    countryCode: 'UG',
    countryCodeAlpha3: 'UGA',
    phone: '256',
    currency: 'UGX',
    stateProvinces: [
      {
        label: 'Central Region',
      },
      {
        label: 'Eastern Region',
      },
      {
        label: 'Northern Region',
      },
      {
        label: 'Western Region',
      },
    ],
  },
  {
    label: 'United States Minor Outlying Islands',
    countryCode: 'UM',
    countryCodeAlpha3: 'UMI',
    phone: '1',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Wake Island',
      },
      {
        label: 'Navassa Island',
      },
      {
        label: 'Baker Island',
      },
      {
        label: 'Howland Island',
      },
      {
        label: 'Jarvis Island',
      },
      {
        label: 'Johnston Atoll',
      },
      {
        label: 'Kingman Reef',
      },
      {
        label: 'Midway Islands',
      },
      {
        label: 'Palmyra Atoll',
      },
    ],
  },
  {
    label: 'United States',
    countryCode: 'US',
    countryCodeAlpha3: 'USA',
    phone: '1',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Arkansas',
      },
      {
        label: 'Washington, D.C.',
      },
      {
        label: 'Delaware',
      },
      {
        label: 'Florida',
      },
      {
        label: 'Georgia',
      },
      {
        label: 'Kansas',
      },
      {
        label: 'Louisiana',
      },
      {
        label: 'Maryland',
      },
      {
        label: 'Missouri',
      },
      {
        label: 'Mississippi',
      },
      {
        label: 'North Carolina',
      },
      {
        label: 'Oklahoma',
      },
      {
        label: 'South Carolina',
      },
      {
        label: 'Tennessee',
      },
      {
        label: 'Texas',
      },
      {
        label: 'West Virginia',
      },
      {
        label: 'Alabama',
      },
      {
        label: 'Connecticut',
      },
      {
        label: 'Iowa',
      },
      {
        label: 'Illinois',
      },
      {
        label: 'Indiana',
      },
      {
        label: 'Maine',
      },
      {
        label: 'Michigan',
      },
      {
        label: 'Minnesota',
      },
      {
        label: 'Nebraska',
      },
      {
        label: 'New Hampshire',
      },
      {
        label: 'New Jersey',
      },
      {
        label: 'New York',
      },
      {
        label: 'Ohio',
      },
      {
        label: 'Rhode Island',
      },
      {
        label: 'Vermont',
      },
      {
        label: 'Wisconsin',
      },
      {
        label: 'California',
      },
      {
        label: 'Colorado',
      },
      {
        label: 'New Mexico',
      },
      {
        label: 'Nevada',
      },
      {
        label: 'Utah',
      },
      {
        label: 'Arizona',
      },
      {
        label: 'Idaho',
      },
      {
        label: 'Montana',
      },
      {
        label: 'North Dakota',
      },
      {
        label: 'Oregon',
      },
      {
        label: 'South Dakota',
      },
      {
        label: 'Washington',
      },
      {
        label: 'Wyoming',
      },
      {
        label: 'Hawaii',
      },
      {
        label: 'Alaska',
      },
      {
        label: 'Kentucky',
      },
      {
        label: 'Massachusetts',
      },
      {
        label: 'Pennsylvania',
      },
      {
        label: 'Virginia',
      },
    ],
  },
  {
    label: 'Uruguay',
    countryCode: 'UY',
    countryCodeAlpha3: 'URY',
    phone: '598',
    currency: 'UYU',
    stateProvinces: [
      {
        label: 'Treinta y Tres',
      },
      {
        label: 'Tacuarembó',
      },
      {
        label: 'Soriano',
      },
      {
        label: 'San José',
      },
      {
        label: 'Salto',
      },
      {
        label: 'Rocha',
      },
      {
        label: 'Rivera',
      },
      {
        label: 'Río Negro',
      },
      {
        label: 'Paysandú',
      },
      {
        label: 'Montevideo',
      },
      {
        label: 'Maldonado',
      },
      {
        label: 'Lavalleja',
      },
      {
        label: 'Florida',
      },
      {
        label: 'Flores',
      },
      {
        label: 'Durazno',
      },
      {
        label: 'Colonia',
      },
      {
        label: 'Cerro Largo',
      },
      {
        label: 'Canelones',
      },
      {
        label: 'Artigas',
      },
    ],
  },
  {
    label: 'Uzbekistan',
    countryCode: 'UZ',
    countryCodeAlpha3: 'UZB',
    phone: '998',
    currency: 'UZS',
    stateProvinces: [
      {
        label: 'Karakalpakstan',
      },
      {
        label: 'Surxondaryo',
      },
      {
        label: 'Samarqand',
      },
      {
        label: 'Qashqadaryo',
      },
      {
        label: 'Bukhara',
      },
      {
        label: 'Toshkent',
      },
      {
        label: 'Tashkent',
      },
      {
        label: 'Sirdaryo Region',
      },
      {
        label: 'Navoiy',
      },
      {
        label: 'Namangan',
      },
      {
        label: 'Xorazm',
      },
      {
        label: 'Jizzax',
      },
      {
        label: 'Fergana',
      },
      {
        label: 'Andijon',
      },
    ],
  },
  {
    label: 'Vatican',
    countryCode: 'VA',
    countryCodeAlpha3: 'VAT',
    phone: '379',
    currency: 'EUR',
    stateProvinces: null,
  },
  {
    label: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    countryCodeAlpha3: 'VCT',
    phone: '+1-784',
    currency: 'XCD',
    stateProvinces: [
      {
        label: 'Saint Patrick',
      },
      {
        label: 'Saint George',
      },
      {
        label: 'Saint David',
      },
      {
        label: 'Saint Andrew',
      },
      {
        label: 'Grenadines',
      },
      {
        label: 'Charlotte',
      },
    ],
  },
  {
    label: 'Venezuela',
    countryCode: 'VE',
    countryCodeAlpha3: 'VEN',
    phone: '58',
    currency: 'VES',
    stateProvinces: [
      {
        label: 'Zulia',
      },
      {
        label: 'Yaracuy',
      },
      {
        label: 'Trujillo',
      },
      {
        label: 'Táchira',
      },
      {
        label: 'Sucre',
      },
      {
        label: 'Portuguesa',
      },
      {
        label: 'Nueva Esparta',
      },
      {
        label: 'Monagas',
      },
      {
        label: 'Miranda',
      },
      {
        label: 'Mérida',
      },
      {
        label: 'Lara',
      },
      {
        label: 'Guárico',
      },
      {
        label: 'Dependencias Federales',
      },
      {
        label: 'Distrito Federal',
      },
      {
        label: 'Falcón',
      },
      {
        label: 'Delta Amacuro',
      },
      {
        label: 'Cojedes',
      },
      {
        label: 'Carabobo',
      },
      {
        label: 'Bolívar',
      },
      {
        label: 'Barinas',
      },
      {
        label: 'Aragua',
      },
      {
        label: 'Apure',
      },
      {
        label: 'Anzoátegui',
      },
      {
        label: 'Amazonas',
      },
      {
        label: 'Vargas',
      },
    ],
  },
  {
    label: 'British Virgin Islands',
    countryCode: 'VG',
    countryCodeAlpha3: 'VGB',
    phone: '+1-284',
    currency: 'USD',
    stateProvinces: null,
  },
  {
    label: 'U.S. Virgin Islands',
    countryCode: 'VI',
    countryCodeAlpha3: 'VIR',
    phone: '+1-340',
    currency: 'USD',
    stateProvinces: [
      {
        label: 'Saint Croix Island',
      },
      {
        label: 'Saint John Island',
      },
      {
        label: 'Saint Thomas Island',
      },
    ],
  },
  {
    label: 'Vietnam',
    countryCode: 'VN',
    countryCodeAlpha3: 'VNM',
    phone: '84',
    currency: 'VND',
    stateProvinces: [
      {
        label: 'Nghệ An',
      },
      {
        label: 'Ninh Bình',
      },
      {
        label: 'Ninh Thuận',
      },
      {
        label: 'Sóc Trăng',
      },
      {
        label: 'Trà Vinh',
      },
      {
        label: 'Tuyên Quang',
      },
      {
        label: 'Vĩnh Long',
      },
      {
        label: 'Yên Bái',
      },
      {
        label: 'Lào Cai',
      },
      {
        label: 'Tiền Giang',
      },
      {
        label: 'Thừa Thiên-Huế',
      },
      {
        label: 'Kon Tum',
      },
      {
        label: 'Thanh Hóa',
      },
      {
        label: 'Thái Bình',
      },
      {
        label: 'Tây Ninh Province',
      },
      {
        label: 'Sơn La',
      },
      {
        label: 'Quảng Trị',
      },
      {
        label: 'Quảng Ninh',
      },
      {
        label: 'Quảng Ngãi Province',
      },
      {
        label: 'Quảng Bình',
      },
      {
        label: 'Phú Yên',
      },
      {
        label: 'Hòa Bình',
      },
      {
        label: 'Long An',
      },
      {
        label: 'Lạng Sơn',
      },
      {
        label: 'Lâm Đồng',
      },
      {
        label: 'Lai Châu',
      },
      {
        label: 'Kiến Giang',
      },
      {
        label: 'Khánh Hòa',
      },
      {
        label: 'Ho Chi Minh',
      },
      {
        label: 'Hà Tĩnh',
      },
      {
        label: 'Hà Giang',
      },
      {
        label: 'Gia Lai',
      },
      {
        label: 'Hanoi',
      },
      {
        label: 'Can Tho',
      },
      {
        label: 'Haiphong',
      },
      {
        label: 'Bình Thuận',
      },
      {
        label: 'Đồng Tháp',
      },
      {
        label: 'Đồng Nai',
      },
      {
        label: 'Đắk Lắk',
      },
      {
        label: 'Bà Rịa-Vũng Tàu',
      },
      {
        label: 'Cao Bằng',
      },
      {
        label: 'Bình Định',
      },
      {
        label: 'Bến Tre',
      },
      {
        label: 'An Giang',
      },
      {
        label: 'Ðắk Nông',
      },
      {
        label: 'Ðiện Biên',
      },
      {
        label: 'Bắc Ninh',
      },
      {
        label: 'Bắc Giang',
      },
      {
        label: 'Da Nang',
      },
      {
        label: 'Bình Dương',
      },
      {
        label: 'Bình Phước',
      },
      {
        label: 'Thái Nguyên',
      },
      {
        label: 'Quảng Nam',
      },
      {
        label: 'Phú Thọ',
      },
      {
        label: 'Nam Định',
      },
      {
        label: 'Hà Nam',
      },
      {
        label: 'Bắc Kạn',
      },
      {
        label: 'Bạc Liêu',
      },
      {
        label: 'Cà Mau',
      },
      {
        label: 'Hải Dương',
      },
      {
        label: 'Hưng Yên',
      },
      {
        label: 'Vĩnh Phúc',
      },
      {
        label: 'Hậu Giang',
      },
    ],
  },
  {
    label: 'Vanuatu',
    countryCode: 'VU',
    countryCodeAlpha3: 'VUT',
    phone: '678',
    currency: 'VUV',
    stateProvinces: [
      {
        label: 'Tafea',
      },
      {
        label: 'Sanma',
      },
      {
        label: 'Torba',
      },
      {
        label: 'Malampa',
      },
      {
        label: 'Penama',
      },
      {
        label: 'Shefa',
      },
    ],
  },
  {
    label: 'Wallis and Futuna',
    countryCode: 'WF',
    countryCodeAlpha3: 'WLF',
    phone: '681',
    currency: 'XPF',
    stateProvinces: [
      {
        label: 'Uvea',
      },
      {
        label: 'Sigave',
      },
      {
        label: 'Alo',
      },
    ],
  },
  {
    label: 'Samoa',
    countryCode: 'WS',
    countryCodeAlpha3: 'WSM',
    phone: '685',
    currency: 'WST',
    stateProvinces: [
      {
        label: 'Vaisigano',
      },
      {
        label: 'Va‘a-o-Fonoti',
      },
      {
        label: 'Tuamasaga',
      },
      {
        label: 'Satupa‘itea',
      },
      {
        label: 'Palauli',
      },
      {
        label: 'Gagaifomauga',
      },
      {
        label: 'Gaga‘emauga',
      },
      {
        label: 'Fa‘asaleleaga',
      },
      {
        label: 'Atua',
      },
      {
        label: 'Aiga-i-le-Tai',
      },
      {
        label: "A'ana",
      },
    ],
  },
  {
    label: 'Yemen',
    countryCode: 'YE',
    countryCodeAlpha3: 'YEM',
    phone: '967',
    currency: 'YER',
    stateProvinces: [
      {
        label: 'Ta‘izz',
      },
      {
        label: 'Shabwah',
      },
      {
        label: 'Sanaa',
      },
      {
        label: 'Şa‘dah',
      },
      {
        label: 'Raymah',
      },
      {
        label: 'Ma’rib',
      },
      {
        label: 'Al Maḩwīt',
      },
      {
        label: 'Al Jawf',
      },
      {
        label: 'Muhafazat Hadramaout',
      },
      {
        label: 'Dhamār',
      },
      {
        label: 'Al Mahrah',
      },
      {
        label: 'Al Hudaydah',
      },
      {
        label: 'Al Bayda',
      },
      {
        label: 'Aden',
      },
      {
        label: 'Abyan',
      },
      {
        label: 'Aḑ Ḑāli‘',
      },
      {
        label: 'Omran',
      },
      {
        label: 'Ḩajjah',
      },
      {
        label: 'Ibb',
      },
      {
        label: 'Laḩij',
      },
      {
        label: 'Amanat Alasimah',
      },
      {
        label: 'Soqatra',
      },
    ],
  },
  {
    label: 'Mayotte',
    countryCode: 'YT',
    countryCodeAlpha3: 'MYT',
    phone: '262',
    currency: 'EUR',
    stateProvinces: [
      {
        label: 'Acoua',
      },
      {
        label: 'Bandraboua',
      },
      {
        label: 'Bandrele',
      },
      {
        label: 'Bouéni',
      },
      {
        label: 'Chiconi',
      },
      {
        label: 'Chirongui',
      },
      {
        label: 'Dembeni',
      },
      {
        label: 'Dzaoudzi',
      },
      {
        label: 'Kani-Kéli',
      },
      {
        label: 'Koungou',
      },
      {
        label: 'Mamoudzou',
      },
      {
        label: 'Mtsamboro',
      },
      {
        label: "M'Tsangamouji",
      },
      {
        label: 'Ouangani',
      },
      {
        label: 'Pamandzi',
      },
      {
        label: 'Sada',
      },
      {
        label: 'Tsingoni',
      },
    ],
  },
  {
    label: 'South Africa',
    countryCode: 'ZA',
    countryCodeAlpha3: 'ZAF',
    phone: '27',
    currency: 'ZAR',
    stateProvinces: [
      {
        label: 'Orange Free State',
      },
      {
        label: 'KwaZulu-Natal',
      },
      {
        label: 'Eastern Cape',
      },
      {
        label: 'Gauteng',
      },
      {
        label: 'Mpumalanga',
      },
      {
        label: 'Northern Cape',
      },
      {
        label: 'Limpopo',
      },
      {
        label: 'North-West',
      },
      {
        label: 'Western Cape',
      },
    ],
  },
  {
    label: 'Zambia',
    countryCode: 'ZM',
    countryCodeAlpha3: 'ZMB',
    phone: '260',
    currency: 'ZMW',
    stateProvinces: [
      {
        label: 'Western',
      },
      {
        label: 'Southern',
      },
      {
        label: 'North-Western',
      },
      {
        label: 'Northern',
      },
      {
        label: 'Lusaka',
      },
      {
        label: 'Luapula',
      },
      {
        label: 'Eastern',
      },
      {
        label: 'Copperbelt',
      },
      {
        label: 'Central',
      },
      {
        label: 'Muchinga',
      },
    ],
  },
  {
    label: 'Zimbabwe',
    countryCode: 'ZW',
    countryCodeAlpha3: 'ZWE',
    phone: '263',
    currency: 'ZWL',
    stateProvinces: [
      {
        label: 'Midlands',
      },
      {
        label: 'Matabeleland South',
      },
      {
        label: 'Matabeleland North',
      },
      {
        label: 'Masvingo',
      },
      {
        label: 'Mashonaland West',
      },
      {
        label: 'Mashonaland East',
      },
      {
        label: 'Mashonaland Central',
      },
      {
        label: 'Manicaland',
      },
      {
        label: 'Bulawayo',
      },
      {
        label: 'Harare',
      },
    ],
  },
  {
    label: 'Serbia and Montenegro',
    countryCode: 'CS',
    countryCodeAlpha3: 'SCG',
    phone: '381',
    currency: 'RSD',
    stateProvinces: null,
  },
  {
    label: 'Netherlands Antilles',
    countryCode: 'AN',
    countryCodeAlpha3: 'ANT',
    phone: '599',
    currency: 'ANG',
    stateProvinces: null,
  },
];
export const countries = [
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo (the Democratic Republic of the)',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands [Malvinas]',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom of Great Britain and Northern Ireland',
  'United States Minor Outlying Islands',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const currencies = [
  'USD',
  'EUR',
  'JPY',
  'GBP',
  'CHF',
  'CAD',
  'AUD',
  'CNY',
  'HKD',
  'NZD',
  'KRW',
  'SGD',
  'MXN',
  'INR',
  'RUB',
  'ZAR',
  'BRL',
  'TRY',
  'NOK',
  'SEK',
  'DKK',
  'PLN',
  'HUF',
  'CZK',
  'ILS',
  'CLP',
  'PHP',
  'IDR',
  'AED',
  'ARS',
  'TWD',
  'SAR',
  'MYR',
  'THB',
];

export const timeZones = [
  'UTC-12:00',
  'UTC-11:00',
  'UTC-10:00',
  'UTC-09:30',
  'UTC-09:00',
  'UTC-08:00',
  'UTC-07:00',
  'UTC-06:00',
  'UTC-05:00',
  'UTC-04:30',
  'UTC-04:00',
  'UTC-03:30',
  'UTC-03:00',
  'UTC-02:00',
  'UTC-01:00',
  'UTC+00:00',
  'UTC+01:00',
  'UTC+02:00',
  'UTC+03:00',
  'UTC+03:30',
  'UTC+04:00',
  'UTC+04:30',
  'UTC+05:00',
  'UTC+05:30',
  'UTC+05:45',
  'UTC+06:00',
  'UTC+06:30',
  'UTC+07:00',
  'UTC+08:00',
  'UTC+08:45',
  'UTC+09:00',
  'UTC+09:30',
  'UTC+10:00',
  'UTC+10:30',
  'UTC+11:00',
  'UTC+11:30',
  'UTC+12:00',
  'UTC+12:45',
  'UTC+13:00',
  'UTC+14:00',
];
