const {
  styled,
  ListItemButton,
  ListItemIcon,
  ListItem,
} = require('@mui/material');

export const PrimaryListItemButtonElement = styled(ListItemButton)(
  ({theme, open, active}) => ({
    justifyContent: open ? 'initial' : 'center',
    backgroundColor: active ? theme.palette.accent[500] : 'transparent',
    color: active ? theme.palette.neutral[100] : theme.palette.secondary[100],
    minHeight: 48,
    padding: '1em 1.5em',
    marginInline: '1.5em',
    borderRadius: '0.5em',
  })
);

export const PrimaryListItemIconElement = styled(ListItemIcon)(
  ({theme, open, active}) => ({
    marginRight: open ? 3 : 'auto',
    color: active ? theme.palette.neutral[100] : theme.palette.secondary[200],
    fontSize: '22px',
    justifyContent: 'center',
  })
);

export const SecondaryListItemElement = styled(ListItem)(({theme}) => ({
  backgroundColor: theme.palette.primary[500],
  color: theme.palette.neutral[600],
  display: 'block',
}));

export const SecondaryListItemButtonElement = styled(ListItemButton)(
  ({open}) => ({
    justifyContent: open ? 'initial' : 'center',
    minHeight: 48,
    padding: '1em 1.5em',
    marginInline: '1.5em',
  })
);

export const SecondaryListItemIconElement = styled(ListItemIcon)(
  ({theme, open}) => ({
    minWidth: 0,
    fontSize: '20px',
    padding: '0.5em',
    justifyContent: 'center',
    borderRadius: '100vmax',
    marginRight: open ? 3 : 'auto',
    backgroundColor: theme.palette.accent[500],
    color: theme.palette.neutral[100],
  })
);
