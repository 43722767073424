import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

const EventSection = ({meta}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      gap: 5,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          Event Details
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          {meta.event_details}
        </Text>
      </View>
      <View style={{...styles.block, alignItems: 'flex-end'}}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          Quote
        </Text>
        <Text
          style={{
            fontSize: '14px',
            fontWeight: 100,
            color: '#6F6F6F',
          }}
        >
          {meta.quote_type}
        </Text>
      </View>
    </View>
  );
};

export default EventSection;
