/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
/* eslint-disable complexity */
import React from 'react';
import {Typography, Stack, Grid} from '@mui/material';
import {SettingsConstants} from 'constants/settingsConstants';
import PrimaryInput from 'components/Inputs/primary';
import PrimaryDropdown from 'components/Inputs/primaryDropdown';
import PrimaryButton from 'components/Buttons/primaryButton';
import {
  useLazyGetUserBusinessQuery,
  useUpdateBusinessMutation,
} from 'lib/apis/businessApi.slice';
import {useDispatch, useSelector} from 'react-redux';
import {setData} from 'lib/slices/business.slice';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import {useFormik} from 'formik';
import {businessSettingsSchema} from 'constants/formValidationSchemas';
import {useFetchData} from 'utils/fetchDataUtility';
import getStateFromCountry from 'utils/getStateFromCountry';

function BusinessSettingsScreen() {
  const {
    heading1,
    nameInput,
    phoneInput,
    mobileInput,
    addrL1Input,
    addrL2Input,
    cityInput,
    stateInput,
    countryInput,
    pinInput,
    heading2,
    currencyInput,
    timezoneInput,
    fiscalMonthInput,
    fiscalDayInput,
    dateFormInput,
    button,
  } = SettingsConstants.businessForm;

  const [getUserBusiness] = useLazyGetUserBusinessQuery();
  const [updateBusiness, {isLoading}] = useUpdateBusinessMutation();
  const dispatch = useDispatch();
  const {showSnack, handleShowSnack} = useSnackbar();
  useFetchData(setData, getUserBusiness);

  const {businessData} = useSelector(state => state.business);
  const {
    name,
    phone_number,
    mobile_number,
    address_line1,
    address_line2,
    city,
    state,
    pincode,
    country,
    base_currency,
    timezone,
    fiscal_month,
    fiscal_day,
    date_format,
  } = businessData;

  // Submit handler
  const handleSubmit = async payload => {
    try {
      const {message} = await updateBusiness(payload).unwrap();
      dispatch(setData(payload));
      handleShowSnack(true, message, 'success');
    } catch (error) {
      handleShowSnack(true, error.data.message, 'error');
    }
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
      phone_number,
      mobile_number,
      address_line1,
      address_line2,
      city,
      state,
      pincode,
      country,
      base_currency,
      timezone,
      fiscal_month,
      fiscal_day,
      date_format,
    },
    validationSchema: businessSettingsSchema,
    onSubmit(values) {
      // console.log(values);
      handleSubmit(values);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          <Stack spacing={3}>
            <Typography mt={2} variant="medium20">
              {heading1}
            </Typography>
            <Stack>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...nameInput}
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helpertext={formik.touched.name && formik.errors.name}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} pt={2}>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...phoneInput}
                    fullWidth
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helpertext={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...mobileInput}
                    fullWidth
                    value={formik.values.mobile_number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mobile_number &&
                      Boolean(formik.errors.mobile_number)
                    }
                    helpertext={
                      formik.touched.mobile_number &&
                      formik.errors.mobile_number
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...addrL1Input}
                    fullWidth
                    value={formik.values.address_line1}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address_line1 &&
                      Boolean(formik.errors.address_line1)
                    }
                    helpertext={
                      formik.touched.address_line1 &&
                      formik.errors.address_line1
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...addrL2Input}
                    fullWidth
                    value={formik.values.address_line2}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address_line2 &&
                      Boolean(formik.errors.address_line2)
                    }
                    helpertext={
                      formik.touched.address_line2 &&
                      formik.errors.address_line2
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...countryInput}
                    fullWidth
                    value={formik.values.country}
                    onChange={value => formik.setFieldValue('country', value)}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helpertext={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...stateInput}
                    fullWidth
                    value={formik.values.state}
                    disabled={formik.values.country === ''}
                    onChange={value => formik.setFieldValue('state', value)}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helpertext={formik.touched.state && formik.errors.state}
                    options={
                      formik.values.country &&
                      getStateFromCountry(formik.values.country)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...pinInput}
                    fullWidth
                    value={formik.values.pincode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pincode && Boolean(formik.errors.pincode)
                    }
                    helpertext={formik.touched.pincode && formik.errors.pincode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryInput
                    {...cityInput}
                    fullWidth
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helpertext={formik.touched.city && formik.errors.city}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Stack spacing={3}>
            <Typography variant="medium20">{heading2}</Typography>
            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...currencyInput}
                    fullWidth
                    value={formik.values.base_currency ? formik.values.base_currency : currencyInput.options[0]}
                    onChange={value =>
                      formik.setFieldValue('base_currency', value)
                    }
                    error={
                      formik.touched.base_currency &&
                      Boolean(formik.errors.base_currency)
                    }
                    helpertext={
                      formik.touched.base_currency &&
                      formik.errors.base_currency
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...timezoneInput}
                    fullWidth
                    value={formik.values.timezone ? formik.values.timezone : timezoneInput.options[0]}
                    onChange={value => formik.setFieldValue('timezone', value)}
                    error={
                      formik.touched.timezone && Boolean(formik.errors.timezone)
                    }
                    helpertext={
                      formik.touched.timezone && formik.errors.timezone
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} pt={2}>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...fiscalMonthInput}
                    fullWidth
                    value={formik.values.fiscal_month ? formik.values.fiscal_month : fiscalMonthInput.options[0]}
                    onChange={value =>
                      formik.setFieldValue('fiscal_month', value)
                    }
                    error={
                      formik.touched.fiscal_month &&
                      Boolean(formik.errors.fiscal_month)
                    }
                    helpertext={
                      formik.touched.fiscal_month && formik.errors.fiscal_month
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...fiscalDayInput}
                    fullWidth
                    value={formik.values.fiscal_day ? formik.values.fiscal_day : fiscalDayInput.options[0]}
                    onChange={value =>
                      formik.setFieldValue('fiscal_day', value)
                    }
                    error={
                      formik.touched.fiscal_day &&
                      Boolean(formik.errors.fiscal_day)
                    }
                    helpertext={
                      formik.touched.fiscal_day && formik.errors.fiscal_day
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} pt={2}>
                <Grid item xs={6}>
                  <PrimaryDropdown
                    {...dateFormInput}
                    fullWidth
                    value={formik.values.date_format ? formik.values.date_format : dateFormInput.options[0]}
                    onChange={value =>
                      formik.setFieldValue('date_format', value)
                    }
                    error={
                      formik.touched.date_format &&
                      Boolean(formik.errors.date_format)
                    }
                    helpertext={
                      formik.touched.date_format && formik.errors.date_format
                    }
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <PrimaryButton
            type="submit"
            label={button.label}
            typevariant={'h5'}
            fullWidth={false}
            disabled={isLoading}
            loading={isLoading}
          />
        </Stack>
      </form>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </>
  );
}

export default BusinessSettingsScreen;
