import React from 'react';
import {Page, Document, StyleSheet, Font, View} from '@react-pdf/renderer';
import ContactSection from './components/contact-section';
import MetaSection from './components/meta-section';
import DateSection from './components/date-section';
import EventSection from './components/event-section';
import NoteSection from './components/note-section';
import Listing from './components/listing';
import PageFooter from './components/page-footer';
import PaymentSection from './components/payment-section';
import Terms from './components/terms';

// Create styles
Font.register({
  family: 'Work Sans',
  src: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    color: '#1C1C1C',
    padding: 30,
    paddingBottom: 80,
  },
  section: {
    margin: 20,
    padding: 10,
    flexGrow: 1,
  },
});

const PrimaryQuotePDF = ({state}) => {
  return (
    <Document
      title={`${state.meta.quoteNumber}_${state.issuer.name}_${
        state.meta.qoute_type === 'Estimate' ? 'EST' : 'OFF'
      }`}
    >
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            display: 'flex',
            gap: 20,
          }}
        >
          <ContactSection issuer={state.issuer} recipient={state.recipient} />
          <MetaSection meta={state.meta} summary={state.summary} />
          <DateSection meta={state.meta} />
          <EventSection meta={state.meta} />
          <NoteSection meta={state.meta} summary={state.summary} />
          <Listing
            listing={state.listing}
            summary={state.summary}
            showSectionWisePrice={state.showSectionWisePrice}
          />
          <PaymentSection paymentDetails={state.paymentDetails} />
          <Terms paymentDetails={state.paymentDetails} />
        </View>
        <PageFooter />
      </Page>
    </Document>
  );
};

export default PrimaryQuotePDF;
