import React from 'react';
import {Box, Typography, useTheme, useMediaQuery} from '@mui/material';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import lockIllustration from 'assets/auth/svgs/lock-illus.svg';
import PrimaryHead from 'components/Mastheads/primary';
import PrimaryInput from 'components/Inputs/primary';
import {useNavigate} from 'react-router-dom';
import {ReqForgotPWSchema} from 'constants/formValidationSchemas';
import {useFormik} from 'formik';
import {useForgotPasswordMutation} from 'lib/apis/authApi.slice';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import PrimaryButton from 'components/Buttons/primaryButton';

const ForgotPW = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery('(min-width: 50em)');
  const navigate = useNavigate();
  const [mutate, {isLoading}] = useForgotPasswordMutation();
  const {showSnack, handleShowSnack} = useSnackbar();

  const handleSubmit = async values => {
    try {
      const {message} = await mutate({
        username: values.username,
      }).unwrap();
      handleShowSnack(true, message, 'info');
      setTimeout(() => {
        navigate('/secure/reset-password', {
          state: {
            username: values.username,
          },
        });
      }, 3000);
    } catch (err) {
      handleShowSnack(true, err.data.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: ReqForgotPWSchema,
    onSubmit(values) {
      handleSubmit(values);
    },
  });

  return (
    <Box
      width="100%"
      height="100%"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'4.25em'}
      padding={isNonMobile ? '3em 0 0' : '8.75em 0'}
    >
      <Box
        backgroundColor={theme.palette.neutral[100]}
        padding={'3em 2.25em'}
        borderRadius={'0.5em'}
        width={isNonMobile ? '32.625em' : '90%'}
      >
        <PrimaryHead
          title={'Forgot Password'}
          subtitle={
            'Please enter your username to receive verification code on your registered email.'
          }
          color={theme.palette.grey[500]}
        >
          <Box
            component="img"
            alt="Lock Illustration"
            src={lockIllustration}
            height="100%"
            width="100%"
            sx={{objectFit: 'cover', maxWidth: '10.375em'}}
          />
        </PrimaryHead>
        {/* FORM */}
        <Box width={'100%'}>
          <form onSubmit={formik.handleSubmit}>
            <FlexBetween
              width={'100%'}
              flexDirection={'column'}
              gap={'1em'}
              padding={'3em 0 2.25em'}
            >
              <PrimaryInput
                name={'username'}
                type={'text'}
                label={'Username'}
                title={'Enter your username'}
                placeholder={'Enter your username'}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helpertext={formik.touched.username && formik.errors.username}
                fullWidth
              />
            </FlexBetween>
            <PrimaryButton
              type="submit"
              fullwidth={true}
              disabled={isLoading}
              loading={isLoading}
              sx={{
                backgroundColor: theme.palette.accent.main,
                color: theme.palette.neutral[100],
                padding: '1em',
                borderRadius: '0.8em',
                textTransform: 'none',
              }}
              label={'Get Verification Code'}
            />
          </form>
        </Box>
      </Box>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </Box>
  );
};

export default ForgotPW;
