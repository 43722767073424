import {StyleSheet, Text, View} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  section1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '6px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 2,
  },
});

const PaymentSection = ({paymentDetails}) => {
  return (
    <View style={styles.container}>
      <View style={styles.section1}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 400,
          }}
        >
          Bank Details
        </Text>
        <View style={styles.content}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
              width: '100px',
            }}
          >
            Bank Name:
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
            }}
          >
            {paymentDetails.bank_name}
          </Text>
        </View>
        <View style={styles.content}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
              width: '100px',
            }}
          >
            Account Holder:
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
            }}
          >
            {paymentDetails.bank_account_holder}
          </Text>
        </View>
        <View style={styles.content}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
              width: '100px',
            }}
          >
            Account Number:
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
            }}
          >
            {paymentDetails.account_number}
          </Text>
        </View>
        <View style={styles.content}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
              width: '100px',
            }}
          >
            IBAN Code:
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
            }}
          >
            {paymentDetails.ifsc_code}
          </Text>
        </View>
        <View style={styles.content}>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
              width: '100px',
            }}
          >
            Swift Code:
          </Text>
          <Text
            style={{
              fontWeight: 400,
              fontSize: '10px',
              color: '#6F6F6F',
            }}
          >
            {paymentDetails.swift_code}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PaymentSection;
