import {PDFViewer} from '@react-pdf/renderer';
import InventoryListDoc from 'components/templates/pdf/inventory';

const PDFPreview = () => {
  const inventoryData = JSON.parse(localStorage.getItem('inventoryData'));
  return (
    <PDFViewer width={'100%'} height={window.innerHeight - 10}>
      <InventoryListDoc state={inventoryData} />
    </PDFViewer>
  );
};

export default PDFPreview;
