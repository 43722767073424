import {apiSlice} from './index.slice';

export const messageAPI = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMessages: builder.query({
      query: () => ({
        url: 'message/getMessages',
        method: 'GET',
      }),
    }),
    deleteMessage: builder.mutation({
      query: ({id}) => ({
        url: `message/deleteMessage?message_id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: arg => [{type: 'Message', id: arg.id}],
    }),
    deleteAllMessages: builder.mutation({
      query: () => ({
        url: 'message/deleteAllMessages',
        method: 'DELETE',
      }),
      invalidatesTags: () => [{type: 'Message'}],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useDeleteMessageMutation,
  useDeleteAllMessagesMutation,
} = messageAPI;
