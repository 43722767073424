import {CloseOutlined} from '@mui/icons-material';
import {Avatar, Badge, IconButton} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@emotion/react';

const WithRemoveAvatar = ({src, alt, handleRemove, showRemove}) => {
  const {palette} = useTheme();
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      badgeContent={
        showRemove && (
          <IconButton
            onClick={handleRemove}
            sx={{
              backgroundColor: palette.error[500],
              padding: '5px',
            }}
          >
            <CloseOutlined
              sx={{
                fontSize: '16px',
              }}
            />
          </IconButton>
        )
      }
    >
      <Avatar
        alt={alt}
        src={src}
        sx={{
          width: 80,
          height: 80,
          border: 2,
          borderColor: palette.grey[200],
        }}
      />
    </Badge>
  );
};

WithRemoveAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  handleRemove: PropTypes.func,
  showRemove: PropTypes.bool,
};
export default WithRemoveAvatar;
