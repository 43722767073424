import React from 'react';
import {Box, Typography, Stack, Grid, IconButton} from '@mui/material';
import {PaymentConstants} from 'constants/paymentContants';
import useTableQueryParams from 'utils/tableQueryParamsUtility';
import DataTable from './serverside.datatable';
import PrimaryButton from 'components/Buttons/primaryButton';
import NoLabelInput from 'components/Inputs/noLabelInput';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {debounce} from 'utils/debounce';

const PaymentPage = () => {
  const {state, dispatch} = useTableQueryParams();

  return (
    <Box
      sx={{
        width: `min(1440px, 100% - 1rem * 2)`,
        marginInline: 'auto',
      }}
    >
      {/* <FlexBetween marginTop={'1em'} justifyContent={'space-between'}>
        {PaymentConstants.stats.map(({icon, imgAlt, type, balance}, id) => {
          return (
            <BasicCardTwo
              key={id}
              icon={icon}
              imgAlt={imgAlt}
              type={type}
              balance={
                'AED ' +
                parseFloat(balance)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          );
        })}
      </FlexBetween> */}
      <Stack spacing={4} my={5}>
        <Typography variant="h3">
          {PaymentConstants.sectionTwo.title}
        </Typography>
      </Stack>
      <CustomToolbar searchValue={state.search} dispatch={dispatch} />
      <DataTable state={state} dispatch={dispatch} />
    </Box>
  );
};

export default PaymentPage;

const CustomToolbar = ({value, dispatch}) => {
  const navigate = useNavigate();
  const handleCreatePageNav = () => {
    navigate('/app/payments/create', {
      state: {
        payment_id: '',
        client: '',
        company_name: '',
        representative_name: '',
        date: '',
        payment_type: '',
        amount: '',
        quoteId: '',
        quoteNumber: '',
        advance_payment: false,
        note: '',
        canEdit: true,
      },
    });
  };
  const handleSearch = value => {
    debounce(dispatch({type: 'SET_SEARCH', payload: value}), 300);
  };

  return (
    <Grid container spacing={3} justifyContent={'space-between'}>
      <Grid item xs={6}>
        <NoLabelInput
          name={PaymentConstants.sectionTwo.input.name}
          type={PaymentConstants.sectionTwo.input.type}
          label={''}
          placeholder={PaymentConstants.sectionTwo.input.placeholder}
          fullWidth
          starticonconfig={
            <IconButton aria-label="Search Option">
              {PaymentConstants.sectionTwo.input.icon}
            </IconButton>
          }
          size="small"
          value={value}
          onChange={e => handleSearch(e.target.value)}
        />
      </Grid>

      <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
        <PrimaryButton
          label={PaymentConstants.sectionTwo.button.label}
          typevariant={'h5'}
          starticon={PaymentConstants.sectionTwo.button.icon}
          handleOnClick={() => handleCreatePageNav()}
        />
      </Grid>
    </Grid>
  );
};

CustomToolbar.propTypes = {
  value: PropTypes.string,
  setSearch: PropTypes.func,
  dispatch: PropTypes.func,
};
