import {useTheme, Typography} from '@mui/material';
import React from 'react';
import {SlabElement} from './elements.style';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import PropTypes from 'prop-types';
// USED IN INVOICE PAGE
const BasicCardTwo = ({icon, type, balance}) => {
  const theme = useTheme();
  return (
    <SlabElement>
      <FlexBetween gap={'1em'}>
        <FlexBetween
          p={'0.5em'}
          bgcolor={theme.palette.primary.main}
          borderRadius={'1em'}
          w={'auto'}
        >
          {icon}
        </FlexBetween>
        <FlexBetween
          flexDirection={'column'}
          alignItems={'flex-start'}
          gap={'0.5em'}
        >
          <Typography variant="body5" color={theme.palette.indigo[400]}>
            {type}
          </Typography>
          <Typography variant="h3">{balance}</Typography>
        </FlexBetween>
      </FlexBetween>
    </SlabElement>
  );
};

BasicCardTwo.propTypes = {
  icon: PropTypes.element,
  type: PropTypes.string,
  balance: PropTypes.string,
};

export default BasicCardTwo;
