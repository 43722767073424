import {useLazyGetUserQuery} from 'lib/apis/authApi.slice';
import React, {useEffect} from 'react';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import {setUser} from 'lib/slices/auth.slice';
import PropTypes from 'prop-types';
import {useAuth} from 'utils/authUtility';
import {setData} from 'lib/slices/business.slice';
import {useLazyGetUserBusinessQuery} from 'lib/apis/businessApi.slice';
import {useFetchData} from 'utils/fetchDataUtility';
import {useGetMessagesQuery} from 'lib/apis/message.api';
import {useDispatch} from 'react-redux';
import {setNewMessage} from 'lib/slices/notification.slice';
// import socketIO from 'socket.io-client';
// import {setNewMessage} from 'lib/slices/notification.slice';
const UserMiddleware = ({children}) => {
  // User query
  const [getUser] = useLazyGetUserQuery();
  const [getUserBusiness] = useLazyGetUserBusinessQuery();
  const {data: messages, isSuccess: isMessageQuerySuccess} =
    useGetMessagesQuery(null, {
      pollingInterval: 60000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });
  const {
    userInfo,
    // token
  } = useSelector(state => state.auth);
  useAuth(setUser, getUser);
  useFetchData(setData, getUserBusiness);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMessageQuerySuccess) {
      messages.map(({_id, created_at, message}) => {
        dispatch(setNewMessage({id: _id, timestamp: created_at, message}));
      });
    }
  }, [isMessageQuerySuccess]);

  if (!userInfo) {
    return <div>LOADING</div>;
  }
  return children;
};

UserMiddleware.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserMiddleware;
