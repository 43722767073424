import React from 'react';
import {Image, StyleSheet, Text, View} from '@react-pdf/renderer';

const ClientSection = ({logo, recipient, quoteNumber, meta}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      width: '100%',
      gap: 20,
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      flexDirection: 'row',
    },
    logo: {
      width: '100px',
      height: 'auto',
      marginBottom: 20,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 0,
      border: '0.5px solid #B7B7B7',
    },
    cellWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: 0,
    },
    cell1: {
      backgroundColor: '#F0F0F0',
      borderRight: '0.5px solid #B7B7B7',
      width: '100px',
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
    cell2: {
      width: '100%',
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
  });

  return (
    <View style={styles.container}>
      {logo && <Image style={styles.logo} src={logo} />}
      <View style={{...styles.block}}>
        <Text
          style={{
            fontFamily: 'Helvetica-Bold',
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#4F4F4F',
          }}
        >
          WORK ORDER NUMBER:
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
            color: '#4F4F4F',
          }}
        >
          {quoteNumber.replace('AQ', 'WO')}
        </Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.cellWrapper}>
            <View style={styles.cell1}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  textAlign: 'left',
                }}
              >
                Company
              </Text>
            </View>
            <View style={styles.cell2}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  color: '#4F4F4F',
                }}
              >
                {recipient.company_name}
              </Text>
            </View>
          </View>
          <View style={styles.cellWrapper}>
            <View style={styles.cell1}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  textAlign: 'left',
                }}
              >
                Venue
              </Text>
            </View>
            <View style={styles.cell2}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  color: '#4F4F4F',
                  maxWidth: '180px',
                  overflowWrap: 'break-word',
                }}
              >
                {meta.event_details}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.cellWrapper}>
            <View style={styles.cell1}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  textAlign: 'left',
                }}
              >
                Contact
              </Text>
            </View>
            <View style={styles.cell2}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  color: '#4F4F4F',
                }}
              >
                {recipient.name}
              </Text>
            </View>
          </View>
          <View style={styles.cellWrapper}>
            <View style={styles.cell1}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  textAlign: 'left',
                }}
              >
                Number
              </Text>
            </View>
            <View style={styles.cell2}>
              <Text
                style={{
                  fontSize: '9px',
                  fontWeight: 100,
                  color: '#4F4F4F',
                }}
              >
                {recipient.mobile_number}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ClientSection;
