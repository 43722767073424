const {styled} = require('@mui/material');

export const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // Necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
