import {ReactComponent as SalesIcon} from 'assets/icons/sales-icon-1.svg';
import {ReactComponent as RevenueIcon} from 'assets/icons/revenue-icon.svg';
import {ReactComponent as ProfitIcon} from 'assets/icons/profit-icon.svg';
import {ReactComponent as SearchOutlined} from 'assets/icons/search-icon.svg';
import {ReactComponent as FilterOutlined} from 'assets/icons/filter-icon.svg';
import React from 'react';
const {SvgIcon} = require('@mui/material');
const {Add} = require('@mui/icons-material');

export const PaymentConstants = {
  stats: [
    {
      icon: <SvgIcon component={SalesIcon} inheritViewBox fontSize={'large'} />,
      imgAlt: 'Sales Icon',
      type: 'Total Sales',
      balance: '24, 875',
    },
    {
      icon: (
        <SvgIcon component={RevenueIcon} inheritViewBox fontSize={'large'} />
      ),
      imgAlt: 'Revenue Icon',
      type: 'Total Revenue',
      balance: '111, 075',
    },
    {
      icon: (
        <SvgIcon component={ProfitIcon} inheritViewBox fontSize={'large'} />
      ),
      imgAlt: 'Profit Icon',
      type: 'Net Profit',
      balance: '97, 544',
    },
  ],
  sectionTwo: {
    title: 'All Payments',
    input: {
      name: 'search',
      type: 'text',
      placeholder: 'Search for payments',
      icon: (
        <SvgIcon component={SearchOutlined} inheritViewBox fontSize="small" />
      ),
    },
    filter: {
      text: 'Filter',
      icon: (
        <SvgIcon
          component={FilterOutlined}
          inheritViewBox
          fontSize={'medium'}
          sx={{
            flexShrink: 0,
          }}
        />
      ),
    },
    button: {
      label: 'Record Payment',
      icon: <Add />,
    },
    table: {
      heading: 'Transaction',
    },
  },
};

export const CreatePaymentContants = {
  heading: 'New Payment',
  companyInput: {
    id: 'company_name',
    name: 'company_name',
    type: 'text',
    title: 'Company Name',
    placeholder: 'Enter company name',
  },
  clientInput: {
    id: 'representative_name',
    name: 'representative_name',
    type: 'text',
    title: 'Client Name',
    placeholder: 'Enter client name',
  },
  dateInput: {
    id: 'date',
    name: 'date',
    type: 'text',
    title: 'Payment Date',
    placeholder: 'Enter payment date',
  },
  paytypeInput: {
    id: 'payment_type',
    name: 'payment_type',
    type: 'text',
    title: 'Payment Type',
    placeholder: 'Select payment type',
    options: [
      'Select payment type',
      'CASH',
      'CHEQUE',
      'CARD',
      'ONLINE PAYMENT',
      'CDC',
      'PDC',
    ],
  },
  amountInput: {
    id: 'amount',
    name: 'amount',
    type: 'number',
    title: 'Amount',
    placeholder: 'Enter an amount',
  },
  quoteInput: {
    id: 'quoteNumber',
    name: 'quoteNumber',
    type: 'text',
    title: 'Quote No.',
    placeholder: 'Enter a quote no.',
  },
  noteInput: {
    id: 'note',
    name: 'note',
    type: 'text',
    title: 'Notes',
    placeholder: 'Enter notes',
  },
  btnOne: {
    label: 'Cancel',
  },
  btnTwo: {
    label: 'Save',
  },
};
