import React from 'react';
import {Box, useMediaQuery, useTheme, styled} from '@mui/material';
import {Outlet} from 'react-router-dom';
import PrimaryNavbar from 'containers/Appbars/PrimaryAppbar';

const PrimaryAuthLayout = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery('(min-width: 50em)');
  const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // Necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  return (
    <Box
      bgcolor={theme.palette.primary.main}
      display={'flex'}
      width="100%"
      height="100%"
    >
      <Box width="100%" height="100%">
        <PrimaryNavbar isAuthenticated={false} isNonMobile={isNonMobile} />
        <Box component="main" sx={{flexGrow: 1, p: 3}}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default PrimaryAuthLayout;
