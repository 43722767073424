import React from 'react';
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

const PrimaryInput = props => {
  const theme = useTheme();

  const numberInputOnWheelPreventChange = e => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'0.7em'}
      alignItems={'flex-start'}
      width={'100%'}
    >
      <InputLabel
        sx={{
          ...theme.typography.h5,
          color: theme.palette.neutral[600],
        }}
      >
        {props.title}
      </InputLabel>
      {/* <Field {...props} /> */}
      <OutlinedInput
        rows={props.rows}
        onWheel={numberInputOnWheelPreventChange}
        {...props}
        sx={{
          borderRadius: '0.5em',
          '& .MuiOutlinedInput-input': {
            padding: '0.875em !important',
          },
          borderColor: props.error ? theme.palette.error[500] : "none",
          width: props.width,
        }}
        endAdornment={
          props.endiconconfig && (
            <InputAdornment position="end">
              {props.endiconconfig}
            </InputAdornment>
          )
        }
        startAdornment={
          props.starticonconfig && (
            <InputAdornment position="start">
              {props.starticonconfig}
            </InputAdornment>
          )
        }
      />
      <FormHelperText
        sx={{
          ...theme.typography.medium14,
          color: theme.palette.error[500],
        }}
      >
        {props.helpertext}
      </FormHelperText>
    </Box>
  );
};

export default PrimaryInput;

PrimaryInput.propTypes = {
  title: PropTypes.string,
  endiconconfig: PropTypes.element,
  starticonconfig: PropTypes.element,
  helpertext: PropTypes.string,
  rows: PropTypes.number,
  width: PropTypes.string,
};
