const {styled, List} = require('@mui/material');
const {drawerWidth} = require('constants/sideBarConstants');

export const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  zIndex: 999,
});

export const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  zIndex: 999,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerListElement = styled(List)(({theme}) => ({
  height: '100%',
  paddingTop: '9.75em',
  backgroundColor: theme.palette.primary[500],
  color: theme.palette.neutral[600],
}));
