import PropTypes from 'prop-types';
import React from 'react';
import {
  InputLabel,
  useTheme,
  Box,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const PrimaryDropdown = props => {
  const theme = useTheme();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'0.75em'}
      alignItems={'flex-start'}
      width={'100%'}
    >
      <InputLabel
        sx={{
          ...theme.typography.h5,
          color: theme.palette.neutral[600],
        }}
      >
        {props.title}
      </InputLabel>
      <Select
        disabled={props.disabled}
        labelId={props.id}
        id={props.id}
        value={props.value}
        label={props.title}
        onChange={e =>
          props.onChange(
            e.target.value === props.options[0] ? '' : e.target.value
          )
        }
        fullWidth
        sx={{
          borderRadius: '0.5em',
          '& .MuiOutlinedInput-input': {
            padding: '0.875em !important',
          },
          border: 'thin solid',
          borderColor: props.error ? theme.palette.error[500] : 'transparent',
        }}
      >
        {props.options &&
          props.options.map((option, id) => {
            return (
              <MenuItem
                value={option}
                key={id}
                sx={{
                  marginInline: '8px',
                  '&.Mui-selected': {
                    backgroundColor:
                      props.value === option &&
                      `${theme.palette.accent[500]} !important`,
                    color:
                      props.value === option &&
                      `${theme.palette.neutral[100]} !important`,
                    borderRadius: '10px',
                  },
                }}
              >
                {option}
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText
        sx={{
          ...theme.typography.medium14,
          color: theme.palette.error[500],
        }}
      >
        {props.helpertext}
      </FormHelperText>
    </Box>
  );
};

PrimaryDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  helpertext: PropTypes.string,
  error: PropTypes.bool,
};

export default PrimaryDropdown;
