import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {authApiSlice} from 'lib/apis/authApi.slice';
import {businessApiSlice} from 'lib/apis/businessApi.slice';
import {apiSlice} from 'lib/apis/index.slice';
import {authReducer} from 'lib/slices/auth.slice';
import {businessReducer} from 'lib/slices/business.slice';
import quoteReducer from 'lib/slices/quote.slice';
import {notificationReducer} from 'lib/slices/notification.slice';
import {persistStore} from 'redux-persist';

// Combining all reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  business: businessReducer,
  quote: quoteReducer,
  notification: notificationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      apiSlice.middleware,
      authApiSlice.middleware,
      businessApiSlice.middleware,
    ]),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
