import * as React from 'react';
import {Dialog} from '@mui/material';
import PropTypes from 'prop-types';

const BasicPopover = ({open, handleClose, children, width}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={width}
      sx={{zIndex: 9999}}
    >
      {children}
    </Dialog>
  );
};

BasicPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};

export default BasicPopover;
