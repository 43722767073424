import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = '#fff';
const headerStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#4F4F4F',
    color: '#ffffff',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 30,
    flexGrow: 1,
    textAlign: 'left',
  },
  sl: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    width: '50%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  qty: {
    width: '15%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  amount: {
    width: '15%',
    fontSize: '10px',
    padding: 5,
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
  },
});
const rowStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    color: '#1c1c1c',
    borderTopWidth: 0,
    alignItems: 'center',
    height: 30,
    flexGrow: 1,
    textAlign: 'left',
  },
  sl: {
    width: '10%',
    borderRightColor: '#E0E0E0',
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    width: '50%',
    borderRightColor: '#E0E0E0',
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  qty: {
    width: '15%',
    fontSize: '10px',
    borderRightColor: '#E0E0E0',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  amount: {
    width: '15%',
    fontSize: '10px',
    padding: 5,
    display: 'flex',
    alignItems: 'flex-end',
    flexGrow: '1',
  },
});

const footerStyle = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: '10px',
    paddingTop: 10,
    borderColor: '#E0E0E0',
    borderTopWidth: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    fontSize: 10,
    fontStyle: 'bold',
  },
  bgrow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 28,
    fontSize: 12,
    fontStyle: 'bold',
    backgroundColor: '#F1F1F1',
  },
  description: {
    width: '70%',
    textAlign: 'right',
    paddingRight: 8,
  },
  total: {
    width: '25%',
    textAlign: 'right',
    paddingRight: 8,
    flexGrow: '1',
  },
});

const TableHeader = () => (
  <View style={headerStyles.container}>
    <View style={headerStyles.sl}>
      <Text>Sl</Text>
    </View>
    <View style={headerStyles.description}>
      <Text>Description</Text>
    </View>
    <View style={headerStyles.qty}>
      <Text>Quantity</Text>
    </View>
    <View style={headerStyles.amount}>
      <Text
        style={{
          textAlign: 'right',
        }}
      >
        AED
      </Text>
    </View>
  </View>
);

const TableRow = ({sl, name, quantity, price, type}) => (
  <View style={rowStyles.container}>
    <View style={rowStyles.sl}>
      <Text>{sl}</Text>
    </View>
    <View
      style={{
        ...rowStyles.description,
        fontSize:
          type === 'site' ? '14px' : type === 'section' ? '12px' : '10px',
        fontWeight: type === 'site' ? 600 : type === 'section' ? 500 : 100,
      }}
    >
      <Text>{name}</Text>
    </View>
    <View style={rowStyles.qty}>
      <Text>{quantity}</Text>
    </View>
    <View style={rowStyles.amount}>
      <Text
        style={{
          textAlign: 'right',
        }}
      >
        {price}
      </Text>
    </View>
  </View>
);
const TableFooter = ({totalAmount, vat, discount, payable, days}) => {
  return (
    <View style={footerStyle.container}>
      <View style={footerStyle.row}>
        <Text style={footerStyle.description}>No. of days</Text>
        <Text style={footerStyle.total}>{days}</Text>
      </View>
      <View style={footerStyle.row}>
        <Text style={footerStyle.description}>Standard Cost</Text>
        <Text style={footerStyle.total}>
          AED {totalAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Text>
      </View>
      <View style={footerStyle.row}>
        <Text style={footerStyle.description}>Discount</Text>
        <Text style={footerStyle.total}>
          AED {discount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Text>
      </View>
      <View style={footerStyle.row}>
        <Text style={footerStyle.description}>Total before VAT</Text>
        <Text style={footerStyle.total}>
          AED{' '}
          {(parseFloat(payable) - parseFloat(vat))
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Text>
      </View>
      <View style={footerStyle.row}>
        <Text style={footerStyle.description}>Standard VAT (5%)</Text>
        <Text style={footerStyle.total}>
          AED {vat.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Text>
      </View>
      <View style={footerStyle.bgrow}>
        <Text style={footerStyle.description}>Total (Inclusive VAT)</Text>
        <Text style={footerStyle.total}>
          AED {payable.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Text>
      </View>
    </View>
  );
};

const Listing = ({listing, summary, showSectionWisePrice}) => {
  const renderList = [];
  listing.map((site, siteId) => {
    if (site.name !== '') {
      renderList.push({sl: siteId + 1, name: site.name, type: 'site'});
      site.sections.map((section, sectionId) => {
        if (section.name !== '') {
          renderList.push({
            sl: `${siteId + 1}.${sectionId + 1}`,
            name: section.name,
            price: section.price,
            type: 'section',
          });
          section.products.map((product, productId) => {
            if (product.id) {
              renderList.push({
                sl: `${siteId + 1}.${sectionId + 1}.${productId + 1}`,
                name: product.name,
                quantity: product.quantity,
                price: product.price,
                type: 'product',
              });
            }
          });
        }
      });
    }
  });
  return (
    <View>
      <TableHeader />
      {renderList.map((item, id) => {
        return (
          <TableRow
            key={id}
            sl={item.sl}
            name={item.name}
            quantity={item.quantity ? item.quantity : ' '}
            price={
              item.type === 'product'
                ? showSectionWisePrice && item.price
                  ? item.price
                  : ' '
                : item.price
            }
            type={item.type}
          />
        );
      })}
      <TableFooter
        totalAmount={summary.totalAmount}
        additional_charges={summary.additional_charge}
        vat={summary.vat}
        discount={summary.discount}
        payable={summary.payable}
        days={summary.days}
      />
    </View>
  );
};

export default Listing;
