import {useReducer} from 'react';

export const useTableQueryParams = () => {
  // Querying params
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_PAGE':
        return {...state, page: action.payload};
      case 'SET_PAGE_SIZE':
        return {...state, pageSize: action.payload};
      case 'SET_SORT':
        return {...state, sort: action.payload};
      case 'SET_SEARCH':
        return {...state, search: action.payload};
      case 'SET_SEARCH_VALUE':
        return {...state, searchValue: action.payload};
      case 'SET_SEARCH_BY_FIELDS':
        return {
          ...state,
          searchFields: {
            key: action.payload.key,
            value: action.payload.value,
          },
        };
      case 'SET_FROM_DATE':
        return {...state, fromDate: action.payload};
      case 'SET_TO_DATE':
        return {...state, toDate: action.payload};
      default:
        break;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    pageSize: 10,
    sort: {},
    search: '',
    searchValue: '',
    fromDate: '',
    toDate: '',
    searchFields: {
      key: '',
      value: '',
    },
  });
  return {state, dispatch};
};

export default useTableQueryParams;
