import {StyleSheet, Text, View} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 10,
  },
  section: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '6px',
  },
});

const CreatedBy = ({createdBy}) => {
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 400,
          }}
        >
          Prepared By
        </Text>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '10px',
            color: '#4A4A4A',
          }}
        >
          {createdBy.first_name} {createdBy.last_name}
        </Text>
      </View>
      <View style={styles.section}>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '9px',
            color: '#6F6F6F',
          }}
        >
          Mobile:{createdBy.mobile_number}
        </Text>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '9px',
            color: '#6F6F6F',
          }}
        >
          Email: {createdBy.email}
        </Text>
      </View>
    </View>
  );
};

export default CreatedBy;
