import React from 'react';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import {Typography} from '@mui/material';
import PropTypes from 'prop-types';

const PrimaryHead = ({children, title, subtitle, color}) => {
  return (
    <FlexBetween flexDirection={'column'} gap={'1em 0'}>
      {children}
      <FlexBetween flexDirection={'column'} gap={'0.75em'}>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="medium16" color={color} align="center">
          {subtitle}
        </Typography>
      </FlexBetween>
    </FlexBetween>
  );
};

export default React.memo(PrimaryHead);

PrimaryHead.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
};
