import {ListItem, ListItemText} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryListItemButtonElement,
  PrimaryListItemIconElement,
} from './elements.style';

const PrimaryItem = ({active, icon, text, open, lcText, handleOnClick, hidden}) => {
  return !hidden && (
    <ListItem disablePadding sx={{display: 'block'}}>
      <PrimaryListItemButtonElement
        open={open}
        active={active.includes(lcText)}
        onClick={() => handleOnClick(`/app/${lcText}`)}
      >
        <PrimaryListItemIconElement open={open} active={active === lcText}>
          {icon}
        </PrimaryListItemIconElement>
        <ListItemText primary={text} sx={{opacity: open ? 1 : 0}} />
      </PrimaryListItemButtonElement>
    </ListItem>
  );
};

export default React.memo(PrimaryItem);

PrimaryItem.propTypes = {
  active: PropTypes.string,
  icon: PropTypes.element,
  text: PropTypes.string,
  open: PropTypes.bool,
  lcText: PropTypes.string,
  handleOnClick: PropTypes.func,
  hidden: PropTypes.bool
};
