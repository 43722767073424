import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

const LogoBox = ({logo}) => {
  return (
    <Box
      component="img"
      alt="Abacus Logo"
      src={logo}
      height="100%"
      width="100%"
      sx={{
        objectFit: 'cover',
        maxWidth: '6.813em',
      }}
    />
  );
};

export default LogoBox;

LogoBox.propTypes = {
  logo: PropTypes.string,
};
