import React from 'react';
import {TabElement} from './buttons.style';
import PropTypes from 'prop-types';

const LinkTab = props => {
  return (
    <TabElement
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
      active={props.active}
      indicator={props.indicator}
    />
  );
};

LinkTab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  indicator: PropTypes.bool,
};
export default LinkTab;
