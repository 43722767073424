import React, {useState} from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import Logo from 'assets/general/svgs/logo.svg';
import PrimaryHead from 'components/Mastheads/primary';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import PrimaryInput from 'components/Inputs/primary';
import {
  CheckCircleOutlined,
  LoginOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import TextBtn from 'components/Buttons/textButton';

import {SignInSchema} from 'constants/formValidationSchemas';
import BasicSnackbar from 'components/Snackbars/basic';
import PrimaryButton from 'components/Buttons/primaryButton';
import {useSigninMutation} from 'lib/apis/authApi.slice';
import {setUserId} from 'lib/slices/auth.slice';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'utils/snackbarUtility';

const Signin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery('(min-width: 50em)');
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [signin, {isLoading}] = useSigninMutation();
  const {showSnack, handleShowSnack} = useSnackbar();

  const handleClickShowPassword = () => {
    setShowPassword(p => !p);
  };

  const handleSubmit = async values => {
    try {
      const {user_id, message} = await signin(values).unwrap();
      dispatch(setUserId({user_id, message}));
      handleShowSnack(true, message, 'info');
      setTimeout(() => {
        navigate('/secure/verify');
      }, 3000);
    } catch (err) {
      handleShowSnack(true, err.data.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit(values) {
      handleSubmit(values);
    },
  });

  return (
    <Box
      width="100%"
      height="100%"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'4.25em'}
      padding={isNonMobile ? '3em 0 0' : '8.75em 0'}
    >
      <Box
        backgroundColor={theme.palette.neutral[100]}
        padding={'3em 2.25em'}
        borderRadius={'0.5em'}
        width={isNonMobile ? '32.625em' : '90%'}
      >
        <PrimaryHead
          title={'Welcome Back'}
          subtitle={'Please enter your login details.'}
        >
          <Box
            component="img"
            alt="Abacus Logo"
            src={Logo}
            height="100%"
            width="100%"
            sx={{objectFit: 'cover', maxWidth: '7.813em'}}
          />
        </PrimaryHead>
        {/* FORM */}
        <Box width={'100%'}>
          <form onSubmit={formik.handleSubmit}>
            <FlexBetween
              width={'100%'}
              flexDirection={'column'}
              gap={'1em'}
              padding={'3em 0 2.25em'}
            >
              <PrimaryInput
                id={'username'}
                name={'username'}
                type={'username'}
                label={''}
                title={'Username'}
                placeholder={'username'}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helpertext={formik.touched.username && formik.errors.username}
                fullWidth
              />
              <PrimaryInput
                name={'password'}
                type={showPassword ? 'text' : 'password'}
                label={''}
                title={'Password'}
                placeholder={'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helpertext={formik.touched.password && formik.errors.password}
                endiconconfig={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // OnMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                }
                fullWidth
              />
              <FlexBetween width={'100%'}>
                <FlexBetween gap={'0.25em'}>
                  <TextBtn
                    onClick={() => {
                      console.info("I'm a button.");
                    }}
                    startIcon={<CheckCircleOutlined fontSize="12" />}
                    color={theme.palette.neutral[600]}
                  >
                    <Typography variant="h6">Remember me</Typography>
                  </TextBtn>
                </FlexBetween>
                <TextBtn
                  handleOnClick={() => {
                    navigate('/secure/forgot-password');
                  }}
                  color={theme.palette.neutral[600]}
                >
                  <Typography variant="h6">Forgot Password?</Typography>
                </TextBtn>
              </FlexBetween>
            </FlexBetween>
            <PrimaryButton
              type="submit"
              fullwidth={true}
              disabled={isLoading}
              loading={isLoading}
              sx={{
                backgroundColor: theme.palette.accent.main,
                color: theme.palette.neutral[100],
                padding: '1em',
                borderRadius: '0.8em',
                textTransform: 'none',
              }}
              endicon={
                <LoginOutlined
                  fontSize={'20px'}
                  color={theme.palette.neutral[100]}
                />
              }
              label={'Login'}
            />
          </form>
        </Box>
      </Box>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </Box>
  );
};

export default Signin;
