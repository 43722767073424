import React from 'react';
import {CircularProgress, Typography, useTheme} from '@mui/material';
import {PrimaryButtonElement} from './buttons.style';
import {ButtonPropsType} from 'types/buttonTypes';
import PropTypes from 'prop-types';
import {Box} from '@mui/system';

const PrimaryButton = ({
  label,
  endicon,
  starticon,
  handleOnClick,
  typevariant,
  fullwidth,
  bgvariant,
  margin,
  disabled,
  loading,
  type,
  padding,
}) => {
  const theme = useTheme();
  return (
    <Box>
      <PrimaryButtonElement
        variant="contained"
        type={type}
        onClick={handleOnClick}
        fullwidth={fullwidth}
        endIcon={endicon}
        startIcon={starticon}
        bgvariant={bgvariant}
        margin={margin}
        disabled={disabled}
        loading={loading}
        padding={padding}
      >
        <Typography variant={typevariant}>{label}</Typography>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: theme.palette.accent.main,
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </PrimaryButtonElement>
    </Box>
  );
};

PrimaryButton.propTypes = {
  ...ButtonPropsType,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  padding: PropTypes.string,
};

export default React.memo(PrimaryButton);
