import React, {useState} from 'react';

import {Outlet} from 'react-router-dom';
import PrimaryAppbar from 'containers/Appbars/PrimaryAppbar';
import PrimarySidebar from 'containers/Sidebars/PrimarySidebar';
import {useMediaQuery} from '@mui/material';
import {KeyboardDoubleArrowLeftOutlined} from '@mui/icons-material';
import {DrawerHeader} from 'utils/drawerHeaderUtility';
import DrawerToggleBtn from 'components/Sidebar/drawerToggleBtn';
import {PrimaryLayout, PrimaryOutlet} from './layout.styles';
import {useSelector} from 'react-redux';
import UserMiddleware from 'middlewares/user.middleware';

export default function PrimaryMainLayout() {
  const [open, setOpen] = useState(true);
  const isNonMobile = useMediaQuery('(min-width: 50em)');

  const handleDrawerOpen = () => {
    setOpen(p => !p);
  };

  const {userInfo} = useSelector(state => state.auth);

  return (
    <UserMiddleware>
      <PrimaryLayout>
        <PrimaryAppbar
          isNonMobile={isNonMobile}
          isAuthenticated={userInfo._id}
          first_name={userInfo.first_name}
          avatar={userInfo.avatar}
          designation={userInfo.role}
          user_id={userInfo._id}
        />
        <PrimarySidebar open={open} user_id={userInfo._id} />
        <DrawerToggleBtn
          icon={<KeyboardDoubleArrowLeftOutlined />}
          handleOnClick={handleDrawerOpen}
          open={open}
        />
        <PrimaryOutlet component="main">
          <DrawerHeader />
          <Outlet />
        </PrimaryOutlet>
      </PrimaryLayout>
    </UserMiddleware>
  );
}
