const {
  styled,
  Button,
  Stack,
  IconButton,
  Tab,
  FormControlLabel,
} = require('@mui/material');

export const PrimaryButtonElement = styled(Button)(
  ({theme, fullwidth, bgvariant, margin, loading, padding}) => ({
    backgroundColor: loading
      ? theme.palette.gray[500]
      : bgvariant === 'light'
      ? theme.palette.primary.main
      : theme.palette.accent.main,
    color:
      bgvariant === 'light'
        ? theme.palette.neutral[1000]
        : theme.palette.neutral[100],
    padding: padding ? padding : '0.9em 2.5em',
    borderRadius: '0.6em',
    textTransform: 'none',
    margin: margin ? margin : 'none',
    width: fullwidth ? '100%' : 'auto',
    height: 'auto',
    position: 'relative',
  })
);

export const OutlinedButtonElement = styled(Button)(
  ({theme, fullwidth, colorVariant, padding, disabled}) => ({
    backgroundColor: 'transparent',
    color:
      colorVariant === 'accent'
        ? theme.palette.accent.main
        : theme.palette.neutral[1000],
    padding: padding ? padding : '0.9em 1.5em',
    borderRadius: '0.6em',
    border: `${
      disabled
        ? 'none'
        : `1px solid ${
            colorVariant === 'accent'
              ? theme.palette.accent.main
              : theme.palette.neutral[1000]
          }`
    }`,
    textTransform: 'none',
    width: fullwidth ? '100%' : 'auto',
  })
);

export const TextButtonElement = styled(Button)(
  ({textcolor, theme, disabled, fullwidth}) => ({
    textTransform: 'none',
    color: disabled ? theme.palette.grey[200] : textcolor,
    flexShrink: 0,
    cursor: disabled && 'crosshair',
    '&:hover': {
      color: disabled ? theme.palette.grey[200] : theme.palette.accent.main,
    },
    width: fullwidth ? '100%' : 'max-content',
  })
);

export const UploadButtonElement = styled(Stack)(({theme}) => ({
  padding: '1em 1.5em',
  backgroundColor: theme.palette.primary.main,
  border: `0.063em dashed ${theme.palette.gray[400]}`,
  borderRadius: '0.25em',
  cursor: 'pointer',
}));

export const PrimaryIconButtonElement = styled(IconButton)(
  ({theme, bgvariant}) => ({
    padding: '0.375em',
    backgroundColor:
      bgvariant === 'light'
        ? theme.palette.primary.main
        : theme.palette.accent.main,
    color:
      bgvariant === 'light'
        ? theme.palette.neutral[1000]
        : theme.palette.neutral[100],
    borderRadius: '0.25em',
    width: 'max-content',
  })
);

export const SecondaryIconButtonElement = styled(IconButton)(({theme}) => ({
  padding: '0.375em',
  backgroundColor: theme.palette.gray[400],
  color: theme.palette.neutral[1000],
  borderRadius: '0.25em',
  width: 'max-content',
}));

export const TabElement = styled(Tab)(({theme, indicator}) => ({
  '&.Mui-selected': {
    color: theme.palette.accent[500],
    borderBottom: indicator ? `1px solid ${theme.palette.accent[500]}` : 'none',
  },
  color: theme.palette.neutral[500],
  borderBottom: indicator ? `1px solid ${theme.palette.neutral[500]}` : 'none',
}));

export const RadioElement = styled(FormControlLabel)(({theme, checked}) => ({
  '& .MuiRadio-root': {
    display: 'none !important',
  },
  backgroundColor: checked && theme.palette.accent.main,
  color: checked && theme.palette.neutral[100],
  width: 'max-content',
  border: `1px solid ${theme.palette.gray[500]}`,
  borderRadius: '0.5em',
  padding: '0.5em',
}));
