import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export const useAuth = (reducer, getCall) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const data = await getCall().unwrap();
      dispatch(reducer(data));
    } catch (err) {
      if (err) {
        navigate('/secure/signin');
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
};
