import React, {useState} from 'react';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import {
  Box,
  IconButton,
  SvgIcon,
  Toolbar,
  useTheme,
  Typography,
  Menu,
  MenuItem,
  Button,
  styled,
  Avatar,
  Stack,
  Badge,
} from '@mui/material';
import Logo from 'assets/general/svgs/logo.svg';
import MuiAppBar from '@mui/material/AppBar';
import {ReactComponent as NotificationOutlined} from 'assets/icons/notification-icon.svg';
// A import {ReactComponent as SettingsOutlined} from 'assets/icons/settings-icon.svg';
import {ReactComponent as HelpOutlined} from 'assets/icons/help-icon.svg';
import TextBtn from 'components/Buttons/textButton';
import {ExpandMore, Person2Outlined} from '@mui/icons-material';
import LogoBox from 'components/Navbar/logoBox';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import WithArrow from 'components/Popovers/withArrow';
import Notification from './notification';
import Help from './help';
import arrayBufferToBase64 from 'utils/arrayBufferToBase64';
import {useSelector} from 'react-redux';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
  borderBottom: 'thin solid',
  boxShadow: 'none',
  background: theme.palette.neutral[100],
  borderColor: theme.palette.indigo[200],
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteAppBarElementringScreen,
    }),
  }),
}));
const PrimaryAppbar = ({isAuthenticated, first_name, avatar, designation}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {allMessages} = useSelector(state => state.notification);

  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notifyAnchorEl, setNotifyAnchorEl] = useState(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);

  // USER MENU
  const isUserMenuOpen = Boolean(userAnchorEl);
  const handleUserMenuClick = event => setUserAnchorEl(event.currentTarget);
  const handleUserMenuClose = () => setUserAnchorEl(null);

  // NOTIFICATION
  const isNotifyOpen = Boolean(notifyAnchorEl);
  const handleNotifyClick = e => setNotifyAnchorEl(e.currentTarget);
  const handleNotifyClose = () => setNotifyAnchorEl(null);
  const notifyPopupId = isNotifyOpen ? 'simple-popover' : undefined;

  // HELP
  const isHelpOpen = Boolean(helpAnchorEl);
  const handleHelpClick = e => setHelpAnchorEl(e.currentTarget);
  const handleHelpClose = () => setHelpAnchorEl(null);
  const helpPopupId = isHelpOpen ? 'simple-popover' : undefined;
  return (
    <AppBar position="fixed">
      <Toolbar>
        <FlexBetween gap={'5.5em'} width={'100%'}>
          <LogoBox logo={Logo} />
          {isAuthenticated && (
            <>
              {/* <Box width="100%" maxWidth={'40em'}>
                <PrimaryInput
                  name={'search'}
                  type={'text'}
                  label={''}
                  title={''}
                  placeholder={'Search'}
                  starticonconfig={
                    <IconButton
                      aria-label="Search Option"
                      onClick={() => console.log('search')}
                    >
                      <SvgIcon component={SearchOutlined} inheritViewBox />
                    </IconButton>
                  }
                  size="small"
                  fullWidth
                />
              </Box> */}

              <FlexBetween gap={'2em'}>
                <IconButton onClick={e => handleNotifyClick(e)}>
                  <Badge
                    badgeContent={Object.values(allMessages).length}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: theme.palette.accent[500],
                        color: '#fff',
                      },
                    }}
                  >
                    <SvgIcon component={NotificationOutlined} inheritViewBox />
                  </Badge>
                </IconButton>
                {/* <IconButton onClick={() => navigate('/app/settings')}>
                  <SvgIcon component={SettingsOutlined} inheritViewBox />
                </IconButton> */}
                <TextBtn color={theme.palette.grey[500]}>
                  <IconButton onClick={e => handleHelpClick(e)}>
                    <SvgIcon component={HelpOutlined} inheritViewBox />
                  </IconButton>
                  <Typography variant="h5">Help</Typography>
                </TextBtn>
                <FlexBetween>
                  <Button
                    onClick={handleUserMenuClick}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textTransform: 'none',
                      gap: '1rem',
                    }}
                  >
                    <Avatar
                      src={
                        avatar &&
                        avatar.data &&
                        arrayBufferToBase64(avatar.data)
                      }
                      alt={first_name}
                    />
                    <Box textAlign="left">
                      <Typography
                        variant="h5"
                        sx={{color: theme.palette.neutral[600]}}
                      >
                        {first_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{color: theme.palette.indigo[400]}}
                      >
                        {designation}
                      </Typography>
                    </Box>
                    <ExpandMore
                      sx={{
                        color: theme.palette.neutral[1000],
                        fontSize: '25px',
                      }}
                    />
                  </Button>
                  <Menu
                    anchorEl={userAnchorEl}
                    open={isUserMenuOpen}
                    onClose={handleUserMenuClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate('/app/settings');
                        handleUserMenuClose();
                      }}
                    >
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Person2Outlined />
                        <Typography variant="h6">View Profile</Typography>
                      </Stack>
                    </MenuItem>
                    {/* <MenuItem onClick={handleSignOut}>
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <LogoutOutlined />
                        <Typography variant="h6">Log Out</Typography>
                      </Stack>
                    </MenuItem> */}
                  </Menu>
                </FlexBetween>
              </FlexBetween>
              <WithArrow
                id={notifyPopupId}
                anchorEl={notifyAnchorEl}
                open={isNotifyOpen}
                handleClose={handleNotifyClose}
              >
                <Notification />
              </WithArrow>
              <WithArrow
                id={helpPopupId}
                anchorEl={helpAnchorEl}
                open={isHelpOpen}
                handleClose={handleHelpClose}
              >
                <Help />
              </WithArrow>
            </>
          )}
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

PrimaryAppbar.propTypes = {
  open: PropTypes.bool,
  isNonMobile: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  first_name: PropTypes.string,
  avatar: PropTypes.string,
  designation: PropTypes.string,
  user_id: PropTypes.string,
};
export default React.memo(PrimaryAppbar);
