import {apiSlice} from './index.slice';

// Auth api slice
export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    signin: builder.mutation({
      query: creds => ({
        url: '/auth/signin',
        method: 'POST',
        body: {...creds},
      }),
    }),
    verify: builder.mutation({
      query: creds => ({
        url: '/auth/verifyCode',
        method: 'POST',
        body: {...creds},
      }),
    }),
    forgotPassword: builder.mutation({
      query: payload => ({
        url: '/auth/forgotPassword',
        method: 'POST',
        body: {...payload},
      }),
    }),
    resetForgotPassword: builder.mutation({
      query: payload => ({
        url: '/auth/resetForgotPassword',
        method: 'POST',
        body: {...payload},
      }),
    }),
    signout: builder.mutation({
      query: creds => ({
        url: '/auth/signout',
        method: 'POST',
        body: {...creds},
      }),
    }),
    getUser: builder.query({
      query: () => ({
        url: '/user/getuser',
        method: 'GET',
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
    }),
    update: builder.mutation({
      query: payload => ({
        url: '/user/update',
        method: 'PATCH',
        body: {...payload},
      }),
    }),
    reset_password: builder.mutation({
      query: payload => ({
        url: '/user/passwordReset',
        method: 'PATCH',
        body: {...payload},
      }),
    }),
  }),
});

export const {
  useSigninMutation,
  useVerifyMutation,
  useLazyGetUserQuery,
  useUpdateMutation,
  useReset_passwordMutation,
  useSignoutMutation,
  useForgotPasswordMutation,
  useResetForgotPasswordMutation,
} = authApiSlice;
