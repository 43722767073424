const {styled} = require('@mui/material');
const {Box} = require('@mui/system');

const FlexBetween = styled(Box)(({justifyContent, alignItems}) => ({
  display: 'flex',
  justifyContent: justifyContent ?? 'space-between',
  alignItems: alignItems ?? 'center',
}));

export default FlexBetween;
