import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Typography, Stack, SvgIcon, Box, useTheme} from '@mui/material';
import DataGridOne from 'components/DataGrids/VariantOne';

import {InvoiceConstants} from 'constants/invoiceConstants';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {Visibility} from '@mui/icons-material';
import {ReactComponent as EditOutlined} from 'assets/icons/edit-icon.svg';
import arrayBufferToBase64 from 'utils/arrayBufferToBase64';
import {superAdmin} from 'constants/userRoles';
import {useSelector} from 'react-redux';
import {debounce} from 'utils/debounce';
import {useGetByQuery} from 'lib/apis/quote.api';

const DataTable = ({state, dispatch, filertUserId, quoteType}) => {
  const navigate = useNavigate();
  const {palette} = useTheme();
  const {showSnack, handleShowSnack} = useSnackbar();
  const {userInfo} = useSelector(state => state.auth);
  const isSuperAdmin = userInfo.role === superAdmin;

  const {isError, isLoading, isSuccess, error, data, isFetching} =
    useGetByQuery(
      {
        page: state.page,
        pageSize: state.pageSize,
        sort: JSON.stringify(state.sort),
        search: state.search,
        fromDate: state.fromDate,
        toDate: state.toDate,
        created_by: filertUserId,
        quoteType: quoteType,
      },
      {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      }
    );

  if (isError) {
    return (
      <BasicSnackbar
        open={isError}
        handleClose={handleShowSnack}
        severity={'error'}
      >
        <Typography>
          {error?.data?.message
            ? error.data.message
            : 'Something went wrong. Please Wait'}
        </Typography>
      </BasicSnackbar>
    );
  }
  const entities = isSuccess ? data.quote.entities : {};
  const totalRows = isSuccess ? data.total : 0;

  const onEditInvoice = params => {
    const {row} = params;
    navigate(`/app/quotes/edit/${row.meta.quoteNumber}`, {
      state: {
        ...row,
        issuer: {
          ...row.issuer,
          logo: arrayBufferToBase64(row.issuer.logo.data),
        },
        listing: row.listings,
        created_by: {
          first_name: row.created_by.first_name,
          last_name: row.created_by.last_name,
          email: row.created_by.email,
          mobile_number: row.created_by.mobile_number,
        },
      },
    });
  };

  const onPreview = params => {
    const {row} = params;

    localStorage.setItem(
      'ABACUS_QUOTE',
      JSON.stringify({
        ...row,
        issuer: {
          ...row.issuer,
          logo: arrayBufferToBase64(row.issuer.logo.data),
        },
        listing: row.listings,
        created_by: {
          first_name: row.created_by.first_name,
          last_name: row.created_by.last_name,
          email: row.created_by.email,
          mobile_number: row.created_by.mobile_number,
        },
        workOrder: false,
      })
    );
    window.open(`/app/quotes/preview/pdf`, '_blank', 'noopener,noreferrer');
  };

  // Destructure id and entities
  const columns = isSuccess
    ? [
        {
          field: 'client',
          headerName: 'Customer/Company Name',
          // cellClassName: 'name-column--cell',
          width: 200,
          renderCell: params => (
            <Typography variant={'h5'}>
              {params.row.recipient.company_name}
            </Typography>
          ),
        },
        {
          field: 'quoteNumber',
          headerName: 'Quote ID',
          width: 140,
          renderCell: params => (
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={2}
              position={'relative'}
            >
              <Typography
                variant={'regular16'}
                sx={{color: palette.neutral[600]}}
              >
                {params.row.meta.quoteNumber}
              </Typography>
              <Box
                bgcolor={palette.error.main}
                position={'absolute'}
                top={0}
                right={'-10px'}
                width={6}
                height={6}
                borderRadius={'100%'}
              ></Box>
            </Stack>
          ),
        },
        {
          field: 'quoteType',
          headerName: 'Quote Status',
          flex: 1,
          sortable: false,
          renderHeader: params => {
            return (
              <Typography variant="h6">{params.colDef.headerName}</Typography>
            );
          },
          renderCell: params => {
            return (
              <Stack>
                <Typography sx={{cursor: 'pointer'}} variant={'h6'}>
                  {String(params.row.meta.quote_type).toUpperCase()}
                </Typography>
              </Stack>
            );
          },
        },
        {
          field: 'updated_at',
          headerName: 'Date',
          flex: 1,
          renderCell: params => (
            <Typography variant={'h6'}>
              {new Date(params.row.updated_at).toLocaleDateString()}
            </Typography>
          ),
        },
        {
          field: 'amount',
          headerName: 'Amount (AED)',
          flex: 1,
          renderCell: params => (
            <Typography variant={'h5'}>
              {parseFloat(params.row.summary.payable)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          ),
        },
        {
          field: 'created_by',
          headerName: 'Employee',
          flex: 1,
          renderCell: params => {
            return (
              <Typography variant={'h5'}>
                {params.row.created_by.first_name}{' '}
                {params.row.created_by.last_name}
              </Typography>
            );
          },
        },
        {
          field: 'actions',
          headerName: 'Edit',
          type: 'actions',
          width: 100,
          getActions: params => [
            <GridActionsCellItem
              onClick={() =>
                params.row.paymentDetails.payment_records.length !== 0
                  ? onPreview(params)
                  : params.row.meta.quote_type === 'Official'
                  ? isSuperAdmin
                    ? onEditInvoice(params)
                    : onPreview(params)
                  : onEditInvoice(params)
              }
              key={2}
              icon={
                params.row.paymentDetails.payment_records.length !== 0 ? (
                  <SvgIcon
                    component={Visibility}
                    inheritViewBox
                    fontSize={'medium'}
                  />
                ) : params.row.meta.quote_type === 'Official' ? (
                  isSuperAdmin ? (
                    <SvgIcon
                      component={EditOutlined}
                      inheritViewBox
                      fontSize={'medium'}
                    />
                  ) : (
                    <SvgIcon
                      component={Visibility}
                      inheritViewBox
                      fontSize={'medium'}
                    />
                  )
                ) : (
                  <SvgIcon
                    component={EditOutlined}
                    inheritViewBox
                    fontSize={'medium'}
                  />
                )
              }
              label={
                params.row.meta.quote_type === 'Official'
                  ? isSuperAdmin
                    ? 'Edit'
                    : 'Preview'
                  : 'Edit'
              }
            />,
          ],
        },
      ]
    : [];

  return (
    <>
      <Typography variant="medium20">
        {InvoiceConstants.sectionTwo.table.heading}
      </Typography>
      <DataGridOne
        loading={isLoading || isFetching}
        columns={columns}
        data={entities ? Object.values(entities) : []}
        rowCount={totalRows}
        pagination
        page={state.page}
        pageSize={state.pageSize}
        paginationMode="server"
        sortingMode="server"
        onPageChange={newPage => dispatch({type: 'SET_PAGE', payload: newPage})}
        onPageSizeChange={newPageSize =>
          dispatch({type: 'SET_PAGE_SIZE', payload: newPageSize})
        }
        onSortModelChange={newSortModel =>
          debounce(dispatch({type: 'SET_SORT', payload: newSortModel[0]}), 300)
        }
        experimentalFeatures={{
          lazyLoading: true,
        }}
      />
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </>
  );
};

DataTable.propTypes = {
  invoices: {
    id: PropTypes.string,
    entities: PropTypes.object,
  },
  state: PropTypes.object,
  dispatch: PropTypes.any,
  filertUserId: PropTypes.string,
  quoteType: PropTypes.string,
};

export default DataTable;

const StatusFilterComponent = ({value, dispatch}) => {
  const {invoice_status_filter_options: statusOptions} =
    InvoiceConstants.sectionTwo.table;
  return (
    <Stack>
      <FormControl sx={{m: 3}} component="fieldset" variant="standard">
        <FormGroup>
          {statusOptions.map((item, id) => {
            return (
              <FormControlLabel
                key={id}
                checked={value === item}
                control={
                  <Checkbox
                    onChange={() =>
                      value === item
                        ? dispatch({type: 'SET_SEARCH', payload: ''})
                        : dispatch({type: 'SET_SEARCH', payload: item})
                    }
                    name={item}
                  />
                }
                label={item}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Stack>
  );
};

StatusFilterComponent.propTypes = {
  value: PropTypes.string,
  dispatch: PropTypes.func,
};
