import {PDFViewer} from '@react-pdf/renderer';
import PrimaryQuotePDF from 'components/templates/pdf/quote/primary';
import WorkOrderPDF from 'components/templates/pdf/quote/workOrder';
import {useLocation} from 'react-router-dom';

const PrimaryQuotePDFView = () => {
  const {pathname} = useLocation();
  const quoteData = JSON.parse(localStorage.getItem('ABACUS_QUOTE'));

  return (
    <PDFViewer
      width={'100%'}
      height={window.innerHeight - 10}
      showToolbar={pathname.includes('export')}
    >
      {quoteData.workOrder ? (
        <WorkOrderPDF state={quoteData} />
      ) : (
        <PrimaryQuotePDF state={quoteData} />
      )}
    </PDFViewer>
  );
};

export default PrimaryQuotePDFView;
