import React, {useEffect, useState} from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import lockIllustration from 'assets/auth/svgs/lock-illus.svg';
import PrimaryHead from 'components/Mastheads/primary';
import PrimaryInput from 'components/Inputs/primary';
import {useLocation, useNavigate} from 'react-router-dom';
import {ResetPWSchema} from 'constants/formValidationSchemas';
import {useFormik} from 'formik';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import {useResetForgotPasswordMutation} from 'lib/apis/authApi.slice';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import PrimaryButton from 'components/Buttons/primaryButton';

const ResetPassword = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.state.username) {
      navigate('/secure/signin');
    }
  }, [location]);

  const theme = useTheme();
  const [showHidden, setShowHidden] = useState({
    otp: false,
    newPassword: false,
    confirmPassword: false,
  });
  const isNonMobile = useMediaQuery('(min-width: 50em)');
  const navigate = useNavigate();

  const [restore, {isLoading}] = useResetForgotPasswordMutation();
  const {showSnack, handleShowSnack} = useSnackbar();

  const handleSubmit = async values => {
    try {
      const {message} = await restore({
        username: location.state.username,
        verification_code: values.otp,
        newPassword: values.newPassword,
      }).unwrap();
      handleShowSnack(true, message, 'success');
      setTimeout(() => {
        navigate('/secure/signin');
      }, 3000);
    } catch (err) {
      handleShowSnack(true, err.data.message, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: ResetPWSchema,
    onSubmit(values) {
      handleSubmit(values);
    },
  });

  return (
    <Box
      width="100%"
      height="100%"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'4.25em'}
      padding={isNonMobile ? '3em 0 0' : '8.75em 0'}
    >
      <Box
        backgroundColor={theme.palette.neutral[100]}
        padding={'3em 2.25em'}
        borderRadius={'0.5em'}
        width={isNonMobile ? '32.625em' : '90%'}
      >
        <PrimaryHead
          title={'Reset Password'}
          subtitle={
            'Please enter the verification code and create a new password.'
          }
          color={theme.palette.grey[500]}
        >
          <Box
            component="img"
            alt="Lock Illustration"
            src={lockIllustration}
            height="100%"
            width="100%"
            sx={{objectFit: 'cover', maxWidth: '10.375em'}}
          />
        </PrimaryHead>
        {/* FORM */}
        <Box width={'100%'}>
          <form onSubmit={formik.handleSubmit}>
            <FlexBetween
              width={'100%'}
              flexDirection={'column'}
              gap={'1em'}
              padding={'3em 0 2.25em'}
            >
              <PrimaryInput
                name={'otp'}
                type={showHidden.otp ? 'text' : 'password'}
                label={''}
                title={'Enter OTP'}
                placeholder={'Enter OTP'}
                value={formik.values.otp}
                onChange={formik.handleChange}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helpertext={formik.touched.otp && formik.errors.otp}
                fullWidth
                endiconconfig={
                  <IconButton
                    aria-label="toggle otp visibility"
                    onClick={() =>
                      setShowHidden({
                        ...showHidden,
                        otp: !showHidden.otp,
                      })
                    }
                    edge="end"
                  >
                    {showHidden.otp ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                }
              />
              <PrimaryInput
                name={'newPassword'}
                type={showHidden.newPassword ? 'text' : 'password'}
                label={''}
                title={'Enter New Password'}
                placeholder={'Enter new password'}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helpertext={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                fullWidth
                endiconconfig={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowHidden({
                        ...showHidden,
                        newPassword: !showHidden.newPassword,
                      })
                    }
                    edge="end"
                  >
                    {showHidden.newPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                }
              />
              <PrimaryInput
                name={'confirmPassword'}
                type={showHidden.confirmPassword ? 'text' : 'password'}
                label={''}
                title={'Confirm Password'}
                placeholder={'Enter password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helpertext={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                fullWidth
                endiconconfig={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowHidden({
                        ...showHidden,
                        confirmPassword: !showHidden.confirmPassword,
                      })
                    }
                    edge="end"
                  >
                    {showHidden.confirmPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                }
              />
            </FlexBetween>
            <PrimaryButton
              type="submit"
              fullwidth={true}
              disabled={isLoading}
              loading={isLoading}
              sx={{
                backgroundColor: theme.palette.accent.main,
                color: theme.palette.neutral[100],
                padding: '1em',
                borderRadius: '0.8em',
                textTransform: 'none',
              }}
              label={'Reset Password'}
            />
          </form>
        </Box>
      </Box>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </Box>
  );
};

export default ResetPassword;
