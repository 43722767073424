import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

const NoteSection = ({meta, summary}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      gap: 5,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          Notes
        </Text>
        {meta.notes.map((note, id) => {
          return (
            <Text
              key={id}
              style={{
                fontSize: '10px',
                fontWeight: 100,
              }}
            >
              {note}
            </Text>
          );
        })}
      </View>
      <View style={{...styles.block, alignItems: 'flex-end'}}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          Number of days
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            textAlign: 'right',
          }}
        >
          {summary.days}
        </Text>
      </View>
    </View>
  );
};

export default NoteSection;
