import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

const MetaSection = ({
  meta, 
  //summary
}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      gap: 5,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Quote Number
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {meta.quoteNumber}
        </Text>
      </View>
      <View style={{...styles.block, alignItems: 'flex-end'}}>
       {/* <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Amount Due
        </Text>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          AED {summary.payable}
        </Text> */}
      </View>
    </View>
  );
};

export default MetaSection;
