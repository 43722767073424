import React, {useState} from 'react';
import {Typography, useTheme} from '@mui/material';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import PropTypes from 'prop-types';

const DatePickerOne = ({
  handledatechange,
  dateval,
  variant,
  placeholder,
  minDate,
}) => {
  const theme = useTheme();
  const {palette} = theme;
  const [showPicker, setShowPicker] = useState(false);

  const handleShowPicker = () => {
    setShowPicker(p => !p);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        closeOnSelect
        onClose={() => handleShowPicker()}
        value={dateval ? dateval : ''}
        open={showPicker}
        onChange={newValue => {
          handledatechange(newValue);
        }}
        minDate={minDate}
        renderInput={params => {
          let date;
          if (params.inputProps.value) {
            const arr = params.inputProps.value.split('/');
            date = `${arr[1]}/${arr[0]}/${arr[2]}`;
          }
          return (
            <Typography
              ref={params.ref}
              variant={variant}
              color={palette.neutral[500]}
              onClick={() => handleShowPicker()}
              sx={{
                cursor: 'pointer',
              }}
            >
              {params.inputProps.value ? date : placeholder}
            </Typography>
          );
        }}
      />
    </LocalizationProvider>
  );
};

DatePickerOne.propTypes = {
  handledatechange: PropTypes.func,
  dateval: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  minDate: PropTypes.any,
};

export default DatePickerOne;
