import React from 'react';
import {Image, StyleSheet, Text, View} from '@react-pdf/renderer';

const ContactSection = ({issuer, recipient}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      gap: 5,
    },
    logo: {
      width: '100px',
      height: 'auto',
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.block}>
        {issuer.logo && <Image style={styles.logo} src={issuer.logo} />}
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 100,
            color: '#4F4F4F',
            marginTop: "20px",
          }}
        >
          {issuer.company_name}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#4F4F4F',
          }}
        >
          {issuer.name}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#4F4F4F',
          }}
        >
          {issuer.mobile_number}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
          }}
        >
          {issuer.address_one}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
          }}
        >
          {issuer.address_two}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
            textAlign: 'right',
          }}
        >
          {issuer.city}, {issuer.state}, {issuer.country} - {issuer.pincode}
        </Text>
      </View>
      <View style={{...styles.block, alignItems: 'flex-end'}}>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
            textAlign: 'right',
          }}
        >
          Bill To
        </Text>
        <Text
          style={{
            fontSize: '16px',
            fontWeight: 100,
            color: '#4F4F4F',
            textAlign: 'right',
          }}
        >
          {recipient.company_name}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#4F4F4F',
            textAlign: 'right',
          }}
        >
          {recipient.name}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#4F4F4F',
            textAlign: 'right',
          }}
        >
          {recipient.mobile_number}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
            textAlign: 'right',
          }}
        >
          {recipient.address_one}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
            textAlign: 'right',
          }}
        >
          {recipient.address_two}
        </Text>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
            color: '#6F6F6F',
            textAlign: 'right',
          }}
        >
          {recipient.city}, {recipient.state}, {recipient.country} -{' '}
          {recipient.pincode}
        </Text>
      </View>
    </View>
  );
};

export default ContactSection;
