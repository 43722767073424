import React from 'react';

const {Close} = require('@mui/icons-material');
const {countries, months, currencies, timeZones} = require('./dataset.js');

export const SettingsConstants = {
  heading: 'Settings',
  links: [
    {
      tab: 'Account',
      link: '/settings/account',
    },
    {
      tab: 'Business',
      link: '/settings/business',
    },
  ],
  accountForm: {
    heading: 'User Information',
    desc: 'Here you can edit public information about yourself. The changes will be displayed for other users instantly.',
    fNameInput: {
      id: 'first_name',
      name: 'first_name',
      type: 'text',
      title: 'First Name',
      placeholder: 'Enter your first name',
    },
    lNameInput: {
      id: 'last_name',
      name: 'last_name',
      type: 'text',
      title: 'Last Name',
      placeholder: 'Enter your last name',
    },
    emailInput: {
      id: 'email',
      name: 'email',
      type: 'email',
      title: 'Email Address',
      placeholder: 'Enter your email',
    },
    emailOption: 'Change Email Address',
    pwInput: {
      id: 'password',
      name: 'password',
      type: 'password',
      title: 'Password',
      placeholder: 'Enter your password',
    },
    pwOption: 'Change Password',
    button: {
      label: 'Save',
    },
  },
  profile: {
    heading: 'Profile Photo',
    btnLabel: 'Upload Photo',
    avatarAlt: 'Avatar',
  },
  password: {
    heading: 'Change Password',
    closeIcon: <Close />,
    oldPwInput: {
      id: 'old_password',
      name: 'old_password',
      type: 'password',
      title: 'Current Password',
      placeholder: 'Enter your current password',
    },
    newPwInput: {
      id: 'new_password',
      name: 'new_password',
      type: 'password',
      title: 'New Password',
      placeholder: 'Enter your new password',
    },
    confirmPwInput: {
      id: 'confirm_password',
      name: 'confirm_password',
      type: 'password',
      title: 'Confirm Password',
      placeholder: 'Enter your new password again',
    },
    button: {
      label: 'Reset Password',
    },
  },
  businessForm: {
    heading1: 'Business Details',
    nameInput: {
      id: 'name',
      name: 'name',
      type: 'text',
      title: 'Business Name',
      placeholder: 'Business name',
    },
    phoneInput: {
      id: 'phone_number',
      name: 'phone_number',
      type: 'tel',
      title: 'Business Phone Number',
      placeholder: 'Phone Number',
    },
    mobileInput: {
      id: 'mobile_number',
      name: 'mobile_number',
      type: 'tel',
      title: 'Mobile Number',
      placeholder: 'Mobile Number',
    },
    addrL1Input: {
      id: 'address_line1',
      name: 'address_line1',
      type: 'text',
      title: 'Address Line One',
      placeholder: 'Address Line One',
    },
    addrL2Input: {
      id: 'address_line2',
      name: 'address_line2',
      type: 'text',
      title: 'Address Line Two',
      placeholder: 'Address Line Two',
    },
    cityInput: {
      id: 'city',
      name: 'city',
      type: 'text',
      title: 'City',
      placeholder: 'City',
    },
    stateInput: {
      id: 'state',
      name: 'state',
      type: 'text',
      title: 'State',
      placeholder: 'Select a state',
    },
    pinInput: {
      id: 'pincode',
      name: 'pincode',
      type: 'text',
      title: 'Pincode',
      placeholder: 'Pincode',
    },
    countryInput: {
      id: 'country',
      name: 'country',
      type: 'text',
      title: 'Country',
      placeholder: 'Select a country',
      options: ['Select a country', ...countries],
    },
    heading2: 'Preferences',
    currencyInput: {
      id: 'base_currency',
      name: 'base_currency',
      type: 'text',
      title: 'Base Currency',
      placeholder: 'Select a base currency',
      options: ['Select a base currency', ...currencies],
    },
    timezoneInput: {
      id: 'timezone',
      name: 'timezone',
      type: 'text',
      title: 'Business Time Zone',
      placeholder: 'Select a business time zone',
      options: ['Select a business time zone', ...timeZones],
    },
    fiscalMonthInput: {
      id: 'fiscal_month',
      name: 'fiscal_month',
      type: 'text',
      title: 'Fiscal Year End Month',
      placeholder: 'Select a Fiscal Year End Month',
      options: ['Select a Fiscal Year End Month', ...months],
    },
    fiscalDayInput: {
      id: 'fiscal_day',
      name: 'fiscal_day',
      type: 'text',
      title: 'Fiscal Year End Day',
      placeholder: 'Select a Fiscal Year End Day',
      options: [
        'Select a Fiscal Year End Day',
        ...Array.from({length: 31}, (_, index) => index + 1),
      ],
    },
    dateFormInput: {
      id: 'date_format',
      name: 'date_format',
      type: 'text',
      title: 'Date Format',
      placeholder: 'Select a Date Format',
      options: ['Select a Date Format', 'DD-MM-YYYY'],
    },
    button: {
      type: 'submit',
      variant: 'primary',
      label: 'Save',
    },
  },
};
