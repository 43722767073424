import * as yup from 'yup';

// For Signin
export const SignInSchema = yup.object({
  username: yup
    .string('username')
    .min(2, 'Enter a valid Username')
    .required('Username is required'),
  password: yup
    .string('Enter your password')
    .min(2, 'Password should be of minimum 2 characters length')
    .required('Password is required'),
});

// For verification
export const MfaSchema = yup.object({
  otp: yup
    .string('Enter The OTP')
    .min(4, 'OTP should be of minimum 4 characters length')
    .max(4, 'OTP should be of maximum 4 characters length')
    .required('OTP is required'),
});

export const ReqForgotPWSchema = yup.object({
  username: yup.string('Enter your username').required('Username is required'),
});

export const ResetPWSchema = yup.object({
  otp: yup
    .string('Enter The OTP')
    .min(4, 'OTP should be of minimum 4 characters length')
    .max(4, 'OTP should be of maximum 4 characters length')
    .required('OTP is required'),
  newPassword: yup
    .string('Enter your password')
    .min(2, 'Password should be of minimum 2 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string('Confirm your password')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

// For user settings
export const UserSettingsSchema = yup.object({
  first_name: yup.string('first_name').required('First name is required'),
  last_name: yup.string('last_name').required('Last name is required'),
  email: yup.string('email').required('email is required'),
});

// For password reset
export const PasswordResetSchema = yup.object({
  old_password: yup
    .string('old_password')
    .min(2, 'Password should be of minimum 2 characters length')
    .required('Old password is required'),
  new_password: yup
    .string('new_password')
    .min(2, 'Password should be of minimum 2 characters length')
    .required('New Password is required'),
  confirm_password: yup
    .string('confirm_password')
    .min(2, 'Password should be of minimum 2 characters length')
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm your password'),
});

// For business settings
export const businessSettingsSchema = yup.object({
  name: yup.string('name').required('Name is required'),
  phone_number: yup
    .string('phone_number')
    .min(4, 'Phone number should be of minimum 4 characters length')
    .required('Phone number is required'),
  mobile_number: yup
    .string('mobile_number')
    .min(4, 'Mobile number should be of minimum 4 characters length')
    .required('Mobile number is required'),
  address_line1: yup.string('address_line1').required('Address is required'),
  address_line2: yup.string('address_line1'),
  city: yup.string('city').required('City is required'),
  state: yup.string('state').required('State is required'),
  pincode: yup.string('pincode'),
  country: yup.string('country').required('Country is required'),
  base_currency: yup.string('base_currency'),
  timezone: yup.string('timezone'),
  fiscal_month: yup.string('fiscal_month'),
  fiscal_day: yup.string('fiscal_day'),
  date_format: yup.string('date_format'),
});

// For payment form
export const PaymentSchema = yup.object({
  company_name: yup.string('company_name').required('Company name is required'),
  representative_name: yup
    .string('representative_name')
    .required('Client name is required'),
  date: yup.string('date').required('Date is required'),
  payment_type: yup.string('payment_type').required('Payment type is required'),
  amount: yup.number('amount').required('Amount is required'),
  quoteNumber: yup.string('quoteNumber').required('Quote Number is required'),
  advance_payment: yup.boolean('advance_payment').optional(),
  note: yup.string('note').optional(),
});

// For client delete
export const RemoveConfirmationSchema = yup.object({
  del: yup
    .string('del')
    .matches('permanently delete', 'Please enter exact keywords to proceed')
    .required('Confirmation is required.'),
});

// For client form
export const ClientSchema = yup.object({
  company_name: yup.string('company_name').required('Company name is required'),
  representative_name: yup
    .string('representative_name')
    .required('Client name is required'),
  role: yup.string('role').required('Role is required'),
  mobile_number: yup
    .string('mobile_number')
    .required('Mobile Number is required'),
  email: yup
    .string('email')
    .required('email is required')
    .email('Email should be valid'),
  address_one: yup.string('address_one').required('Address is required'),
  address_two: yup.string('address_two').optional(),
  city: yup.string('city').required('City is required'),
  pincode: yup.string('pincode').required('Pincode is required'),
  state: yup.string('state').required('State is required'),
  country: yup.string('country').required('Country is required'),
  bank_name: yup.string('bank_name').required('Bank Name is required'),
  bank_account_number: yup
    .string('bank_account_number')
    .required('Bank Account Number is required'),
  bank_iban_code: yup
    .string('bank_iban_code')
    .required('Bank IBAN Code is required'),
});

// For sales rep settings
export const SalesRepSettingsSchema = yup.object({
  first_name: yup.string('first_name').required('First name is required'),
  last_name: yup.string('last_name').required('Last name is required'),
  email: yup.string('email').required('email is required'),
  mobile_number: yup
    .string('mobile_number')
    .required('Mobile Number is required'),
  role: yup.string('role').required('Role is required'),
  designation: yup.string('designation').required('Designation is required'),
});

// For creating inventory item
export const CreateProductSchema = yup.object({
  name: yup.string('name').required('Name is required'),
  description: yup.string('description'),
  category_id: yup.string('category_id'),
  category_name: yup.string('category_name').required('Category is required'),
  price: yup.number('price').required('Price is required'),
  totalQty: yup.number('totalQty').required('Quantity is required'),
  sku: yup.string('sku'),
  stock_status: yup.string('stock_status'),
});

// For invoice

export const InvoiceSchema = yup.object({
  issued_by_company_name: yup
    .string('issued_by_company_name')
    .required('Company Name is required'),
  issued_by_mobile_number: yup
    .string('issued_by_mobile_number')
    .required('Mobile number is required'),
  issued_by_address_line_one: yup
    .string('issued_by_address_line_one')
    .required('Address is required'),
  issued_by_address_line_two: yup
    .string('issued_by_address_line_two')
    .notRequired(),
  issued_by_city: yup.string('issued_by_city').required('City is required'),
  issued_by_state: yup.string('issued_by_state'),
  issued_by_pincode: yup
    .string('issued_by_pincode')
    .required('Pincode is required'),
  issued_by_country: yup
    .string('issued_by_country')
    .required('Country is required'),
  billed_to_company_name: yup
    .string('billed_to_company_name')
    .required('Company name is required'),
  billed_to_mobile_number: yup
    .string('billed_to_mobile_number')
    .required('Mobile number is required'),
  billed_to_address_line_one: yup
    .string('billed_to_address_line_one')
    .required('Address is required'),
  billed_to_address_line_two: yup
    .string('billed_to_address_line_two')
    .notRequired(),
  billed_to_city: yup.string('billed_to_city').required('City is required'),
  billed_to_state: yup.string('billed_to_state'),
  billed_to_pincode: yup
    .string('billed_to_pincode')
    .required('Pincode is required'),
  billed_to_country: yup
    .string('billed_to_country')
    .required('Country is required'),
  invoice_number: yup
    .string('invoice_number')
    .required('Invoice number is required'),
  issue_date: yup.string('issue_date').required('Issue date is required'),
  valid_thru: yup.string('valid_thru').required('Valid Thru is required'),
  notes: yup.string('notes').required('Notes is required'),
  setup_date: yup.string('setup_date').required('Setup date is required'),
  show_date: yup.string('show_date').required('Show date is required'),
  dismantle_date: yup
    .string('dismantle_date')
    .required('Dismantle date is required'),
  total_amount: yup.string('total_amount'),
  additional_charges: yup.string('additional_charges'),
  vat: yup.string('vat'),
  discount: yup.string('discount'),
  payable: yup.string('payable'),
  bank_name: yup.string('bank_name'),
  bank_account_number: yup.string('bank_account_number'),
  bank_iban_code: yup.string('bank_iban_code'),
  bank_swift_code: yup.string('bank_swift_code'),
  quotes: yup.string('quotes'),
  payment_quotes: yup.string('payment_quotes'),
  quote_type: yup.string('quote_type').required('Quote type is required'),
});
