import React from 'react';
import {ButtonPropsType} from 'types/buttonTypes';
import {TextButtonElement} from './buttons.style';
import {useTheme} from '@mui/material';

const TextButton = ({
  handleOnClick,
  startIcon,
  endIcon,
  children,
  disabled,
  fullwidth,
}) => {
  const theme = useTheme();
  return (
    <TextButtonElement
      // {...props}
      onClick={e => handleOnClick(e)}
      startIcon={startIcon && startIcon}
      endIcon={endIcon && endIcon}
      variant="text"
      textcolor={theme.palette.neutral[600]}
      disabled={disabled}
      fullWidth={fullwidth}
    >
      {children}
    </TextButtonElement>
  );
};

TextButton.propTypes = {
  ...ButtonPropsType,
};

export default TextButton;
