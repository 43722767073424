export const colorTokens = {
  neutral: {
    100: '#ffffff',
    150: '#F9FAFB',
    200: '#A2A2A2',
    300: '#9FA1A6',
    400: '#636262',
    500: '#5f5f5f',
    600: '#1C1C1C',
    700: '#53585D',
    1000: '#000000',
  },
  indigo: {
    100: '#e2e2e2',
    200: '#cdcdcd',
    300: '#a9a9a9',
    400: '#8a8a8a',
    500: '#6f6f6f',
    600: '#595959',
    700: '#434343',
    800: '#2c2c2c',
    900: '#161616',
  },
  accent: {
    400: '#EDDCCE',
    500: '#EBA084',
  },
  primary: {
    100: '#fefdfd',
    200: '#fdfcfb',
    300: '#fdfaf8',
    400: '#fcf9f6',
    500: '#fbf7f4',
    600: '#c9c6c3',
    700: '#979492',
    800: '#646362',
    900: '#323131',
  },
  success: {
    100: '#d9f5dc',
    200: '#b4eab9',
    300: '#8ee097',
    400: '#69d574',
    500: '#43cb51',
    600: '#36a241',
    700: '#287a31',
    800: '#1b5120',
    900: '#0d2910',
  },
  warning: {
    100: '#fff8dc',
    200: '#fff1b4',
    300: '#ffe98e',
    400: '#ffe369',
    500: '#ffdc43',
    600: '#e1c936',
    700: '#b4a628',
    800: '#867b1b',
    900: '#594e0d',
  },
  error: {
    100: '#fcdddd',
    200: '#f9bcbc',
    300: '#f69a9a',
    400: '#f37979',
    500: '#f05757',
    600: '#c04646',
    700: '#903434',
    800: '#602323',
    900: '#301111',
  },
  gray: {
    100: '#f3f3f3',
    200: '#e8e8e8',
    300: '#dcdcdc',
    400: '#d1d1d1',
    500: '#c5c5c5',
    600: '#9e9e9e',
    700: '#767676',
    800: '#4A4A4A',
    900: '#272727',
  },
};
