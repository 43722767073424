/* eslint-disable capitalized-comments */
import {
  BarChartOutlined,
  DashboardOutlined,
  Inventory2Outlined,
  LogoutOutlined,
  PaidOutlined,
  PeopleOutline,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import React from 'react';
export const drawerWidth = 240;

export const NavItems = [
  {
    text: 'Dashboard',
    icon: <DashboardOutlined />,
  },
  {
    text: 'Quotes',
    icon: <ReceiptLongOutlined />,
  },
  {
    text: 'Payments',
    icon: <PaidOutlined />,
  },
  {
    text: 'Contacts',
    icon: <PeopleOutline />,
  },
  {
    text: 'Sales',
    icon: <BarChartOutlined />,
  },
  {
    text: 'Inventory',
    icon: <Inventory2Outlined />,
  },
];
export const SecondaryNavItems = [
  {
    text: 'Log Out',
    icon: <LogoutOutlined />,
  },
];

// import { ReactComponent as DashOutlined } from "assets/icons/dash-icon.svg";
// import { ReactComponent as InvoiceOutlined } from "assets/icons/invoice-icon.svg";
// import { ReactComponent as SalesOutlined } from "assets/icons/sales-icon.svg";
// import { ReactComponent as PaymentOutlined } from "assets/icons/payment-icon.svg";
// import { ReactComponent as InventoryOutlined } from "assets/icons/inventory-icon.svg";
// import { ReactComponent as UserGroupOutlined } from "assets/icons/user_group-icon.svg";
// import { SvgIcon } from "@mui/material";
// Use of SvgIcon from MUI
// icon: <SvgIcon component={InventoryOutlined} inheritViewBox />,
