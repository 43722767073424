import {apiSlice} from './index.slice';

// Business api slice
export const businessApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUserBusiness: builder.query({
      query: () => ({
        url: '/businesses/getDetailsByUser',
        method: 'GET',
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
    }),
    updateBusiness: builder.mutation({
      query: payload => ({
        url: '/businesses/updateDetailsByUser',
        method: 'PATCH',
        body: {...payload},
      }),
    }),
  }),
});

export const {useLazyGetUserBusinessQuery, useUpdateBusinessMutation} =
  businessApiSlice;
