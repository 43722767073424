import React from 'react';
import {ListItemText} from '@mui/material';

import PropTypes from 'prop-types';
import {
  SecondaryListItemElement,
  SecondaryListItemButtonElement,
  SecondaryListItemIconElement,
} from './elements.style';

const SecondaryItem = ({icon, text, open, handleOnClick}) => {
  return (
    <SecondaryListItemElement disablePadding>
      <SecondaryListItemButtonElement
        open={open}
        onClick={() => handleOnClick()}
      >
        <SecondaryListItemIconElement open={open}>
          {icon}
        </SecondaryListItemIconElement>
        <ListItemText
          primary={text}
          sx={{opacity: open ? 1 : 0, marginRight: '0.5em'}}
        />
      </SecondaryListItemButtonElement>
    </SecondaryListItemElement>
  );
};

export default React.memo(SecondaryItem);

SecondaryItem.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  open: PropTypes.bool,
  handleOnClick: PropTypes.func,
};
