import {
  initialIssuer,
  initialMeta,
  initialPaymentDetail,
  initialRecipient,
  initialSummary,
} from 'constants/quote.constants';
import {useSelector} from 'react-redux';

export const creatQuoteState = () => {
  const {userInfo} = useSelector(state => state.auth);
  const {businessData} = useSelector(state => state.business);
  return {
    id: null,
    issuer: {
      logo: initialIssuer.logo,
      id: businessData._id,
      company_name: 'Abacus Audio Visual Rental LLC',
      name: businessData.name,
      mobile_number: businessData.mobile_number,
      address_one: businessData.address_line1,
      address_two: businessData.address_line2,
      city: businessData.city,
      state: businessData.state,
      country: businessData.country,
      pincode: businessData.pincode,
    },
    recipient: initialRecipient,
    meta: initialMeta,
    listing: [],
    summary: initialSummary,
    paymentDetails: initialPaymentDetail,
    created_by: {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      mobile_number: userInfo.mobile_number,
    },
  };
};
