import {colorTokens} from 'constants/colorConstants';
// Color design tokens export

// Mui theme settings
export const themeSettings = {
  palette: {
    primary: {
      ...colorTokens.primary,
      main: colorTokens.primary[500],
    },
    neutral: {
      ...colorTokens.neutral,
      main: colorTokens.neutral[500],
    },
    accent: {
      ...colorTokens.accent,
      main: colorTokens.accent[500],
    },
    indigo: {
      ...colorTokens.indigo,
      main: colorTokens.indigo[500],
    },
    success: {
      ...colorTokens.success,
    },
    warning: {
      ...colorTokens.warning,
    },
    error: {
      ...colorTokens.error,
    },
    gray: {
      ...colorTokens.gray,
      main: colorTokens.gray[500],
    },
  },
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
    fontSize: 12,
    // REGULAR FONTS
    regular24: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '28px',
    },
    regular20: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '23px',
    },
    regular16: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '19px',
    },
    regular14: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
    },
    // MEDIUM FONTS
    medium36: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 36,
      fontWeight: 500,
      lineHeight: '28px',
    },
    medium24: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '28px',
    },
    medium20: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '23px',
    },
    medium16: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
    },
    medium14: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
    },
    h1: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 40,
      fontWeight: 600,
    },
    h2: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '38px',
    },
    h3: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '28px',
    },
    h4: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '23px',
    },
    h5: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
    },
    h6: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '16px',
    },
    body2: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 32,
      fontWeight: 400,
      lineHeight: '38px',
    },
    body3: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '28px',
    },
    body4: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 20,
      fontWeight: 400,
      lineHeight: '23px',
    },
    body5: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '19px',
    },
    body6: {
      fontFamily: ['Work Sans', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
    },
  },
  boxShadows: {
    shadow1: '2px 6px 56px rgba(0, 0, 0, 0.12)',
  },
  transitions: {
    easing: {
      sharp: 'linear',
      ease: 'ease',
      easeIn: 'ease-in',
      easeOut: 'ease-out',
    },
    duration: {1: '0.1ms', 2: '0.2ms', 3: '0.3ms'},
  },
};
