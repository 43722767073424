/* eslint-disable react/display-name */
import {Slide, Snackbar} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const BasicSnackbar = ({open, handleClose, severity, children}) => {
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={open}
      autoHideDuration={4000}
      onClose={() => handleClose(false, '', '')}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={() => handleClose(false, '', '')}
        severity={severity}
        sx={{width: '100%'}}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};

BasicSnackbar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  severity: PropTypes.string,
  children: PropTypes.element,
};

export default React.memo(BasicSnackbar);
