export default function calculateTimeElapsed(timestamp) {
  const now = Date.now();
  const timeDifference = now - timestamp;
  const secondsElapsed = Math.floor(timeDifference / 1000);
  const minutesElapsed = Math.floor(secondsElapsed / 60);
  const hoursElapsed = Math.floor(minutesElapsed / 60);
  const daysElapsed = Math.floor(hoursElapsed / 24);
  const weeksElapsed = Math.floor(daysElapsed / 7);
  const monthsElapsed = Math.floor(daysElapsed / 30); // Approximate months

  const dateTimestamp = new Date(timestamp);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (dateTimestamp >= today) {
    if (hoursElapsed > 0) {
      return `${hoursElapsed} ${
        hoursElapsed === 1 ? 'hour' : 'hours'
      } ago today`;
    } else if (minutesElapsed > 0) {
      return `${minutesElapsed} ${
        minutesElapsed === 1 ? 'minute' : 'minutes'
      } ago today`;
    } else {
      return `${secondsElapsed} ${
        secondsElapsed === 1 ? 'second' : 'seconds'
      } ago today`;
    }
  } else if (weeksElapsed < 1) {
    return `${daysElapsed} ${daysElapsed === 1 ? 'day' : 'days'} ago`;
  } else if (monthsElapsed < 1) {
    return `${weeksElapsed} ${weeksElapsed === 1 ? 'week' : 'weeks'} ago`;
  } else {
    return `${monthsElapsed} ${monthsElapsed === 1 ? 'month' : 'months'} ago`;
  }
}
