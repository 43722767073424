import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import Cookies from 'js-cookie';
import {CookieStorage} from 'redux-persist-cookie-storage';

const initialState = {
  userInfo: {},
  token: null,
  refreshToken: null,
  loading: false,
  error: false,
  message: '',
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserId(state, action) {
      const {user_id, message} = action.payload;
      state.userInfo.user_id = user_id;
      state.message = message;
      state.loading = false;
      state.error = false;
    },
    signout(state, action) {
      state.userInfo = {};
      state.token = null;
      state.refreshToken = null;
      state.message = action.payload.message;
      state.loading = false;
      state.error = false;
    },
    setToken(state, action) {
      const {token, refreshToken, message} = action.payload;
      state.token = token;
      state.refreshToken = refreshToken;
      state.message = message;
      state.loading = false;
      state.error = false;
    },
    setUser(state, action) {
      state.userInfo = action.payload;
      state.message = '';
      state.loading = false;
      state.error = false;
    },
  },
});

export const {setUserId, signout, setToken, setUser} = authSlice.actions;
export const authReducer = persistReducer(
  {
    key: 'auth',
    storage: new CookieStorage(Cookies, {
      expiration: {
        default: 1 * 86400, // Cookies expire after one day
      },
      sameSite: 'lax',
    }),
    whitelist: ['userInfo', 'token', 'refreshToken'],
  },
  authSlice.reducer
);
