import {Box, Stack, Typography, useTheme} from '@mui/material';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import React from 'react';
import TextButton from 'components/Buttons/textButton';
import {ActiveElement, NotificationBoxElement} from './appbar.styles';
import {useDispatch, useSelector} from 'react-redux';
import {removeAllMessages, removeMessage} from 'lib/slices/notification.slice';
import calculateTimeElapsed from 'utils/timeElapsed';
import {
  useDeleteAllMessagesMutation,
  useDeleteMessageMutation,
} from 'lib/apis/message.api';

const Notification = () => {
  const theme = useTheme();
  const {allMessages} = useSelector(state => state.notification);
  const dispatch = useDispatch();
  const [deleteMessage] = useDeleteMessageMutation();
  const [deleteAllMessages] = useDeleteAllMessagesMutation();

  const handleRemoveMessage = async id => {
    try {
      await deleteMessage({
        id: id,
      }).unwrap();
      dispatch(removeMessage({id: id}));
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveMessages = async () => {
    try {
      await deleteAllMessages().unwrap();
      dispatch(removeAllMessages());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <NotificationBoxElement>
      <Stack spacing={1.5}>
        <FlexBetween>
          <Typography variant="medium16">Overdue invoices present</Typography>
          <TextButton handleOnClick={() => handleRemoveMessages()}>
            Mark all as read
          </TextButton>
        </FlexBetween>
        <FlexBetween>
          <ActiveElement direction={'row'} alignItems={'center'} spacing={0.5}>
            <Typography variant="medium16">All</Typography>
            <Box className="counter">
              <Typography variant="medium14">
                {Object.values(allMessages).length}
              </Typography>
            </Box>
          </ActiveElement>
        </FlexBetween>

        <Stack
          overflowY={'scroll'}
          maxHeight={'40vh'}
          gap={2}
          paddingBottom={'1em'}
        >
          {Object.keys(allMessages)
            .sort((a, b) => allMessages[b].timestamp - allMessages[a].timestamp)
            .map(key => {
              const timeElapsed = calculateTimeElapsed(
                new Date(allMessages[key].timestamp)
              );
              return (
                <Stack
                  bgcolor={theme.palette.primary.main}
                  p="0.8em"
                  borderRadius={'0.8em'}
                  spacing={2}
                  key={allMessages[key].id}
                >
                  <Stack gap={2}>
                    <Typography variant="regular14">
                      {allMessages[key].message}
                    </Typography>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Typography fontSize={10} color={theme.palette.gray[700]}>
                        {timeElapsed}
                      </Typography>
                      <TextButton
                        fullwidth={false}
                        handleOnClick={() => handleRemoveMessage(key)}
                      >
                        Mark as read
                      </TextButton>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
      </Stack>
    </NotificationBoxElement>
  );
};

export default Notification;
