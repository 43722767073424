import {createSlice} from '@reduxjs/toolkit';
import {
  getNewProduct,
  getNewSection,
  getNewSite,
} from './quotes_utils/generateList';
// import {getlistPrice} from './quotes_utils/listPrice';
import {
  initialCreatedBy,
  initialIssuer,
  initialMeta,
  initialPaymentDetail,
  initialRecipient,
  initialSummary,
} from 'constants/quote.constants';

const initialState = {
  showSectionWisePrice: false,
  quoteData: {
    issuer: initialIssuer,
    recipient: initialRecipient,
    meta: initialMeta,
    listing: [],
    summary: initialSummary,
    paymentDetails: initialPaymentDetail,
    created_by: initialCreatedBy,
  },
  previewType: 'PDF',
  workOrder: false,
};

const getSummary = summary => {
  const {totalAmount, additional_charge, discount, multiplier, days} = summary;
  if (totalAmount === '0.00') {
    return initialSummary;
  }
  const parsedTotalAmount = totalAmount ? parseFloat(totalAmount) : 0;
  const parsedAdditionalCharge = additional_charge
    ? parseFloat(additional_charge)
    : 0;
  const parsedDiscount = discount ? parseFloat(discount) : 0;
  const parsedMultiplier = multiplier ? parseFloat(multiplier) : 0;
  const parsedDays = Math.round(parseInt(days));
  let baseAmount = parsedTotalAmount + parsedAdditionalCharge;

  if (parsedMultiplier && parsedMultiplier > 0) {
    baseAmount *= parsedMultiplier;
    // baseAmount += parsedDays * calculatedMultiplier;
  }

  let newPayable =
    baseAmount - parsedDiscount > 0 ? baseAmount - parsedDiscount : 0.0;
  const newVat = (5 * newPayable) / 100;
  newPayable = (newVat + newPayable).toFixed(2);

  return {
    totalAmount: totalAmount,
    additional_charge: additional_charge,
    discount: discount,
    vat: newVat.toFixed(2),
    multiplier: multiplier,
    days: parsedDays,
    payable: newPayable,
  };
};

const getTotalPrice = arr => {
  const totalPrice = arr.reduce((acc, cur) => {
    if (cur.price != '0.00') {
      acc += parseFloat(cur.price);
    }
    return acc;
  }, 0);
  return totalPrice ? totalPrice.toFixed(2) : '0.00';
};
const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setShowSectionWisePrice(state, action) {
      state.showSectionWisePrice = action.payload;
    },
    setPreviewType(state, action) {
      state.previewType = action.payload;
    },
    setWorkOrder(state, action) {
      state.workOrder = action.payload;
    },
    setCreatedBy(state, action) {
      state.quoteData.created_by = {
        ...state.quoteData.created_by,
        ...action.payload,
      };
    },
    setQuoteIssuer(state, action) {
      state.quoteData.issuer = action.payload;
    },
    setQuoteRecipient(state, action) {
      state.quoteData.recipient = action.payload;
    },
    setQuoteMeta(state, action) {
      state.quoteData.meta = action.payload;
    },
    setQuoteSummary(state, action) {
      const {additional_charge, discount, multiplier, days} = action.payload;
      const sitesPrice = getTotalPrice(state.quoteData.listing);
      const newSummary = getSummary({
        totalAmount: sitesPrice,
        additional_charge: additional_charge,
        multiplier: multiplier,
        days: days,
        discount: discount,
      });
      state.quoteData.summary = {
        ...state.quoteData.summary,
        ...newSummary,
      };
    },
    setQuotePaymentDetails(state, action) {
      state.quoteData.paymentDetails = action.payload;
    },
    setQuoteListing(state, action) {
      state.quoteData.listing = action.payload;
    },
    addNewSite(state, action) {
      const {siteId} = action.payload;
      const list = state.quoteData.listing;
      const value = getNewSite();
      state.quoteData.listing = [
        ...list.slice(0, siteId + 1),
        value,
        ...list.slice(siteId + 1),
      ];
    },
    addNewSection(state, action) {
      const {siteId, sectionId} = action.payload;
      const list = state.quoteData.listing[siteId].sections;
      const value = getNewSection();
      state.quoteData.listing[siteId].sections = [
        ...list.slice(0, sectionId + 1),
        value,
        ...list.slice(sectionId + 1),
      ];
    },
    addNewProduct(state, action) {
      const {siteId, sectionId, productId} = action.payload;
      const list = state.quoteData.listing[siteId].sections[sectionId].products;
      const value = getNewProduct();
      state.quoteData.listing[siteId].sections[sectionId].products = [
        ...list.slice(0, productId + 1),
        value,
        ...list.slice(productId + 1),
      ];
    },
    removeSite(state, action) {
      const {siteId} = action.payload;
      state.quoteData.listing.splice(siteId, 1);
    },
    removeSection(state, action) {
      const {siteId, sectionId} = action.payload;
      state.quoteData.listing[siteId].sections.splice(sectionId, 1);
      if (!state.quoteData.listing[siteId].sections.length) {
        const value = getNewSection();
        state.quoteData.listing[siteId].sections.push(value);
      }
      const sectionsPrice = getTotalPrice(
        state.quoteData.listing[siteId].sections
      );
      state.quoteData.listing[siteId].price = sectionsPrice;

      const sitesPrice = getTotalPrice(state.quoteData.listing);

      const newSummary = getSummary({
        totalAmount: sitesPrice,
        additional_charge: state.quoteData.summary.additional_charge,
        discount: state.quoteData.summary.discount,
        multiplier: state.quoteData.summary.multiplier,
        days: state.quoteData.summary.days,
      });
      state.quoteData.summary = {...state.quoteData.summary, ...newSummary};
    },
    removeProduct(state, action) {
      const {siteId, sectionId, productId} = action.payload;

      state.quoteData.listing[siteId].sections[sectionId].products.splice(
        productId,
        1
      );
      if (
        !state.quoteData.listing[siteId].sections[sectionId].products.length
      ) {
        const value = getNewProduct();
        state.quoteData.listing[siteId].sections[sectionId].products.push(
          value
        );
      }
      const productsPirice = getTotalPrice(
        state.quoteData.listing[siteId].sections[sectionId].products
      );
      state.quoteData.listing[siteId].sections[sectionId].price =
        productsPirice;

      const sectionsPrice = getTotalPrice(
        state.quoteData.listing[siteId].sections
      );
      state.quoteData.listing[siteId].price = sectionsPrice;

      const sitesPrice = getTotalPrice(state.quoteData.listing);

      const newSummary = getSummary({
        totalAmount: sitesPrice,
        additional_charge: state.quoteData.summary.additional_charge,
        discount: state.quoteData.summary.discount,
        multiplier: state.quoteData.summary.multiplier,
        days: state.quoteData.summary.days,
      });
      state.quoteData.summary = {...state.quoteData.summary, ...newSummary};
    },
    editSite(state, action) {
      const {siteId, name} = action.payload;
      state.quoteData.listing[siteId].name = name;
    },
    editSection(state, action) {
      const {siteId, sectionId, name, price} = action.payload;
      state.quoteData.listing[siteId].sections[sectionId].name = name;
      if (state.quoteData.listing[siteId].sections[sectionId].price !== price) {
        state.quoteData.listing[siteId].sections[sectionId].price = price;
        const sectionsPrice = getTotalPrice(
          state.quoteData.listing[siteId].sections
        );
        state.quoteData.listing[siteId].price = sectionsPrice;

        const sitesPrice = getTotalPrice(state.quoteData.listing);

        const newSummary = getSummary({
          totalAmount: sitesPrice,
          additional_charge: state.quoteData.summary.additional_charge,
          discount: state.quoteData.summary.discount,
          multiplier: state.quoteData.summary.multiplier,
          days: state.quoteData.summary.days,
        });
        state.quoteData.summary = {...state.quoteData.summary, ...newSummary};
      }
    },
    editProduct(state, action) {
      const {siteId, sectionId, productId, ...data} = action.payload;
      state.quoteData.listing[siteId].sections[sectionId].products[productId] =
        {...data};

      const productsPirice = getTotalPrice(
        state.quoteData.listing[siteId].sections[sectionId].products
      );
      state.quoteData.listing[siteId].sections[sectionId].price =
        productsPirice;

      const sectionsPrice = getTotalPrice(
        state.quoteData.listing[siteId].sections
      );
      state.quoteData.listing[siteId].price = sectionsPrice;

      const sitesPrice = getTotalPrice(state.quoteData.listing);

      const newSummary = getSummary({
        totalAmount: sitesPrice,
        additional_charge: state.quoteData.summary.additional_charge,
        discount: state.quoteData.summary.discount,
        multiplier: state.quoteData.summary.multiplier,
        days: state.quoteData.summary.days,
      });
      state.quoteData.summary = {...state.quoteData.summary, ...newSummary};
    },
    swapProducts(state, action) {
      const {siteId, sectionId, srcId, destId} = action.payload;
      const items = Array.from(
        state.quoteData.listing[siteId].sections[sectionId].products
      );
      const [reOrderItem] = items.splice(srcId, 1);
      items.splice(destId, 0, reOrderItem);
      state.quoteData.listing[siteId].sections[sectionId].products = items;
    },
  },
});

export const {
  setShowSectionWisePrice,
  setPreviewType,
  setWorkOrder,
  setCreatedBy,
  setQuoteIssuer,
  setQuoteRecipient,
  setQuoteMeta,
  setQuoteSummary,
  setQuotePaymentDetails,
  setQuoteListing,
  addNewSite,
  addNewSection,
  addNewProduct,
  removeSite,
  removeSection,
  removeProduct,
  editSite,
  editSection,
  editProduct,
  swapProducts,
} = quoteSlice.actions;
export default quoteSlice.reducer;
