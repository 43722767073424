import {apiSlice} from './index.slice';
import {createEntityAdapter, createSelector} from '@reduxjs/toolkit';

// Payment api slice
const paymentsAdapter = createEntityAdapter();
const initialState = paymentsAdapter.getInitialState();

export const paymentsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPayments: builder.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        created_by,
        fromDate,
        toDate,
      }) => ({
        url: 'payments/getAllPayments',
        method: 'GET',
        params: {page, pageSize, sort, search, created_by, fromDate, toDate},
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
      transformResponse(responseData) {
        const loadedPayments = responseData.allPayments.map(payment => {
          payment.id = payment._id;
          return payment;
        });
        return {
          allPayments: paymentsAdapter.setAll(initialState, loadedPayments),
          total: responseData.total,
        };
      },
      providesTags(result) {
        if (result?.allPayments.ids) {
          return [
            {type: 'Payment', id: 'LIST'},
            ...result.allPayments.ids.map(id => ({type: 'Payment', id})),
          ];
        }

        return [{type: 'Payment', id: 'LIST'}];
      },
    }),
    updatePayment: builder.mutation({
      query: ({id, payload}) => ({
        url: `/payments/updatePayment?id=${id}`,
        method: 'PATCH',
        body: {...payload},
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Payment', id: arg.id}],
    }),
    createPayment: builder.mutation({
      query: payload => {
        return {
          url: `/payments/addPayment`,
          method: 'POST',
          body: {...payload},
        };
      },
      invalidatesTags: (result, error, arg) => [{type: 'Payment', id: arg.id}],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useUpdatePaymentMutation,
  useCreatePaymentMutation,
} = paymentsApiSlice;

// Return query result object
export const selectPaymentsResult =
  paymentsApiSlice.endpoints.getPayments.select();

// Create memoized selector
const selectPaymentsData = createSelector(
  selectPaymentsResult,
  paymentsResults => paymentsResults.data
);

// Created aliases for selectors
export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById,
  selectIds: selectPaymentIds,
} = paymentsAdapter.getSelectors(
  state => selectPaymentsData(state) ?? initialState
);
