import React from 'react';
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
} from '@react-pdf/renderer';
import TableHeader from './tableHeader';
import TableRow from './tableRow';

// Create styles
Font.register({
  family: 'Work Sans',
  src: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    color: '#1C1C1C',
    paddingTop: 20,
    paddingBottom: 20,
  },
  section: {
    margin: 20,
    padding: 10,
  },
});

// Create Document Component
const InventoryListDoc = ({state}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
            <TableHeader/>
            {
              Object.values(state).map((item, id) => {
                return(
                  <TableRow key={id} {...item} id={id+1} image={item.images.length > 0 ? item.images[0] : ""}/>
                )
              })
            }
        </View>
      </Page>
    </Document>
  );
};

export default InventoryListDoc;
