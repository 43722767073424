import React from 'react';
import {Page, Document, StyleSheet, Font, View} from '@react-pdf/renderer';
import DateSection from './components/date-section';
import NoteSection from './components/note-section';
import Listing from './components/listing';
import PageFooter from './components/page-footer';
import CreatedBy from './components/createdBy';
import ClientSection from './components/client-section';
// Create styles
Font.register({
  family: 'Helvetica',
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    color: '#1C1C1C',
    padding: 30,
    paddingBottom: 80,
    fontFamily: 'Helvetica',
  },
  section: {
    margin: 20,
    padding: 10,
    flexGrow: 1,
  },
});

const WorkOrderPDF = ({state}) => {
  return (
    <Document
      title={`${state.meta.quoteNumber.replace('AQ', 'WO')}_${
        state.issuer.name
      }_${state.meta.qoute_type === 'Estimate' ? 'EST' : 'OFF'}`}
    >
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            display: 'flex',
            gap: 20,
          }}
        >
          <ClientSection
            logo={state.issuer.logo}
            recipient={state.recipient}
            quoteNumber={state.meta.quoteNumber}
            meta={state.meta}
          />
          <DateSection meta={state.meta} />
          <NoteSection meta={state.meta} summary={state.summary} />
          <Listing listing={state.listing} />
          <CreatedBy createdBy={state.created_by} />
        </View>
        <PageFooter />
      </Page>
    </Document>
  );
};

export default WorkOrderPDF;
