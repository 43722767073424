import {useState} from 'react';

export const useSnackbar = () => {
  const [showSnack, setShowSnack] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleShowSnack = (open, message, severity) => {
    setShowSnack({...showSnack, open, message, severity});
  };

  return {showSnack, handleShowSnack};
};
