export const getNewSite = () => {
  const newSite = {
    name: '',
    price: '',
    sections: [getNewSection()],
  };

  return newSite;
};

export const getNewSection = () => {
  const newSection = {
    name: '',
    price: '',
    products: [getNewProduct()],
  };

  return newSection;
};

export const getNewProduct = () => {
  const newProduct = {
    id: '',
    name: '',
    quantity: 1,
    price: '',
    basePrice: '',
    discount: '',
  };

  return newProduct;
};
