import * as React from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import {useTheme} from '@emotion/react';

export default function WithArrow({open, handleClose, children, id, anchorEl}) {
  const theme = useTheme();
  const {palette, boxShadows} = theme;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          backgroundColor: palette.neutral[100],
          boxShadow: boxShadows.shadow1,
          borderRadius: 0,
        },
      }}
    >
      {children}
    </Popover>
  );
}

WithArrow.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.element,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};
