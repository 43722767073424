import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import dayjs from 'dayjs';

const DateSection = ({meta}) => {
  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    block: {
      maxWidth: '50%',
      display: 'flex',
      alignItems: 'flex-start',
      gap: 5,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Issue Date
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {dayjs(meta.issued_at).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Valid Thru
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {dayjs(meta.valid_thru).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Setup Date
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {dayjs(meta.setup_at).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Show Date
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {dayjs(meta.show_at).format('DD/MM/YYYY')}
        </Text>
      </View>
      <View style={styles.block}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 100,
          }}
        >
          Dismantle Date
        </Text>
        <Text
          style={{
            fontSize: '12px',
            fontWeight: 100,
          }}
        >
          {dayjs(meta.dismantle_at).format('DD/MM/YYYY')}
        </Text>
      </View>
    </View>
  );
};

export default DateSection;
