import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {styled} from '@mui/material';
import {NavItems, SecondaryNavItems} from 'constants/sideBarConstants';
import MuiDrawer from '@mui/material/Drawer';
import {openedMixin, closedMixin} from './drawer.style';
import PrimaryItem from 'components/Sidebar/primaryItem';
import SecondaryItem from 'components/Sidebar/secondaryItem';
import {DrawerListElement} from './drawer.style';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {signout} from 'lib/slices/auth.slice';
import {useSignoutMutation} from 'lib/apis/authApi.slice';
import { superAdmin } from 'constants/userRoles';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'relative',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const PrimarySidebar = ({open, user_id}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signOut] = useSignoutMutation();

  const handleNavigate = route => {
    navigate(route);
  };
  const {userInfo}=useSelector(state=>state.auth);

  const handleSignOut = async () => {
    navigate('/secure/signin');
    dispatch(signout('Signed Out'));
    await signOut({
      user_id: user_id,
    }).unwrap();
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerListElement>
        {NavItems.map(({text, icon}, index) => {
          const lcText = text.toLowerCase();
          return (
            <PrimaryItem
              key={index}
              open={open}
              handleOnClick={val => handleNavigate(val)}
              lcText={lcText}
              icon={icon}
              text={text}
              active={pathname}
              hidden={userInfo.role!==superAdmin && text==="Sales"}
            />
          );
        })}
      </DrawerListElement>
      {SecondaryNavItems.map(({icon, text}, id) => {
        return (
          <SecondaryItem
            key={id}
            open={open}
            handleOnClick={handleSignOut}
            icon={icon}
            text={text}
          />
        );
      })}
    </Drawer>
  );
};

PrimarySidebar.propTypes = {
  open: PropTypes.bool,
};
export default React.memo(PrimarySidebar);
