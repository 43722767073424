import React, {Suspense} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {themeSettings} from 'theme';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import PrimaryMainLayout from 'pages/Protected/primaryLayout';
import Signin from 'pages/Auth/signin';
import PrimaryAuthLayout from 'pages/Auth/authLayout';
import Multifactor from 'pages/Auth/multifactor';
import InvoicePage from 'pages/Protected/Invoice';
import SettingsLayout from './pages/Protected/settings';
import PaymentPage from 'pages/Protected/Payment';
import BubbleLoader from 'components/Loaders/dotLoaders';
import InventoryPDFPreview from 'pages/Protected/Inventory/Preview/inventory';
import ForgotPW from 'pages/Auth/forgotPW';
import ResetPassword from 'pages/Auth/resetPW';
import PrimaryQuotePDFView from 'pages/Protected/Invoice/preview/primaryQuotePDFView';

const Dashboard = React.lazy(() => import('pages/Protected/Dashboard'));
const ContactPage = React.lazy(() => import('pages/Protected/Contact'));
const SalesPage = React.lazy(() => import('pages/Protected/Sales'));
const InventoryPage = React.lazy(() => import('pages/Protected/Inventory'));
const MutateInvoicePage = React.lazy(() =>
  import('pages/Protected/Invoice/mutateInvoice')
);
const RepEditScreen = React.lazy(() => import('pages/Protected/Sales/repEdit'));
const RepStatsScreen = React.lazy(() =>
  import('pages/Protected/Sales/repStats')
);
const CreatePaymentScreen = React.lazy(() =>
  import('pages/Protected/Payment/managePayment')
);
const ContactDetailsScreen = React.lazy(() =>
  import('pages/Protected/Contact/contactDetails')
);

const ContactEditScreen = React.lazy(() =>
  import('pages/Protected/Contact/manageContact')
);

const MutateProductScreen = React.lazy(() =>
  import('pages/Protected/Inventory/mutateProduct')
);

function App() {
  const authenticated = true;
  const theme = createTheme(themeSettings);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense
            fallback={
              <BubbleLoader color={theme.palette.accent.main} loading={true} />
            }
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={authenticated ? '/app' : '/secure'}
                    replace={true}
                  />
                }
              />
              <Route path="/secure" element={<PrimaryAuthLayout />}>
                <Route
                  path="/secure"
                  element={<Navigate to={'/secure/signin'} replace={true} />}
                />
                <Route index path="signin" element={<Signin />} />
                <Route path="verify" element={<Multifactor />} />
                <Route path="forgot-password" element={<ForgotPW />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
              {/* Protect routes */}
              <Route path="/app" element={<PrimaryMainLayout />}>
                <Route
                  path="/app"
                  element={<Navigate to={'/app/dashboard'} replace={true} />}
                />
                <Route index path="dashboard" element={<Dashboard />} />
                <Route path="quotes" element={<InvoicePage />} />
                <Route path="payments" element={<PaymentPage />} />
                <Route
                  path="payments/create"
                  element={<CreatePaymentScreen />}
                />
                <Route
                  path="payments/edit/:id"
                  element={<CreatePaymentScreen />}
                />
                <Route path="contacts" element={<ContactPage />} />
                <Route
                  path="contacts/client/:id"
                  element={<ContactDetailsScreen />}
                />
                <Route path="contacts/create" element={<ContactEditScreen />} />
                <Route
                  path="contacts/edit/:id"
                  element={<ContactEditScreen />}
                />
                <Route path="sales" element={<SalesPage />} />
                <Route path="sales/create" element={<RepEditScreen />} />
                <Route path="sales/edit/:repId" element={<RepEditScreen />} />
                <Route path="sales/stats/:repId" element={<RepStatsScreen />} />
                <Route path="inventory" element={<InventoryPage />} />
                <Route
                  path="inventory/edit/:productId"
                  element={<MutateProductScreen />}
                />
                <Route
                  path="inventory/create"
                  element={<MutateProductScreen />}
                />
                <Route path="quotes/create/" element={<MutateInvoicePage />} />
                <Route path="quotes/edit/:id" element={<MutateInvoicePage />} />
                <Route
                  path="quotes/preview/pdf"
                  element={<PrimaryQuotePDFView />}
                />
                <Route path="settings" element={<SettingsLayout />} />
              </Route>
              <Route
                path="quotes/export/pdf"
                element={<PrimaryQuotePDFView />}
              />
              <Route path="inventory/pdf" element={<InventoryPDFPreview />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
