import {StyleSheet, Text, View} from '@react-pdf/renderer';
import React from 'react';

const PageFooter = () => {
  const style = StyleSheet.create({
    container: {
      width: '100%',
      borderBottomWidth: 4,
      borderColor: '#D41217',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      position: 'absolute',
      bottom: 30,
      left: 30,
      paddingBottom: 5,
    },
    item: {
      color: '#4A4A4A',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: 'normal',
    },
    bullet: {
      width: '5px',
      height: '5px',
      backgroundColor: '#D41217',
      borderRadius: '100%',
    },
  });
  return (
    <View style={style.container} fixed>
      <Text style={style.item}>Abacus Audio Visual Rental LLC</Text>
      <View style={style.bullet}></View>
      <Text style={style.item}>P.O. Box 99443 Dubai UAE</Text>
      <View style={style.bullet}></View>
      <Text style={style.item}>tl: +971 4 3400722</Text>
      <View style={style.bullet}></View>
      <Text style={style.item}>www.abacusuae.com</Text>
    </View>
  );
};

export default PageFooter;
