import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  allMessages: {},
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNewMessage(state, action) {
      const {id, message, timestamp} = action.payload;
      state.allMessages[id] = {message: message, timestamp: timestamp};
    },
    removeAllMessages(state) {
      state.allMessages = {...initialState.allMessages};
    },
    removeMessage(state, action) {
      const removeKey = action.payload.id;
      const newObj = {...state.allMessages};
      delete newObj[removeKey];

      state.allMessages = {...newObj};
    },
  },
});

export const {setNewMessage, removeAllMessages, removeMessage} =
  notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
