/* eslint-disable no-unused-vars */
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setToken, signout} from 'lib/slices/auth.slice';
import {REHYDRATE} from 'redux-persist';

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEV_SERVER
      : process.env.REACT_APP_PROD_SERVER,
  prepareHeaders(headers, {getState}) {
    const {token} = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === REHYDRATE) {
      return action.payload[reducerPath];
    }
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const {refreshToken, userInfo} = api.getState().auth;
    const refreshResult = await baseQuery(
      {
        method: 'POST',
        url: '/auth/refreshToken',
        body: {
          user_id: userInfo._id,
          token: refreshToken,
        },
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      api.dispatch(
        setToken({
          token: refreshResult.data.token,
          refreshToken: refreshResult.data.refreshToken,
          message: 'Token Refreshed',
        })
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = 'Your login has expired.';
      }

      // If the refresh token API call fails, log out the user
      api.dispatch(signout('Signed Out'));
      await baseQuery(
        {
          method: 'POST',
          url: '/auth/signout',
          body: {
            user_id: userInfo._id,
          },
        },
        api,
        extraOptions
      );
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Business', 'Users', 'Invoice', 'Quote'],
  endpoints: builder => ({}),
});
