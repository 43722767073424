import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

export const useFetchData = (reducer, getCall) => {
  const dispatch = useDispatch();
  const fetchUser = async () => {
    try {
      const data = await getCall().unwrap();
      dispatch(reducer(data));
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);
};
