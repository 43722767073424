import {StyleSheet, Text, View} from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 10,
  },
  section: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '6px',
  },
});

const Terms = ({paymentDetails}) => {
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 400,
          }}
        >
          Terms and conditions
        </Text>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '10px',
            color: '#6F6F6F',
          }}
        >
          {paymentDetails.terms_conditions}
        </Text>
      </View>
      <View style={styles.section}>
        <Text
          style={{
            fontSize: '10px',
            fontWeight: 400,
          }}
        >
          Payment Terms
        </Text>
        <Text
          style={{
            fontWeight: 400,
            fontSize: '10px',
            color: '#6F6F6F',
          }}
        >
          {paymentDetails.payment_terms}
        </Text>
      </View>
    </View>
  );
};

export default Terms;
