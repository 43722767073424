import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {persistor, store} from 'lib/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate
      loading={<div>loading from persist</div>}
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>
);
