// import {createEntityAdapter, createSelector} from '@reduxjs/toolkit';
import {createEntityAdapter} from '@reduxjs/toolkit';
import {apiSlice} from './index.slice';

const quotesAdapter = createEntityAdapter();
const initialState = quotesAdapter.getInitialState();

export const quoteApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createQuote: builder.mutation({
      query: payload => ({
        url: `quote/create`,
        method: 'POST',
        body: {...payload},
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Quote', id: arg.id}],
    }),
    getBy: builder.query({
      query: ({
        page,
        pageSize,
        sort,
        search,
        fromDate,
        toDate,
        created_by,
        quoteType,
      }) => ({
        url: 'quote/getByQuery',
        method: 'GET',
        params: {
          page,
          pageSize,
          sort,
          search,
          fromDate,
          toDate,
          created_by,
          quoteType,
        },
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
      transformResponse(responseData) {
        const loadedQuotess = responseData.quotes.map(quote => {
          quote.id = quote._id;
          return quote;
        });
        return {
          quote: quotesAdapter.setAll(initialState, loadedQuotess),
          total: responseData.total,
          totalValue: responseData.totalValue,
          officialValue: responseData.officialValue,
          estimateValue: responseData.estimateValue,
          overdueValue: responseData.overdueValue,
          paidValue: responseData.paidValue,
        };
      },
      providesTags(result) {
        if (result?.quote?.ids) {
          return [
            {type: 'Quote', id: 'LIST'},
            ...result.quote.ids.map(id => ({type: 'Quote', id})),
          ];
        }

        return [{type: 'Quote', id: 'LIST'}];
      },
    }),
    updateQuote: builder.mutation({
      query: ({payload, id}) => ({
        url: `quote/updateQuote?id=${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [{type: 'Quote', id: arg.id}],
    }),
    getUserQuotesStats: builder.query({
      query: ({created_by}) => ({
        url: 'quote/getUserStats',
        method: 'GET',
        params: {
          created_by,
        },
      }),
    }),
    getQuoteById: builder.query({
      query: ({id}) => ({
        url: 'quote/getQuoteById',
        method: 'GET',
        params: {
          id,
        },
      }),
    }),
    searchQuotes: builder.query({
      query: ({search}) => ({
        url: 'quote/searchQuotes',
        method: 'GET',
        params: {
          search,
        },
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
      transformResponse(responseData) {
        const loadedQuotess = responseData.quotes.map(quote => {
          quote.id = quote._id;
          return quote;
        });
        return {
          quote: quotesAdapter.setAll(initialState, loadedQuotess),
          total: responseData.total,
        };
      },
      providesTags(result) {
        if (result?.quote?.ids) {
          return [
            {type: 'Quote', id: 'LIST'},
            ...result.quote.ids.map(id => ({type: 'Quote', id})),
          ];
        }

        return [{type: 'Quote', id: 'LIST'}];
      },
    }),
  }),
});

export const {
  useCreateQuoteMutation,
  useGetByQuery,
  useUpdateQuoteMutation,
  useGetUserQuotesStatsQuery,
  useSearchQuotesQuery,
  useGetQuoteByIdQuery,
} = quoteApi;

// getUserStats;
