import React from 'react';
import {InputAdornment, OutlinedInput} from '@mui/material';
import PropTypes from 'prop-types';

const NoLabelInput = props => {
  return (
    <OutlinedInput
      {...props}
      sx={{borderRadius: '0.5em', padding: '0.3em 0.2em'}}
      endAdornment={
        props.endiconconfig && (
          <InputAdornment position="end">{props.endiconconfig}</InputAdornment>
        )
      }
      startAdornment={
        props.starticonconfig && (
          <InputAdornment position="start">
            {props.starticonconfig}
          </InputAdornment>
        )
      }
    />
  );
};

export default NoLabelInput;

NoLabelInput.propTypes = {
  endiconconfig: PropTypes.element,
  starticonconfig: PropTypes.element,
};
