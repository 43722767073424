import React from 'react';
import {IconButton, useTheme} from '@mui/material';
import PropTypes from 'prop-types';

const DrawerToggleBtn = ({handleOnClick, icon, open}) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        backgroundColor: theme.palette.accent[400],
        color: theme.palette.accent[500],
        padding: '0.5em',
        borderRadius: '0.5em',
        position: 'fixed',
        zIndex: 999,
        top: '4em',
        left: open ? `calc(${theme.spacing(27)})` : `calc(${theme.spacing(5)})`,
        transition: theme.transitions.create('left', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        fontSize: '22px',
      }}
      onClick={() => handleOnClick()}
    >
      {icon}
    </IconButton>
  );
};

export default DrawerToggleBtn;

DrawerToggleBtn.propTypes = {
  handleOnClick: PropTypes.func,
  icon: PropTypes.element,
  open: PropTypes.bool,
};
