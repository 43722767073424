const {styled, Card, Chip} = require('@mui/material');

export const CardElement = styled(Card)(({theme}) => ({
  maxWidth: '13.75em',
  width: '100%',
  borderRadius: '0.5em',
  border: 'thin solid',
  boxShadow: 'none',
  background: theme.palette.neutral[100],
  borderColor: theme.palette.gray[500],
  padding: '1em',
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
}));

export const ChipElement = styled(Chip)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral[1000],
  borderRadius: '0.5em',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '19px',
}));

export const SlabElement = styled(Card)(({theme}) => ({
  width: 'auto',
  borderRadius: '0.5em',
  border: 'thin solid',
  boxShadow: 'none',
  background: theme.palette.neutral[100],
  borderColor: theme.palette.gray[500],
  padding: '1em 1.5em',
}));
