const {styled, Box} = require('@mui/material');

export const PrimaryLayout = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
}));

export const PrimaryOutlet = styled(Box)(() => ({
  flexGrow: 1,
  padding: '2em',
}));
