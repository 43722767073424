import BasicCardTwo from 'components/Cards/BasicCard/variantTwo';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Stack,
  Grid,
  IconButton,
  Popper,
  useTheme,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import {
  CreateInvoiceConstants,
  InvoiceConstants,
} from 'constants/invoiceConstants';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import DataTable from './serverside.datatable';
import useTableQueryParams from 'utils/tableQueryParamsUtility';
import NoLabelInput from 'components/Inputs/noLabelInput';
import PrimaryButton from 'components/Buttons/primaryButton';
import TextBtn from 'components/Buttons/textButton';
import {debounce} from 'utils/debounce';
import {useNavigate} from 'react-router-dom';
import {CloseOutlined} from '@mui/icons-material';
import DatePickerOne from 'components/DatePickers/variantOne';
import {useGetAllUsersQuery} from 'lib/apis/userApi.slice';
import TextButton from 'components/Buttons/textButton';
import PrimaryInput from 'components/Inputs/primary';
import {useSelector} from 'react-redux';
import {superAdmin} from 'constants/userRoles';
import BubbleLoader from 'components/Loaders/dotLoaders';
import {useGetUserQuotesStatsQuery} from 'lib/apis/quote.api';
import {creatQuoteState} from 'lib/slices/quotes_utils/createQuote';

const InvoicePage = () => {
  const {state, dispatch} = useTableQueryParams();
  const [filertUserId, setFilterUserId] = useState('');
  const [quoteType, setQuoteType] = useState('');
  const navigate = useNavigate();
  const {userInfo} = useSelector(state => state.auth);
  const createQuoteInitState = creatQuoteState();
  const onCreateInvoice = () => {
    navigate(`/app/quotes/create`, {
      state: createQuoteInitState,
    });
  };

  return (
    <Box
      sx={{
        width: `min(1440px, 100% - 1rem * 2)`,
        marginInline: 'auto',
      }}
    >
      {userInfo.role !== superAdmin && (
        <Box marginTop={'1em'}>
          <Stats />
        </Box>
      )}
      <Stack spacing={4} my={5}>
        <Typography variant="h3">
          {InvoiceConstants.sectionTwo.title}
        </Typography>
      </Stack>
      <Grid
        container
        columnSpacing={{xs: 1, sm: 2, md: 5}}
        marginBottom={'1.8em'}
      >
        <Grid item xs={8}>
          <CustomToolbar
            state={state}
            dispatch={dispatch}
            setFilterUserId={setFilterUserId}
            quoteType={quoteType}
            setQuoteType={setQuoteType}
          />
        </Grid>
        <Grid item xs={4} alignItems={'flex-end'}>
          <PrimaryButton
            label={InvoiceConstants.sectionTwo.button.label}
            typevariant={'h5'}
            starticon={InvoiceConstants.sectionTwo.button.icon}
            handleOnClick={() => onCreateInvoice()}
          />
        </Grid>
      </Grid>
      <DataTable
        state={state}
        dispatch={dispatch}
        filertUserId={filertUserId}
        quoteType={quoteType}
      />
    </Box>
  );
};

export default InvoicePage;

const Stats = () => {
  const {palette} = useTheme();
  const {userInfo} = useSelector(state => state.auth);
  const {
    isLoading: isAnalyticsLoading,
    isSuccess: isAnalyticsSuccess,
    data: analytics,
  } = useGetUserQuotesStatsQuery(
    {
      created_by: userInfo.role !== superAdmin ? userInfo._id : '',
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  if (isAnalyticsLoading) {
    return (
      <BubbleLoader color={palette.accent.main} loading={isAnalyticsLoading} />
    );
  }
  if (isAnalyticsSuccess) {
    return (
      <FlexBetween justifyContent={'space-between'} gap={'2em'}>
        {InvoiceConstants.stats.map(({icon, imgAlt, type, key}, id) => {
          return (
            <BasicCardTwo
              key={id}
              icon={icon}
              imgAlt={imgAlt}
              type={type}
              balance={
                'AED ' +
                analytics[key]
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          );
        })}
      </FlexBetween>
    );
  }
};

const CustomToolbar = ({
  state,
  dispatch,
  setFilterUserId,
  setQuoteType,
  quoteType,
}) => {
  const [filterEl, setFilterEL] = useState(null);

  const isFilterOpen = Boolean(filterEl);
  const handleFilterClick = (e, popperOpen) => {
    setFilterEL(popperOpen ? e.currentTarget : null);
  };
  const handleSearch = value => {
    debounce(dispatch({type: 'SET_SEARCH', payload: value}), 300);
  };

  return (
    <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}}>
      <Grid item xs={9}>
        <NoLabelInput
          name={InvoiceConstants.sectionTwo.input.name}
          type={InvoiceConstants.sectionTwo.input.type}
          label={''}
          placeholder={InvoiceConstants.sectionTwo.input.placeholder}
          starticonconfig={
            <IconButton aria-label="Search Option">
              {InvoiceConstants.sectionTwo.input.icon}
            </IconButton>
          }
          size="small"
          fullWidth
          value={state.search}
          onChange={e => handleSearch(e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextBtn
          handleOnClick={e => {
            handleFilterClick(e, true);
          }}
          startIcon={InvoiceConstants.sectionTwo.filter.icon}
        >
          <Typography variant="body5">
            {InvoiceConstants.sectionTwo.filter.text}
          </Typography>
        </TextBtn>
      </Grid>
      <FilterPopup
        isFilterOpen={isFilterOpen}
        filterEl={filterEl}
        state={state}
        dispatch={dispatch}
        handleFilterClick={handleFilterClick}
        setFilterUserId={setFilterUserId}
        quoteType={quoteType}
        setQuoteType={setQuoteType}
      />
    </Grid>
  );
};

CustomToolbar.propTypes = {
  state: PropTypes.object,
  dispatch: PropTypes.func,
  setFilterUserId: PropTypes.func,
  quoteType: PropTypes.string,
  setQuoteType: PropTypes.func,
};

const FilterPopup = ({
  state,
  dispatch,
  isFilterOpen,
  filterEl,
  handleFilterClick,
  setFilterUserId,
  quoteType,
  setQuoteType,
}) => {
  const {blockThree} = CreateInvoiceConstants.settings;
  const [usersListEl, setUsersListEl] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const {userInfo} = useSelector(state => state.auth);

  const handleUsersList = (event, popperOpen) => {
    setUsersListEl(popperOpen ? event.currentTarget : null);
  };
  const usersListOpen = Boolean(usersListEl);
  const usersListId = usersListOpen ? 'simple-popper' : undefined;

  const {
    isSuccess,
    isLoading,
    data: usersData,
  } = useGetAllUsersQuery('usersList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const filterPopupId = isFilterOpen ? 'simple-popover' : undefined;
  const {palette} = useTheme();
  const filterUsers = isSuccess
    ? Object.values(usersData.users.entities).filter(
        user =>
          user.first_name
            .toLowerCase()
            .includes(searchUser ? searchUser.toLowerCase() : '') ||
          user.last_name
            .toLowerCase()
            .includes(searchUser ? searchUser.toLowerCase() : '')
      )
    : [];

  const handleClearAllFilters = () => {
    setSearchUser('');
    setFilterUserId('');
    dispatch({
      type: 'SET_FROM_DATE',
      payload: '',
    });
    dispatch({
      type: 'SET_TO_DATE',
      payload: '',
    });
    setQuoteType('');
    handleFilterClick(null, false);
  };

  return (
    <Popper
      id={filterPopupId}
      anchorEl={filterEl}
      open={isFilterOpen}
      handleClose={() => handleFilterClick(null, false)}
      sx={{
        zIndex: '999',
        minWidth: '28em',
        maxWidth: '48em',

        // overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          border: `thin solid ${palette.grey[500]}`,
          borderRadius: '0.5em',
          maxHeight: '20em',
          overflowY: 'hidden',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={() => handleFilterClick(null, false)}
          sx={{
            position: 'absolute',
            top: '0.3em',
            right: '0.3em',
          }}
        >
          <CloseOutlined />
        </IconButton>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Typography variant="medium14">Filter</Typography>
          <TextButton handleOnClick={() => handleClearAllFilters()}>
            <Typography variant="medium10" color={palette.error[500]}>
              Clear All
            </Typography>
          </TextButton>
        </Stack>
        <Stack pt={3} gap={2}>
          <FlexBetween>
            <DatePickerOne
              handledatechange={val =>
                dispatch({
                  type: 'SET_FROM_DATE',
                  payload: new Date(val),
                })
              }
              dateval={state.fromDate}
              variant="regular16"
              placeholder={'From date'}
            />
            <DatePickerOne
              handledatechange={val =>
                dispatch({
                  type: 'SET_TO_DATE',
                  payload: new Date(val),
                })
              }
              dateval={state.toDate}
              variant="regular16"
              placeholder={'To date'}
              minDate={state.fromDate}
            />
          </FlexBetween>
          {userInfo.role === superAdmin && (
            <Box>
              <Typography variant="medium14">Search sales person</Typography>
              <PrimaryInput
                placeholder="Enter name"
                autoComplete={'off'}
                aria-describedby={usersListId}
                onFocus={e => handleUsersList(e, true)}
                value={searchUser}
                onChange={e => setSearchUser(e.target.value)}
                fsize={'small'}
                width="100%"
                endiconconfig={
                  <IconButton
                    onClick={() => {
                      setSearchUser('');
                      setFilterUserId('');
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                }
              />
            </Box>
          )}
          <Box>
            <Typography variant="medium14">Select quote type</Typography>
            <Stack direction={'row'} gap={2}>
              {blockThree.selectOptions.map((option, id) => {
                return (
                  <Stack
                    key={id}
                    direction="row"
                    spacing={0.5}
                    alignItems={'center'}
                    sx={{cursor: 'pointer'}}
                  >
                    <Checkbox
                      checked={quoteType === option}
                      sx={{
                        '& .MuiSvgIcon-root': {fontSize: 16},
                        color: palette.accent.main,
                        '&.Mui-checked': {
                          color: palette.accent.main,
                        },
                      }}
                      onChange={() =>
                        quoteType === option
                          ? setQuoteType('')
                          : setQuoteType(option)
                      }
                    />
                    <Typography
                      variant="medium16"
                      color={quoteType === option && palette.accent.main}
                    >
                      {option}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
          <Popper
            id={usersListId}
            open={usersListOpen}
            anchorEl={usersListEl}
            sx={{
              zIndex: '9999',
              minWidth: '20em',
              maxWidth: '28em',

              // overflowY: 'scroll',
            }}
          >
            <Box
              sx={{
                p: 1,
                bgcolor: 'background.paper',
                border: `thin solid ${palette.grey[500]}`,
                borderRadius: '0.5em',
                maxHeight: '20em',
                overflowY: 'scroll',
              }}
            >
              <IconButton
                onClick={() => handleUsersList(null, false)}
                sx={{
                  position: 'absolute',
                  top: '0.3em',
                  right: '0.8em',
                }}
              >
                <CloseOutlined />
              </IconButton>
              <Grid
                container
                columnSpacing={{xs: 1, sm: 2, md: 3}}
                rowSpacing={2}
                paddingTop={3}
              >
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: palette.accent.main,
                      padding: '2em',
                    }}
                  />
                )}
                {isSuccess && (
                  <>
                    {filterUsers.length > 0 &&
                      filterUsers.map((user, index) => {
                        return (
                          <>
                            <Grid item xs={9} key={index}>
                              <TextButton
                                handleOnClick={() => {
                                  setSearchUser(
                                    `${user.first_name} ${user.last_name}`
                                  );
                                  setFilterUserId(user._id);
                                  handleUsersList(null, false);
                                }}
                              >
                                <Typography variant={'medium14'}>
                                  {user.first_name} {user.last_name}
                                </Typography>
                              </TextButton>
                            </Grid>
                            {/* <Grid item xs={3}>
                              {product.name === item.product.name && (
                                <CheckOutlined />
                              )}
                            </Grid> */}
                          </>
                        );
                      })}
                  </>
                )}
              </Grid>
              {isSuccess && filterUsers.length === 0 && (
                <Stack
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  padding={'1rem'}
                  gap={2}
                >
                  <Typography variant="medium16" textAlign={'center'}>
                    Not Found
                  </Typography>
                  <Typography
                    variant="medium16"
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                    width={'20em'}
                  >
                    {searchUser}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Popper>
        </Stack>
      </Box>
    </Popper>
  );
};

FilterPopup.propTypes = {
  isFilterOpen: PropTypes.bool,
  filterEl: PropTypes.any,
  handleFilterClick: PropTypes.func,
  state: PropTypes.object,
  dispatch: PropTypes.func,
  setFilterUserId: PropTypes.func,
  quoteType: PropTypes.string,
  setQuoteType: PropTypes.func,
};
