import {Box, Typography, Tabs} from '@mui/material';
import React, {useState} from 'react';
import TabPanel from 'containers/TabPanel';
import LinkTab from 'components/Buttons/linkTab';
import {SettingsConstants} from 'constants/settingsConstants';
import AccountSettingsScreen from './account';
import BusinessSettingsScreen from './business';

const SettingsLayout = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{
        width: `min(1440px, 100% - 1rem * 2)`,
        marginInline: 'auto',
      }}
    >
      <Typography variant="h2">{SettingsConstants.heading}</Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        sx={{margin: '1.5em 0em'}}
      >
        <LinkTab label="Account" {...a11yProps(0)} />
        <LinkTab label="Business" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AccountSettingsScreen />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BusinessSettingsScreen />
      </TabPanel>
    </Box>
  );
};

export default SettingsLayout;
