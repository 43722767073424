import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = '#fff';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#4F4F4F',
    color: '#ffffff',
    borderBottomWidth: 1,
    alignItems: 'center',
    flexGrow: 1,
    textAlign: 'left',
    height: 30,
  },
  sl: {
    width: '6%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    width: '50%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  status: {
    width: '25%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  stock: {
    width: '25%',
    fontSize: '10px',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'center',
    paddingHorizontal: 10,
  },
  amount: {
    width: '25%',
    fontSize: '10px',
    padding: 5,
    textAlign: 'center',
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <View style={styles.sl}>
      <Text>Sl</Text>
    </View>
    <View style={styles.description}>
      <Text>Product/Service Name</Text>
    </View>
    <View style={styles.status}>
      <Text>Status</Text>
    </View>
    <View style={styles.stock}>
      <Text>In Stock</Text>
    </View>
    <View style={styles.amount}>
      <Text>Price</Text>
    </View>
  </View>
);

export default TableHeader;
