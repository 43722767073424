import {Stack, Typography, useTheme} from '@mui/material';
import React from 'react';
import {HelpBoxElement} from './appbar.styles';

const Help = () => {
  const theme = useTheme();
  return (
    <HelpBoxElement>
      <Stack spacing={1.5}>
        <Typography variant="medium16">Help</Typography>
        <Stack
          bgcolor={theme.palette.primary.main}
          p="0.8em"
          borderRadius={'0.8em'}
          spacing={2}
        >
          <Typography variant="regular16">
            In case of any technical queries, please contact us at{' '}
            <a
              style={{
                color: '#000',
                fontWeight: 'medium',
              }}
              href="tel:+971540324573"
            >
              +971 540 32 4573
            </a>{' '}
            or email us at{' '}
            <a
              style={{
                color: '#000',
                fontWeight: 'medium',
              }}
              href="mailto:support@abacus.ae"
            >
              support@abacus.ae
            </a>
          </Typography>
        </Stack>
      </Stack>
    </HelpBoxElement>
  );
};

export default Help;
