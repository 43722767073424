import React from 'react';
import {DotLoader} from 'react-spinners';
import PropTypes from 'prop-types';

const BubbleLoader = props => {
  return (
    <DotLoader
      {...props}
      cssOverride={{
        display: 'block',
        margin: '0 auto',
        borderColor: 'red',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translat(-50%, -50%)',
      }}
      size={60}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

BubbleLoader.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};
export default BubbleLoader;
