import {countriesWithStates} from 'constants/dataset';

function getStateFromCountry(param) {
  const data = countriesWithStates.filter(country => country.label === param);
  if (!data[0] || data[0].stateProvinces === null) {
    return ['No States Available'];
  }

  const state = data[0]?.stateProvinces;
  const n = state.length;

  if (n > 0) {
    const arr = [];

    for (let i = 0; i < n; i++) {
      arr.push(state[i].label);
    }

    return arr;
  }
}

export default getStateFromCountry;
