import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';

const borderColor = '#fff';
const headerStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#4F4F4F',
    color: '#ffffff',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 30,
    flexGrow: 1,
    textAlign: 'left',
  },
  sl: {
    width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    width: '70%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  qty: {
    width: '20%',
    fontSize: '10px',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
});
const rowStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    color: '#1c1c1c',
    borderBottomWidth: 0.5,
    borderBottomColor: '#E0E0E0',
    alignItems: 'center',
    height: 30,
    flexGrow: 1,
    textAlign: 'left',
    padding: 0,
  },
  sl: {
    width: '10%',
    borderRightColor: '#E0E0E0',
    borderRightWidth: 0.5,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  description: {
    width: '70%',
    borderRightColor: '#E0E0E0',
    borderRightWidth: 0.5,
    fontSize: '10px',
    paddingHorizontal: 10,
  },
  qty: {
    width: '20%',
    fontSize: '10px',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
});

const TableHeader = () => (
  <View style={headerStyles.container}>
    <View style={headerStyles.sl}>
      <Text>Sl</Text>
    </View>
    <View style={headerStyles.description}>
      <Text>Description</Text>
    </View>
    <View style={headerStyles.qty}>
      <Text>Quantity</Text>
    </View>
  </View>
);

const TableRow = ({sl, name, quantity, type}) => (
  <View style={rowStyles.container}>
    <View style={rowStyles.sl}>
      <Text>{sl}</Text>
    </View>
    <View
      style={{
        ...rowStyles.description,
        fontSize:
          type === 'site' ? '14px' : type === 'section' ? '12px' : '10px',
        fontWeight: type === 'site' ? 600 : type === 'section' ? 500 : 100,
      }}
    >
      <Text>{name}</Text>
    </View>
    <View style={rowStyles.qty}>
      <Text>{quantity}</Text>
    </View>
  </View>
);

const Listing = ({listing}) => {
  const renderList = [];
  listing.map((site, siteId) => {
    if (site.name !== '') {
      renderList.push({sl: siteId + 1, name: site.name, type: 'site'});
      site.sections.map((section, sectionId) => {
        if (section.name !== '') {
          renderList.push({
            sl: `${siteId + 1}.${sectionId + 1}`,
            name: section.name,
            type: 'section',
          });
          section.products.map((product, productId) => {
            if (product.id) {
              renderList.push({
                sl: `${siteId + 1}.${sectionId + 1}.${productId + 1}`,
                name: product.name,
                quantity: product.quantity,
                type: 'product',
              });
            }
          });
        }
      });
    }
  });
  return (
    <View>
      <TableHeader />
      {renderList.map((item, id) => {
        return (
          <TableRow
            key={id}
            sl={item.sl}
            name={item.name}
            quantity={item.quantity ? item.quantity : ' '}
            type={item.type}
          />
        );
      })}
    </View>
  );
};

export default Listing;
