const {styled, Box, Stack} = require('@mui/material');

export const NotificationBoxElement = styled(Box)(() => ({
  padding: '1em',
  borderRadius: '1em',
  width: '32em',
}));

export const HelpBoxElement = styled(Box)(() => ({
  padding: '1em',
  borderRadius: '1em',
  width: '32em',
}));
export const ActiveElement = styled(Stack)(({theme}) => ({
  color: theme.palette.accent.main,
  borderBottomColor: theme.palette.accent.main,
  paddingBottom: '2px',
  borderBottom: '2px solid',
  '& .counter': {
    backgroundColor: theme.palette.accent.main,
    padding: '1px 8px',
    borderRadius: '0.2em',
    textTransform: 'none',
    color: theme.palette.neutral[100],
  },
}));
