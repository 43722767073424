import {createSlice} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  businessData: {},
  loading: false,
  error: false,
  message: '',
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setData(state, action) {
      state.businessData = action.payload;
      state.message = '';
      state.loading = false;
      state.error = false;
    },
  },
});

export const {setData} = businessSlice.actions;
export const businessReducer = persistReducer(
  {
    key: 'business',
    storage,
    whitelist: ['businessData'],
  },
  businessSlice.reducer
);
