import PropTypes from 'prop-types';

export const ButtonPropsType = {
  label: PropTypes.string,
  endicon: PropTypes.element,
  starticon: PropTypes.element,
  handleOnClick: PropTypes.func,
  typevariant: PropTypes.string,
  fullwidth: PropTypes.bool,
  bgvariant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};
