import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Box, Stack, Chip, SvgIcon} from '@mui/material';
import DataGridOne from 'components/DataGrids/VariantOne';
import {PaymentConstants} from 'constants/paymentContants';
import {useNavigate} from 'react-router-dom';
import {useGetPaymentsQuery} from 'lib/apis/paymentsApi.slice';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import {debounce} from 'utils/debounce';
import {Visibility} from '@mui/icons-material';
import {GridActionsCellItem} from '@mui/x-data-grid';

const DataTable = ({state, dispatch}) => {
  const {handleShowSnack} = useSnackbar();
  const navigate = useNavigate();

  const {isError, isLoading, isSuccess, error, data, isFetching} =
    useGetPaymentsQuery(
      {
        page: state.page,
        pageSize: state.pageSize,
        sort: JSON.stringify(state.sort),
        search: state.search,
      },
      {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      }
    );

  if (isError) {
    return (
      <BasicSnackbar
        open={isError}
        handleClose={handleShowSnack}
        severity={'error'}
      >
        <Typography>{error?.data?.message}</Typography>
      </BasicSnackbar>
    );
  }
  const entities = isSuccess ? data.allPayments.entities : {};
  const totalRows = isSuccess ? data.total : 0;

  const handleEditPayment = id => {
    console.log(entities[id]);
    navigate(`/app/payments/edit/${id}`, {
      state: {
        payment_id: id,
        client: entities[id].client._id,
        company_name: entities[id].client.company_name,
        representative_name: entities[id].client.representative_name,
        date: entities[id].date,
        payment_type: entities[id].payment_type,
        amount: entities[id].amount,
        quoteId: entities[id].quote._id,
        quoteNumber: entities[id].quote.meta.quoteNumber,
        advance_payment: entities[id].advance_payment,
        note: entities[id].note,
        canEdit: false,
      },
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Client/Quote No.',
      flex: 1,
      cellClassName: 'name-column--cell',
      renderCell: params =>
        isSuccess && (
          <>
            {params.row.client ? (
              <Stack onClick={() => handleEditPayment(params.row.id)}>
                <Typography variant={'medium20'}>
                  {params.row.client.company_name}
                </Typography>{' '}
                <Typography variant={'h6'}>
                  {params.row.client.representative_name}
                </Typography>
              </Stack>
            ) : (
              <Typography variant={'h6'}>N/A</Typography>
            )}
          </>
        ),
    },
    {
      field: 'date',
      headerName: 'Payment Date',
      flex: 1,
      renderCell: params =>
        isSuccess && (
          <Typography variant={'regular14'}>
            {new Date(params.value).toLocaleDateString()}
          </Typography>
        ),
    },
    {
      field: 'payment_type',
      headerName: 'Type',
      flex: 1,
      renderCell: param => <Chip label={param.value} />,
    },
    {
      field: 'amount',
      headerName: 'Amount Paid',
      flex: 1,
      renderCell: params => {
        return (
          isSuccess && (
            <Stack direction={'row'} alignItems={'center'} gap={2}>
              <Typography variant={'h6'}>
                AED{' '}
                {parseFloat(params.value)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
              {params.row.advance_payment && (
                <Chip label={'Advance'} size="small" />
              )}
            </Stack>
          )
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 100,
      getActions: params =>
        isSuccess
          ? [
              <GridActionsCellItem
                onClick={() => {
                  handleEditPayment(params.row.id);
                }}
                key={2}
                icon={
                  <SvgIcon
                    component={Visibility}
                    inheritViewBox
                    fontSize={'medium'}
                  />
                }
                label={'Preview'}
              />,
            ]
          : [],
    },
  ];

  return (
    <>
      <Box marginTop={'2em'}>
        <Typography variant="medium20">
          {PaymentConstants.sectionTwo.table.heading}
        </Typography>
      </Box>

      <DataGridOne
        loading={isLoading || isFetching}
        initialState={{
          sorting: {
            sortModel: [{field: 'date', sort: 'desc'}],
          },
        }}
        columns={columns}
        data={Object.values(entities)}
        rowCount={totalRows}
        pagination
        page={state.page}
        pageSize={state.pageSize}
        paginationMode="server"
        sortingMode="server"
        onPageChange={newPage => dispatch('SET_PAGE', newPage)}
        onPageSizeChange={newPageSize =>
          dispatch({type: 'SET_PAGE_SIZE', payload: newPageSize})
        }
        onSortModelChange={newSortModel =>
          debounce(dispatch({type: 'SET_SORT', payload: newSortModel[0]}), 300)
        }
        experimentalFeatures={{
          lazyLoading: true,
        }}
      />
    </>
  );
};

DataTable.propTypes = {
  payments: {
    id: PropTypes.string,
    entities: PropTypes.object,
  },
  state: PropTypes.object,
  dispatch: PropTypes.any,
};

export default DataTable;
