/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
  Avatar,
  IconButton,
  Skeleton,
} from '@mui/material';
import {SettingsConstants} from 'constants/settingsConstants';
import FlexBetween from 'components/UtilityComponents/FlexBetween';
import PrimaryInput from 'components/Inputs/primary';
import {useDispatch, useSelector} from 'react-redux';
import {
  PasswordResetSchema,
  UserSettingsSchema,
} from 'constants/formValidationSchemas';
import {useFormik} from 'formik';
import PrimaryButton from 'components/Buttons/primaryButton';
import {
  useLazyGetUserQuery,
  useReset_passwordMutation,
  useUpdateMutation,
} from 'lib/apis/authApi.slice';
import {useSnackbar} from 'utils/snackbarUtility';
import BasicSnackbar from 'components/Snackbars/basic';
import {setUser, signout} from 'lib/slices/auth.slice';
import TextButton from 'components/Buttons/textButton';
import PropTypes, {string} from 'prop-types';
import BasicPopover from 'components/Popovers/basic';
import {useNavigate} from 'react-router-dom';
import toBase64 from 'utils/toBase64';
import WithRemoveAvatar from 'components/Avatar/withRemoveAvatar';
import arrayBufferToBase64 from 'utils/arrayBufferToBase64';

function AccountSettingsScreen() {
  const theme = useTheme();
  const {palette} = theme;
  const {userInfo} = useSelector(state => state.auth);
  const {showSnack, handleShowSnack} = useSnackbar();
  const [update, {isLoading}] = useUpdateMutation();
  const [getUser, {isFetching}] = useLazyGetUserQuery();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  // Destructuring form props
  const {
    heading,
    desc,
    fNameInput,
    lNameInput,
    emailInput,
    pwInput,
    emailOption,
    pwOption,
    button,
  } = SettingsConstants.accountForm;

  // Destructuring user details
  const {first_name, last_name, email, avatar} = userInfo;

  const handleSubmit = async values => {
    try {
      const {message} = await update(values).unwrap();
      handleShowSnack(true, message, 'success');
      const data = await getUser().unwrap();
      dispatch(setUser(data));
    } catch (error) {
      handleShowSnack(true, error.data.message, 'error');
    }
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name,
      last_name,
      email,
      avatar: avatar
        ? arrayBufferToBase64(avatar.data)
        : '',
    },
    validationSchema: UserSettingsSchema,
    onSubmit(values) {
      handleSubmit(values);
    },
  });

  // Password Popover handlers
  const handlePopOverVisibility = () => {
    setShow(p => !p);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Box>
      {/* Form Area */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}}>
          <Grid item xs={6}>
            <Stack mt={2} spacing={1}>
              <Typography variant="medium20">{heading}</Typography>
              <Typography variant="regular16" color={palette.neutral[500]}>
                {desc}
              </Typography>
            </Stack>

            <Stack mt={3} spacing={2}>
              <FlexBetween gap={'1.5em'}>
                {formik.values.first_name ? (
                  <PrimaryInput
                    {...fNameInput}
                    fullWidth
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    helpertext={
                      formik.touched.first_name && formik.errors.first_name
                    }
                  />
                ) : (
                  <Skeleton variant="rounded" width="100%" height="50px" />
                )}
                {formik.values.last_name ? (
                  <PrimaryInput
                    {...lNameInput}
                    fullWidth
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    helpertext={
                      formik.touched.last_name && formik.errors.last_name
                    }
                  />
                ) : (
                  <Skeleton variant="rounded" width="100%" height="50px" />
                )}
              </FlexBetween>
              <Stack>
                {formik.values.email ? (
                  <PrimaryInput
                    {...emailInput}
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helpertext={formik.touched.email && formik.errors.email}
                  />
                ) : (
                  <Skeleton variant="rounded" width="100%" height="50px" />
                )}
              </Stack>
              <Stack>
                <PrimaryInput
                  value={'(*&^^$%^$%%&*&*%^$#%^^&%&*^*(('}
                  readOnly
                  {...pwInput}
                  fullWidth
                />
                <TextButton handleOnClick={handlePopOverVisibility}>
                  <Typography variant="regular14" color={palette.neutral[500]}>
                    {pwOption}
                  </Typography>
                </TextButton>
              </Stack>
            </Stack>
          </Grid>
          {/* Avatar Area */}
          <Grid item xs={6} spacing={1}>
            <Stack mt={2} spacing={1}>
              <Typography variant="medium20">
                {SettingsConstants.profile.heading}
              </Typography>
              <Stack direction={'row'} gap={2}>
                <WithRemoveAvatar
                  alt={first_name}
                  src={formik.values.avatar}
                  handleRemove={() => formik.setFieldValue('avatar', '')}
                  showRemove={formik.values.avatar !== ''}
                />
                <IconButton aria-label="upload picture" component="label">
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={async e =>
                      formik.setFieldValue(
                        'avatar',
                        await toBase64(e.target.files[0])
                      )
                    }
                  />
                  <Typography
                    variant="regular16"
                    color={palette.accent[500]}
                    alignItems={'center'}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {SettingsConstants.profile.btnLabel}
                  </Typography>
                </IconButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack mt={'4em'}>
          <PrimaryButton
            type="submit"
            label={button.label}
            typevariant={'medium16'}
            disabled={isLoading}
            fullwidth={false}
            loading={isLoading}
          />
        </Stack>
      </form>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
      <BasicPopover open={show} handleClose={handleClose} width={'sm'}>
        <PasswordChangeSection handleClose={handleClose} />
      </BasicPopover>
    </Box>
  );
}

export default AccountSettingsScreen;

const PasswordChangeSection = ({handleClose}) => {
  // API slice for mutation
  const [reset_password, {isLoading}] = useReset_passwordMutation();
  // Snackbar api
  const {showSnack, handleShowSnack} = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Destructuring constants
  const {heading, closeIcon, oldPwInput, newPwInput, confirmPwInput, button} =
    SettingsConstants.password;

  // Reset handler
  const handleReset = async values => {
    try {
      const {message} = await reset_password(values).unwrap();
      handleShowSnack(true, message, 'success');
      setTimeout(() => {
        dispatch(signout('Signed Out'));
        navigate('/secure/signin');
      }, 3000);
    } catch (error) {
      handleShowSnack(true, error.data.message, 'error');
    }
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: PasswordResetSchema,
    onSubmit(values) {
      handleReset(values);
    },
  });
  return (
    <Stack p={'1em 1.5em'} spacing={2}>
      <FlexBetween>
        <Typography variant="h3">{heading}</Typography>
        <IconButton onClick={() => handleClose()}>{closeIcon}</IconButton>
      </FlexBetween>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <PrimaryInput
            {...oldPwInput}
            fullWidth
            value={formik.values.old_password}
            onChange={formik.handleChange}
            error={
              formik.touched.old_password && Boolean(formik.errors.old_password)
            }
            helpertext={
              formik.touched.old_password && formik.errors.old_password
            }
          />
          <PrimaryInput
            {...newPwInput}
            fullWidth
            value={formik.values.new_password}
            onChange={formik.handleChange}
            error={
              formik.touched.new_password && Boolean(formik.errors.new_password)
            }
            helpertext={
              formik.touched.new_password && formik.errors.new_password
            }
          />
          <PrimaryInput
            {...confirmPwInput}
            fullWidth
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            error={
              formik.touched.confirm_password &&
              Boolean(formik.errors.confirm_password)
            }
            helpertext={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
          />
          <PrimaryButton
            type="submit"
            label={button.label}
            typevariant={'medium16'}
            fullwidth={true}
            disabled={isLoading}
            loading={isLoading}
          />
        </Stack>
      </form>
      <BasicSnackbar
        open={showSnack.open}
        handleClose={handleShowSnack}
        severity={showSnack.severity}
      >
        <Typography>{showSnack.message}</Typography>
      </BasicSnackbar>
    </Stack>
  );
};

PasswordChangeSection.propTypes = {
  handleClose: PropTypes.func,
};
