import {createEntityAdapter, createSelector} from '@reduxjs/toolkit';
import {apiSlice} from './index.slice';

// Users api slice
const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllUsers: builder.query({
      query: ({page, pageSize, sort}) => ({
        url: '/user/getAllUsers',
        method: 'GET',
        params: {
          page,
          pageSize,
          sort,
        },
      }),
      validateStatus(response, result) {
        return response.status === 200 && !result.isError;
      },
      transformResponse(responseData) {
        const loadedUsers = responseData.users.map(user => {
          user.id = user._id;
          return user;
        });
        return {
          users: usersAdapter.setAll(initialState, loadedUsers),
          total: responseData.total,
        };
      },
      providesTags(result) {
        if (result?.users.ids) {
          return [
            {type: 'User', id: 'LIST'},
            ...result.users.ids.map(id => ({type: 'User', id})),
          ];
        }

        return [{type: 'User', id: 'LIST'}];
      },
    }),
    updateBySuper: builder.mutation({
      query: ({id, payload}) => ({
        url: `/user/updateBySuperadmin?id=${id}`,
        method: 'PATCH',
        body: {...payload},
      }),
      invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}],
    }),
    createUser: builder.mutation({
      query: payload => ({
        url: '/user/createUser',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}],
    }),
    removeUser: builder.mutation({
      query: ({id}) => ({
        url: `/user/removeUser?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}],
    }),
    removeUsers: builder.mutation({
      query: payload => ({
        url: `/user/removeUsers`,
        method: 'DELETE',
        body: {...payload},
      }),
      invalidatesTags: (result, error, arg) => [{type: 'User', id: arg.id}],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useUpdateBySuperMutation,
  useCreateUserMutation,
  useRemoveUserMutation,
  useRemoveUsersMutation,
} = usersApiSlice;

// Return query result object
export const selectUsersResult = usersApiSlice.endpoints.getAllUsers.select();

// Create memoized selector
const selectUsersData = createSelector(
  selectUsersResult,
  usersResults => usersResults.data
);

// Created aliases for selectors
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState);
